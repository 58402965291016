import MomentUtils from '@date-io/moment';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import CustomInput from '../../CustomInput';
import { useTranslation } from 'react-i18next';
import { FormEditProfileProps } from '../model/ProfileFormDialog';

const FormEditProfile: React.FC<FormEditProfileProps> = (props) => {
	const [t] = useTranslation('profileForm');

	const {
		// activationDate
		activationDate,
		handleActivationDateChange,
		// deactivationDate
		deactivationDate,
		handleDeactivationDateChange,
		// firstname
		firstName,
		firstNameError,
		handleFirstNameOnChange,
		// lastname
		lastName,
		lastNameError,
		handleLastNameOnChange,
		// username
		username,
		usernameError,
		helperUsername,
		handleUsernameOnChange,
		// role
		role,
		handleRoleOnChange
	} = props;

	return (
		<>
			<Grid container justify="center">
				<Grid item xs={11}>
					<CustomInput
						error={firstNameError}
						helperText={t('form.required')}
						id="firstName"
						label={t('form.firstName.label')}
						onChange={handleFirstNameOnChange}
						value={firstName}
					/>
				</Grid>
				<Grid item xs={11}>
					<CustomInput
						error={lastNameError}
						helperText={t('form.required')}
						id="lastName"
						label={t('form.lastName.label')}
						onChange={handleLastNameOnChange}
						value={lastName}
					/>
				</Grid>
				<Grid item xs={11}>
					<CustomInput
						error={usernameError}
						helperText={t(helperUsername)}
						id="username"
						label={t('form.username.label')}
						onChange={handleUsernameOnChange}
						value={username}
					/>
				</Grid>
			</Grid>
			<Grid container justify="space-around">
				<Grid item xs={5}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<KeyboardDatePicker
							disabled
							className={'c-custom-input'}
							format="DD/MM/yyyy"
							id="date-picker-activate"
							inputVariant="outlined"
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
							label={t('form.activationDate.label')}
							margin="normal"
							onChange={(date: moment.Moment | null, value?: string | null | undefined) =>
								handleActivationDateChange(date, value)
							}
							placeholder="dd/mm/yyyy"
							value={activationDate}
							variant="inline"
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={5}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<KeyboardDatePicker
							autoComplete="off"
							className={'c-custom-input'}
							format="DD/MM/yyyy"
							id="date-picker-deactivate"
							inputVariant="outlined"
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
							label={t('form.deactivationDate.label')}
							margin="normal"
							onChange={(date: moment.Moment | null, value?: string | null | undefined) =>
								handleDeactivationDateChange(date, value)
							}
							placeholder="dd/mm/yyyy"
							value={deactivationDate}
							disablePast
							variant="inline"
						/>
					</MuiPickersUtilsProvider>
				</Grid>
			</Grid>
			<Grid container justify="space-around">
				<Grid item xs={2}>
					<FormControl className="c-profilecreator__radio-label" variant="outlined" fullWidth margin="normal">
						<FormLabel>{t('form.radio.role.title')}</FormLabel>
					</FormControl>
				</Grid>
				<Grid item xs={8}>
					<FormControl className="c-profilecreator__radio" component="fieldset">
						<RadioGroup
							aria-label={t('form.radio.role.aria-label')}
							name={t('form.radio.role.name')}
							onChange={handleRoleOnChange}
							defaultValue="tenant"
							row
							value={role}
						>
							<FormControlLabel
								value="tenant"
								control={<Radio color="default" />}
								label={t('form.radio.role.options.tenant.name')}
								color="default"
							/>
							<FormControlLabel
								value="admin"
								control={<Radio color="default" />}
								label={t('form.radio.role.options.admin.name')}
								color="default"
							/>
						</RadioGroup>
					</FormControl>
				</Grid>
			</Grid>
		</>
	);
};

export default FormEditProfile;
