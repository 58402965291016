import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { refreshAuthToken } from '../components/Login/login.service';
import { setNewAuth, setLogout, setExpireSession } from '../components/Login/login.actions';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TOKEN_PREFIX } from '../services/services.consts';
import { ExpiredSession } from 'app/components/Login/model/ExpiredSession';
import { Auth } from 'app/components/Login/model/Auth';
/**
 * @function
 * @name useInterceptor
 * @description This hook creates interceptor to manage axios request and axios response
 * if the token is invalid, the interceptor requests a new token and retry query.
 */
const useInterceptor = () => {
	const dispatch = useDispatch();
	const [authInterceptor, setAuthInterceptor] = useState<any>(undefined);
	const [errorInterceptor, setErrorInterceptor] = useState<any>(undefined);
	const store = useStore();
	/**
	 * @function
	 * @name addAuthInterceptor
	 * @description Create authInterceptor and add authInterceptor to state
	 */
	const addAuthInterceptor = () => {
		const authInterceptor = axios.interceptors.request.use(
			(config: AxiosRequestConfig) => {
				console.log('request:' + config.url);
				//TO-DO: intercept token
				// if (!config.headers.hasOwnProperty('Authorization')) {
				// 	if (auth._token) {
				// 		config.headers.Authorization = auth._token;
				// 	}
				// } else if (!config.headers.Authorization) {
				// 	delete config.headers.Authorization;
				// }
				return config;
			},
			(error: AxiosError): Promise<AxiosError> => {
				return Promise.reject(error);
			}
		);
		setAuthInterceptor(authInterceptor);
	};

	/**
	 * @function
	 * @name removeAuthInterceptor
	 * @description Delete authInterceptor and add authInterceptor to state
	 */
	const removeAuthInterceptor = () => {
		axios.interceptors.request.eject(authInterceptor);
		setAuthInterceptor(undefined);
	};
	/**
	 * @function
	 * @name addErrorInterceptor
	 * @description Create errorInterceptor and add errorInterceptor to state
	 */
	const addErrorInterceptor = () => {
		const errorInterceptor = axios.interceptors.response.use(
			(response: AxiosResponse): AxiosResponse => {
				const expiredSession: ExpiredSession = store.getState().loginState.expiredSession;
				if (expiredSession.retry < expiredSession.initialRetry) {
					dispatch(setExpireSession(false, expiredSession.initialRetry, ''));
				}
				return response;
			},
			(error) => {
				return new Promise((resolve, reject) => {
					const originalReq = error.config;
					if (error.response.status === 401 && error.config) {
						originalReq._retry = true;
						const refreshToken = store.getState().loginState.login._refreshToken;
						const retry = store.getState().loginState.expiredSession.retry;
						let result = refreshAuthToken(refreshToken).then((res: Auth) => {
							if (res.accessToken) {
								dispatch(setNewAuth(res));
								originalReq.headers.authorization = `${TOKEN_PREFIX} ${res.accessToken}`;
								if (retry === 0) {
									dispatch(setExpireSession(true, 0, 'time'));
									dispatch(setLogout());
									reject(error);
								} else {
									dispatch(setExpireSession(false, retry - 1, 'time'));
								}
								return axios(originalReq);
							} else {
								dispatch(setExpireSession(true, 0, 'time'));
								dispatch(setLogout());
								reject(error);
							}
						});
						resolve(result);
					}
					reject(error);
				});
			}
		);
		setErrorInterceptor(errorInterceptor);
	};
	/**
	 * @function
	 * @name removeErrorInterceptor
	 * @description Delete errorInterceptor and add errorInterceptor to state
	 */
	const removeErrorInterceptor = () => {
		axios.interceptors.request.eject(errorInterceptor);
		setErrorInterceptor(undefined);
	};

	useEffect(() => {
		console.log('Add intercerceptors');
		addAuthInterceptor();
		addErrorInterceptor();
		return () => {
			console.log('Remove interceptors');
			removeAuthInterceptor();
			removeErrorInterceptor();
		};
	}, []);
};

export default useInterceptor;
