import React from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { SnackbarContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainCookieProps } from '../../../core/interfaces/AdviceCookie';

const MainCookie: React.FC<MainCookieProps> = (props) => {
	const [t] = useTranslation('cookiesInfo');
	const { acceptCookiesAndClose, switchCookieAdvice, usersAcceptCookie } = props;

	return (
		<Snackbar open={usersAcceptCookie} className="c-cookie-advice__root">
			<SnackbarContent
				classes={{ root: 'c-cookie-advice__content', action: 'c-cookie-advice__btn' }}
				role="alertdialog"
				aria-describedby="cookien-preferences"
				message={<span id="msg-cookie">{t('mainCookie.content')}</span>}
				action={
					<React.Fragment>
						<Button
							variant="contained"
							onClick={() => {
								switchCookieAdvice();
							}}
							color="primary"
							size="small"
							className={'c-cookie-advice__btn--reject'}
						>
							{t('mainCookie.btnMoreContent')}
						</Button>
						<Button
							className={'c-button c-cookie-advice__btn--confirm'}
							variant="contained"
							color="primary"
							size="small"
							onClick={() => {
								acceptCookiesAndClose();
							}}
						>
							{t('mainCookie.btnAcceptContent')}
						</Button>
					</React.Fragment>
				}
			/>
		</Snackbar>
	);
};

export default MainCookie;
