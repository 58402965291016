import { HeadCell, BulkActionProps } from '../ActionTable/model/ActionTable';
import { UserRow } from './model/UserList';

const Config = (
	openEditUser: (row: any) => void,
	deleteUser: () => void,
	setActivateUsers: (rowList: UserRow[]) => Promise<void>,
	setDeactivateUsers: (rowList: UserRow[]) => Promise<void>
) => {
	const headCellsList: HeadCell[] = [
		{
			id: 'id',
			label: 'table.id',
			type: 'number',
			disablePadding: false,
			options: {
				display: false,
				callbacks: []
			}
		},
		{
			alignHead: 'left',
			id: 'user_name',
			disablePadding: true,
			label: 'table.username',
			type: 'string',
			options: {
				filter: true,
				sort: true,
				display: true,
				callbacks: [],
				filterType: 'custom'
			}
		},
		{
			alignHead: 'left',
			id: 'role',
			disablePadding: false,
			label: 'table.role',
			type: 'string',
			options: {
				filter: true,
				sort: true,
				display: true,
				callbacks: [],
				filterType: 'custom'
			}
		},
		{
			alignHead: 'center',
			alignContent: 'center',
			id: 'activation_date',
			disablePadding: false,
			label: 'table.activation_date',
			type: 'string',
			options: {
				filter: true,
				sort: true,
				display: true,
				callbacks: [],
				filterType: 'custom'
			}
		},
		{
			id: 'deactivation_date',
			label: 'table.deactivation_date',
			type: 'string',
			disablePadding: false,
			options: {
				display: false,
				callbacks: []
			}
		},
		{
			id: 'active',
			disablePadding: false,
			label: 'table.active',
			type: 'switch',
			options: {
				filter: true,
				sort: true,
				empty: true,
				callbacks: [
					{
						callback: setActivateUsers,
						name: 'activate'
					},
					{
						callback: setDeactivateUsers,
						name: 'deactivate'
					}
				],
				display: true,
				filterType: 'custom'
			}
		},
		{
			alignHead: 'center',
			id: 'actions',
			disablePadding: false,
			label: 'table.actions',
			type: 'actions',
			options: {
				filter: false,
				sort: false,
				empty: true,
				callbacks: [
					{
						callback: openEditUser,
						icon: 'edit',
						name: 'table.edit'
					},
					{
						callback: deleteUser,
						icon: 'delete',
						name: 'table.delete'
					}
				],
				display: true
			}
		}
	];

	const bulkActionList: BulkActionProps[] = [
		{
			callback: setActivateUsers,
			icon: 'lock',
			name: 'table.bulk_actions.activate'
		},
		{
			callback: setDeactivateUsers,
			icon: 'lock-open',
			name: 'table.bulk_actions.deactivate'
		}
	];

	const conf: [HeadCell[], BulkActionProps[]] = [headCellsList, bulkActionList];
	return conf;
};

export default Config;
