import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { AlertProps } from '../../core/models/Alert';
import { switchmodeStatus } from '../SwitchMode/switchmode.selectors';
import { SwitchStatus } from '../SwitchMode/model/ESwitchStatus';

const AlertWrapper: React.FC<AlertProps> = (props) => {
	const { onClose, severity, message, variant } = props;
	const darkTheme: SwitchStatus = useSelector(switchmodeStatus);

	return (
		<Alert onClose={onClose} severity={severity} variant={darkTheme === 'on' ? 'standard' : variant}>
			{message}
		</Alert>
	);
};

export default AlertWrapper;
