import { Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormActionCreateProfileProps } from '../model/ProfileFormDialog';

const FormActionCreateProfile: React.FC<FormActionCreateProfileProps> = (props) => {
	const { t } = useTranslation('profileForm');
	const { createAnother, handleCreateAnother, handleClose, handleSubmit, disableAccept } = props;

	return (
		<>
			<Grid container justify="flex-end">
				<FormControlLabel
					control={
						<Checkbox
							id="createAnother"
							checked={createAnother}
							onClick={handleCreateAnother}
							className={'c-profilecreator__checkbox'}
						/>
					}
					label={t('form.checkbox.createAnother.label')}
					className={'c-profilecreator__checkbox--label'}
				/>
			</Grid>
			<Grid container justify="space-around">
				<Grid item xs={5}>
					<Button
						className={'c-button c-button--cancel'}
						disableElevation
						fullWidth={true}
						id="cancelBtn"
						onClick={handleClose}
						variant="contained"
					>
						{t('form.cancelBtn')}
					</Button>
				</Grid>
				<Grid item xs={5}>
					<Button
						className={'c-button'}
						disabled={disableAccept}
						disableElevation
						fullWidth={true}
						id="acceptBtn"
						onClick={handleSubmit}
						variant="contained"
					>
						{t('form.confirmBtn.create')}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default FormActionCreateProfile;
