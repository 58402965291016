import { Cookie } from '../core/interfaces/AdviceCookie';
import { handleApiError } from './index';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';

const axios = require('axios');

/**
 * @function
 * @name getCookieList
 * @returns {Promise<AxiosResponse<Cookie[]>>} A AxiosResponse promise with an array of Cookie data elements
 * @description This method retrieve the Cookies json to cookieInfoView component
 */
export const getCookieList = async (): Promise<AxiosResponse<Cookie[]>> => {
	try {
		return axios.get('/src/app/assets/mocks/usercookies.json');
	} catch (error) {
		return handleApiError(error, 'Error getting cookies data.');
	}
};

/**
 * @description Returns user cookies if defined
 * @name getUserCookies
 * @returns {Cookie[]} Cookies Array
 */
export const getUserCookies = async (): Promise<Cookie[]> => {
	const savedCookies = Cookies.get('userCookieList');
	return JSON.parse(savedCookies ? savedCookies : '[]');
};

/**
 * @description Set user cookies as string json
 * @name setUserCookies
 * @param {Cookie[]} cookieList
 * @param {number} expirationTime // Duration time of the cookie
 */
export const setUserCookies = (cookieList: Cookie[], expirationTime: number): void => {
	Cookies.set('userCookieList', JSON.stringify(cookieList), { expires: expirationTime });
};
