import React, { useEffect } from 'react';
import { Divider, List } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import VerticalScroll from '../VerticalScroll';
import { MenuProps } from './MenuProps';
import { AareonRoute } from 'app/core/interfaces/AareonRoute';
import { Events, scrollSpy } from 'react-scroll';
import { markdownScrolls } from '../MarkDown/markdown.actions';

const Menu: React.FC<MenuProps> = (props) => {
	const { itemsMenu, short } = props;
	const [fromClick, setFromClick] = React.useState(false);
	const shortClass = short ? 'c-sidenav--short' : '';
	const dispatch = useDispatch();
	useEffect(() => {
		Events.scrollEvent.register('begin', () => {
			dispatch(markdownScrolls(true));
		});
		Events.scrollEvent.register('end', () => {
			dispatch(markdownScrolls(false));
			setFromClick(false);
		});
		scrollSpy.update();
	}, []);

	const startScrolling = () => {
		dispatch(markdownScrolls(true));
		setFromClick(true);
	};
	return (
		<React.Fragment>
			<Divider />
			<List component="nav" className={`c-sidenav u-aareon-scrollbar ${shortClass}`}>
				{itemsMenu.map((item: AareonRoute, index: number) => {
					return (
						item &&
						item.data.visible && (
							<VerticalScroll scrollItem={item} key={index} startScrolling={startScrolling} fromClick={fromClick} />
						)
					);
				})}
			</List>
		</React.Fragment>
	);
};

export default Menu;
