export interface ILinkTitle {
	icon?: string;
	text: string;
	link: {
		href: string;
		type: string;
		target: string;
	};
}

export interface IListData {
	order: number;
	headTitle?: string;
	linksTitle: ILinkTitle[];
}

export const Data: IListData[] = [
	{
		order: 1,
		// headTitle: 'Documentation Group',
		linksTitle: [
			{
				text: 'Aareon Group',
				link: {
					type: 'href',
					href: 'https://www.aareon.com',
					target: '_blank'
				}
			},
			{
				text: 'Aareon Smart World',
				link: {
					type: 'href',
					href: 'https://www.aareon.com/Aareon_Smart_World.181350.html',
					target: '_blank'
				}
			},
			{
				text: 'Company',
				link: {
					type: 'href',
					href: 'https://www.aareon.com/Company.181428.html',
					target: '_blank'
				}
			},
			{
				text: 'Contact',
				link: {
					type: 'href',
					href: 'https://www.aareon.com/Contact.3542.html',
					target: '_blank'
				}
			}
		]
	}
];
