import React from 'react';
import { SnackbarContent } from '@material-ui/core';
import { SnackbarContentWrapperProps } from '../../core/models/Snackbar';

const SnackbarContentWrapper: React.FC<SnackbarContentWrapperProps> = (props) => {
	const { message } = props;
	const messageSpan = (
		<span id="client-snackbar" className="c-snackbar__message">
			{message}
		</span>
	);

	return (
		<SnackbarContent
			className="c-snackbar__content"
			classes={{ message: 'c-snackbar__message', action: 'c-snackbar__closeBtn' }}
			role="alertdialog"
			aria-describedby="client-snackbar"
			message={messageSpan}
			{...props}
		/>
	);
};

export default SnackbarContentWrapper;
