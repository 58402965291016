import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import React from 'react';
import { iSummaryProps } from 'app/core/interfaces/Summary';
import { useTranslation } from 'react-i18next';

export const Summary = ({ successCalls, failedCalls, errorCalls, loading }: iSummaryProps) => {
	const { t } = useTranslation('performanceTest');
	return (
		<Paper className={'c-summary'}>
			<Box className={'c-summary__totalcalls'}>
				<Typography variant="h4" component="span" className="c-summary__totalcallsNumber">
					{successCalls + failedCalls + errorCalls}
				</Typography>
				<Typography variant="h6" component="span">
					{t('summary.nrequest')}
				</Typography>
			</Box>
			<Grid container direction="row" justify="center" alignItems="center">
				<Grid item xs={4}>
					<Paper elevation={0} square={true} className={'c-summary__counter'}>
						<Box className={'c-summary__wrapicon'}>
							<CheckCircle fontSize="large" className={'c-summary__icon c-summary__icon--success'} />
							{loading ? <CircularProgress /> : successCalls}
						</Box>
						<Box className={'c-summary__label'}>{t('summary.success')}</Box>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper elevation={0} square={true} className={' c-summary__counter'}>
						<Box className={'c-summary__wrapicon'}>
							<CancelIcon fontSize="large" className={'c-summary__icon c-summary__icon--fail'} />
							{loading ? <CircularProgress /> : failedCalls}
						</Box>
						<Box className={'c-summary__label'}>{t('summary.fail')}</Box>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper elevation={0} square={true} className={' c-summary__counter'}>
						<Box className={'c-summary__wrapicon'}>
							<CancelIcon fontSize="large" className={'c-summary__icon c-summary__icon--fail'} />
							{loading ? <CircularProgress /> : errorCalls}
						</Box>
						<Box className={'c-summary__label'}>{t('summary.internalerror')}</Box>
					</Paper>
				</Grid>
			</Grid>
		</Paper>
	);
};
