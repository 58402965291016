import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Link,
	Snackbar,
	Typography,
	withWidth
} from '@material-ui/core';
import { CheckCircleOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { ChangePasswordProps, AlertChangePasswordProps } from './model/ChangePassword';
import { changePassword, getPasswordPolicies, validateResetPassword } from '../../services/users.service';
import CustomInput from '../CustomInput';
import { validatePassword, validateRepeatPassword } from '../../utils/PasswordPolicy';
import { PasswordValidated, Policies } from '../../utils/PasswordPolicy/model/PasswordPolicy';
import { ChangePasswordBody } from 'app/core/interfaces/CreateUser';
import { getUserToken, getUser } from '../Login/login.selectors';
import Alert from '../AlertWrapper';
import PasswordPolicyBox from '../PasswordPolicyBox';

const AlertChangePassword: React.FC<AlertChangePasswordProps> = (props) => {
	const { snackbar, handleClose } = props;
	const [t] = useTranslation(['changePassword']);

	const hideDuration = 6000;
	return (
		<Snackbar
			id="test-snackbarcookie"
			open={snackbar.open}
			autoHideDuration={hideDuration}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			classes={{ root: 'c-snackbar' }}
		>
			{snackbar.success ? (
				<Alert onClose={handleClose} severity="success" variant="filled" message={t('alert.success')} />
			) : (
				<Alert
					onClose={handleClose}
					severity="error"
					variant="filled"
					message={snackbar.errorMsg === '' ? t('alert.error.generic') : t(`alert.error.${snackbar.errorMsg}`)}
				/>
			)}
		</Snackbar>
	);
};

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
	const [t] = useTranslation(['changePassword']);

	const { lightLabel = false, isUserLoggedIn = true, verticalAligned = false, width } = props;
	const mobile = width === 'sm' || width === 'xs';

	// used to just trigger useEffect logic on updates, but not on the initial render
	const isInitialMount = useRef(true);

	const [policies, setPolicies] = useState<Policies>({
		min_length: 0,
		min_upper_case_characters: 0,
		min_lower_case_characters: 0,
		min_digits: 0,
		min_special_digits: 0,
		min_min_special_or_digits: 0,
		compare_characters: 0
	});

	const [policiesLoaded, setPoliciesLoaded] = useState<boolean>(false);

	const [validations, setValidations] = useState<PasswordValidated>({
		length: false,
		lowercase: false,
		uppercase: false,
		number: false,
		specialchar: false,
		specialnumberchar: false,
		comparechars: false
	});

	const [snackbarOpen, setSnackbarOpen] = useState<{ open: boolean; success: boolean; errorMsg?: string }>({
		open: false,
		success: false,
		errorMsg: ''
	});

	const [disableButton, setDisableButton] = useState<boolean>(true);
	const [passwordValidate, setPasswordValidate] = useState<boolean>(false);
	const [samePassword, setSamePassword] = useState<boolean>(true);
	const [emptyCurrentPassword, setEmptyCurrentPassword] = useState<boolean>(false);

	const [currentPassword, setCurrentPassword] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [rePassword, setRePassword] = useState<string>('');
	const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmInForm, setShowConfirmInForm] = useState<boolean>(false);

	const token = useSelector(getUserToken);
	const userInfo = useSelector(getUser);

	/**
	 * @name handleShowCurrentPassword
	 * @description Show the text current password in the input
	 * @param {boolean} inputShowPassword the new value
	 */
	const handleShowCurrentPassword = (inputShowPassword: boolean) => {
		setShowCurrentPassword(inputShowPassword);
	};

	/**
	 * @name handleShowPassword
	 * @description Show the text password in the input
	 * @param {boolean} inputShowPassword the new value
	 */
	const handleShowPassword = (inputShowPassword: boolean) => {
		setShowPassword(inputShowPassword);
	};

	/**
	 * @name getPasswordPolicies
	 * @description Get the password policies and set it
	 */
	const getPWPolicies = (): void => {
		if (isInitialMount.current) {
			getPasswordPolicies().then((res: any) => {
				setPolicies(res.results);
				setPoliciesLoaded(true);
			});
		}
	};

	/**
	 * @name handleCurrentPasswordOnChange
	 * @description Manage input value change in repassword
	 * @param {React.ChangeEvent<HTMLInputElement>} e the event on the input
	 */
	const handleCurrentPasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setCurrentPassword(e?.target.value);
		setEmptyCurrentPassword(e?.target.value === '');
	};

	/**
	 * @name onChangeNewPassword
	 * @description Get the password policies and set it
	 * @param {React.ChangeEvent<HTMLInputElement>} e Receive the input object
	 */
	const handleNewPasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const newPassword: string = e.target.value;
		const personalData: string = `${userInfo.identity.first_name} ${userInfo.identity.last_name} ${userInfo.user.username}`;

		const validations: PasswordValidated = validatePassword(newPassword, policies, personalData);

		setValidations(validations);
		setPassword(e.target.value);
	};

	/**
	 * @name handleRePasswordOnChange
	 * @description Manage input value change in repassword
	 * @param {React.ChangeEvent<HTMLInputElement>} e the event on the input
	 */
	const handleRePasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setRePassword(e?.target.value);
	};

	/**
	 * @name checkPasswordValidations
	 * @description check the new password Validations
	 */
	const checkPasswordValidations = (): void => {
		let validate: boolean = true;

		if (validate && policies.min_length > 0) {
			validate = validations.length;
		}

		if (validate && policies.min_upper_case_characters > 0) {
			validate = validations.uppercase;
		}

		if (validate && policies.min_lower_case_characters > 0) {
			validate = validations.lowercase;
		}

		if (validate && policies.min_digits > 0) {
			validate = validations.number;
		}

		if (validate && policies.min_special_digits > 0) {
			validate = validations.specialchar;
		}

		if (validate && policies.min_min_special_or_digits > 0) {
			validate = validations.specialnumberchar;
		}

		if (validate && policies.compare_characters > 0) {
			validate = validations.comparechars;
		}

		setPasswordValidate(validate);
	};

	/**
	 * @name validateRePassword
	 * @description Validate the input and check if it's valid and if it has any errors
	 */
	const validateRePassword = (): void => {
		const same: boolean = validateRepeatPassword(password, rePassword);
		setSamePassword(same);
	};

	/**
	 * @name handleSnackbarOpen
	 * @param {boolean} success
	 * @param {string?} errorMsg
	 * @description Open the Snackbar Alert
	 */
	const handleSnackbarOpen = (success: boolean, errorMsg?: string) => {
		setSnackbarOpen({ open: true, success: success, errorMsg: errorMsg });
	};

	/**
	 * @name handleSnackbarClose
	 * @description Close the Snackbar Alert
	 */
	const handleSnackbarClose = () => {
		setSnackbarOpen({ open: false, success: true, errorMsg: '' });
	};

	/**
	 * @name submitPassword
	 * @description Submit the password changes and return if error or success
	 */
	const submitPassword = (): void => {
		const body: ChangePasswordBody = { currentPassword, password };
		if (isUserLoggedIn) {
			if (currentPassword !== '') {
				changePassword(body, token)
					.then((res) => {
						if (res.results.success) {
							handleSnackbarOpen(true);
						} else {
							handleSnackbarOpen(false, '');
						}
					})
					.catch((error) => {
						const pwInvalid: RegExp = /^The current password.*is invalid/;
						if (error.message.match(pwInvalid) !== null) {
							handleSnackbarOpen(false, 'currentInvalid');
						} else {
							handleSnackbarOpen(false, '');
						}
					});
			} else {
				setEmptyCurrentPassword(currentPassword === '');
			}
		} else {
			const newPassword = body.password;
			const email = location.search.split('=')[1];
			const resetToken =
				window.location.href
					.split('?')[0]
					.split('/')
					.pop() || '';

			validateResetPassword(email, resetToken, newPassword)
				.then((res) => {
					if (res.results.status) {
						setShowConfirmInForm(true);
					} else {
						handleSnackbarOpen(false, '');
					}
				})
				.catch((res) => {
					const pwInvalid: RegExp = /^The current password.*is invalid/;
					if (res.error_description.match(pwInvalid) !== null) {
						handleSnackbarOpen(false, 'currentInvalid');
					} else if (res.error === 'Not Found') {
						handleSnackbarOpen(false, 'tokenInvalid');
					} else {
						handleSnackbarOpen(false, '');
					}
				});
		}

		clearInputs();
	};

	/**
	 * @function
	 * @name clearInputs
	 * @description clear all inputs after submit
	 */
	const clearInputs = (): void => {
		setCurrentPassword('');
		setPassword('');
		setRePassword('');
	};

	/**
	 * @name handleDisableButton
	 * @description Disable or enable the submit button
	 */
	const handleDisableButton = (): void => {
		setDisableButton(!(samePassword && passwordValidate && password !== '' && rePassword !== ''));
	};

	useEffect(() => {
		isInitialMount.current = false;
	}, [policies]);

	useEffect(() => {
		checkPasswordValidations();
		validateRePassword();
	}, [password, rePassword]);

	useEffect(() => {
		handleDisableButton();
	}, [samePassword, passwordValidate]);

	getPWPolicies();

	return (
		<React.Fragment>
			<AlertChangePassword snackbar={snackbarOpen} handleClose={handleSnackbarClose} />
			{!showConfirmInForm && (
				<>
					{isUserLoggedIn && (
						<Typography
							className={'c-dashboard__title c-change-password__title'}
							component={'h1'}
							variant={'h2'}
							color="textSecondary"
							gutterBottom
						>
							{t('title')}
						</Typography>
					)}
					{!isUserLoggedIn && !showConfirmInForm && (
						<Typography className={'c-change-password__title-reset'} variant={'h4'} gutterBottom>
							{t('resetPassword.title')}
						</Typography>
					)}
					<Grid container>
						<Grid
							item
							xs={12}
							sm={12}
							md={verticalAligned ? 12 : 5}
							xl={verticalAligned ? 12 : 5}
							className="c-change-password__form"
						>
							{isUserLoggedIn && (
								<>
									<InputLabel
										htmlFor="password"
										className={`${lightLabel ? 'c-change-password__text--light' : 'c-change-password__text'}`}
									>
										{t('currentPassword.title')}
									</InputLabel>
									<CustomInput
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle current password visibility"
													onClick={() => handleShowCurrentPassword(!showCurrentPassword)}
													onMouseDown={(event) => event.preventDefault()}
													edge="end"
												>
													{showCurrentPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
										error={emptyCurrentPassword}
										helperText={emptyCurrentPassword ? t('currentPassword.error') : ''}
										id="currentPassword"
										onChange={handleCurrentPasswordOnChange}
										type={showCurrentPassword ? 'text' : 'password'}
										value={currentPassword}
									/>
								</>
							)}
							<InputLabel
								htmlFor="password"
								className={`${lightLabel ? 'c-change-password__text--light' : 'c-change-password__text'}`}
							>
								{t('newPassword.title')}
							</InputLabel>
							<CustomInput
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => handleShowPassword(!showPassword)}
											onMouseDown={(event) => event.preventDefault()}
											edge="end"
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								error={!passwordValidate}
								helperText={!passwordValidate ? t('newPassword.error') : ''}
								id="newPassword"
								onChange={handleNewPasswordOnChange}
								type={showPassword ? 'text' : 'password'}
								value={password}
							/>

							<InputLabel
								htmlFor="password"
								className={`${lightLabel ? 'c-change-password__text--light' : 'c-change-password__text'}`}
							>
								{t('confirmPassword.title')}
							</InputLabel>
							<CustomInput
								error={!samePassword}
								helperText={!samePassword ? t('confirmPassword.error') : ''}
								id="rePassword"
								onChange={handleRePasswordOnChange}
								type={'password'}
								value={rePassword}
								disabled={showPassword}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={verticalAligned ? 12 : 6}
							xl={verticalAligned ? 12 : 5}
							className="c-change-password__form"
						>
							<PasswordPolicyBox
								policies={policies}
								validations={validations}
								mobile={mobile}
								loaded={policiesLoaded}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" justify="flex-end">
						<Grid item>
							<Button
								className={'c-button c-change-password__btn'}
								variant="contained"
								color="primary"
								size="small"
								id="submit"
								onClick={submitPassword}
								disabled={disableButton}
							>
								{t('button')}
							</Button>
						</Grid>
					</Grid>
				</>
			)}
			{!isUserLoggedIn && showConfirmInForm && (
				<div className={'c-change-password__confirm-container'}>
					<Typography className={'c-change-password__title-reset'} variant={'h4'} gutterBottom>
						{t('resetPassword.confirm')}
					</Typography>
					<CheckCircleOutlined
						className={'c-change-password__confirm-container'}
						style={{ fontSize: 80 }}
					></CheckCircleOutlined>
					<Link className={'c-login__textLabelForm c-login__link'} href="/login">
						{t('resetPassword.back')}
					</Link>
				</div>
			)}
		</React.Fragment>
	);
};

export default withWidth()(ChangePassword);
