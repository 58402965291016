import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteWithSubRoutes } from '../../../routes/routes';
import { getBrowserInfo } from '../../../utils/common';
import { getLoading } from '../../../components/MarkDown//markdown.selectors';

const Main: React.FC<any> = (props) => {
	const { routes, short } = props;
	const browserInfo = getBrowserInfo();
	const loading: boolean = useSelector(getLoading);

	const loadingClass = !loading ? 'c-markdown__fix-loading-footer-spacing' : '';

	const className = `${loadingClass}`;

	return (
		<main className={className}>
			<Switch>
				{routes.map((route, i) => {
					return <RouteWithSubRoutes key={i} {...route} />;
				})}
			</Switch>
		</main>
	);
};

export default Main;
