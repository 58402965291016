import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import Cookies from 'js-cookie';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { isScrolling } from '../../MarkDown/markdown.selectors';
import { closeSidebar } from '../../ResponsiveSidebar/sidebar.actions';

const VerticalChild: React.FC<any> = (props: any) => {
	const { scrollItem } = props;
	const [t] = useTranslation(['menuTableOfContent']);
	const [indicatorHeight] = React.useState(0);
	const [indicatorPosition] = React.useState(0);
	const scrolling = useSelector(isScrolling);
	const dispatch = useDispatch();
	let history = useHistory();

	//Handle access to config cookie page
	const [cookieSettings] = React.useState(!Cookies.get('userCookieList') ? true : false);

	const handleClick = (e: any) => {
		const linkRoute = e.currentTarget.getAttribute('data-url');
		const hasNoChilds = eval(e.currentTarget.getAttribute('data-no-childs'));

		if (linkRoute !== location.pathname && hasNoChilds) {
			history.push(linkRoute);
			dispatch(closeSidebar());
		}
	};

	const moveIndicator = (listItem: any) => {
		// Prevent indicator moving on activeScrollElement
		if (scrolling) {
			return;
		}

		// Handle Navigation
		const linkRoute = listItem.getAttribute('data-url');
		if (linkRoute !== location.pathname) {
			history.push(linkRoute);
		}
	};

	const handleActive = (containerId: string) => {
		const cleanId = containerId.split('_')[0];
		const listItemId = cleanId + '_link';
		const listItem = document.getElementById(listItemId);
		moveIndicator(listItem);
	};

	const handleListClick = (clickEvent: any) => {
		const listItem = clickEvent.currentTarget;
		const linkRoute = listItem.getAttribute('data-url');
		history.push(linkRoute);
		dispatch(closeSidebar());
	};

	return (
		<React.Fragment>
			{scrollItem?.routes && scrollItem.routes.length ? (
				<React.Fragment>
					<ListItem
						button
						onClick={handleClick}
						disabled={scrollItem.data.key === 'cookie-settings' ? cookieSettings : scrollItem.data.disabled}
						key={scrollItem.data.key}
						data-url={scrollItem.url}
						data-no-childs={!scrollItem.routes || scrollItem.routes.length === 0}
						className={
							'c-sidenav__list c-sidenav__list--header-item ' +
							(scrollItem.routes && scrollItem.routes.length === 0 ? 'c-sidenav__parent ' : '') +
							(scrollItem.routes && scrollItem.routes.length === 0 && location.pathname === scrollItem.url
								? 'c-sidenav__parent--active'
								: '')
						}
					>
						<ListItemText className={'c-sidenav__label'} primary={t('titleSubsection')} />
					</ListItem>

					{scrollItem.routes && (
						<Collapse in={true} timeout="auto" unmountOnExit>
							<List component="div" disablePadding className={'c-sidenav__sublist'}>
								{scrollItem.routes.map((route: any, index: number) => (
									<Link
										to={route.data.key + '_container'}
										spy={true}
										smooth={true}
										offset={-160}
										key={route.data.key}
										onSetActive={handleActive}
									>
										<ListItem
											button
											className={'c-sidenav__subitem'}
											onClick={handleListClick}
											data-url={route.url}
											data-id={scrollItem.data.key + '_' + index}
											data-index={index}
											data-container={route.data.key + '_container'}
											id={route.data.key + '_link'}
										>
											<ListItemText className={'c-sidenav__label'} primary={route.data.label} />
										</ListItem>
									</Link>
								))}
								<div className="c-sidenav__slider">
									<div
										className="c-sidenav__indicator"
										id={scrollItem.data.key + '_indicator'}
										style={{
											height: indicatorHeight,
											transform: `translateY( ${indicatorPosition}px)`
										}}
									></div>
								</div>
							</List>
						</Collapse>
					)}
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

export default VerticalChild;
