import { iServiceEndpoint } from '../core/interfaces/TablePerformance';
import { config } from '../services';
import axios, { AxiosResponse } from 'axios';

/**
 * @function
 * @name helpCheck
 * @param {iServiceEndpoint} endpoint endpoint to check
 * @returns {Promise<AxiosResponse>} A AxiosResponse promise with data content
 * @description This method retrieve a Promisse with the response of call the endpoint
 */
export const helpCheck = (endpoint: iServiceEndpoint): Promise<AxiosResponse> => {
	const baseURL = `${endpoint.protocol}://${endpoint.uri}${config.branch}${config.serviceUrlDomain}`;

	console.info('Call helpcheck');
	switch (endpoint.call) {
		case 'get':
			return axios.get(baseURL);
		case 'head':
			return axios.head(baseURL);
		case 'post':
			return axios.post(baseURL);
		case 'options':
			return axios.options(baseURL);
		default:
			return axios.get(baseURL);
	}
};

/**
 * @function
 * @name getEndpoints
 * @returns {Promise<AxiosResponse>} A AxiosResponse promise with an array of iServiceEndpoint data elements
 * @description This method retrieve the Endpoints json to testing
 */
export const getEndpoints = (): Promise<AxiosResponse> => {
	try {
		console.info('Get Endpoints to check');
		return axios.get('/src/app/assets/mocks/endpoints.json');
	} catch (err) {
		console.error(err);
		throw err;
	}
};
