import React, { Key } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, IconButton, Toolbar, withWidth } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MainLogo from '../Logo';
import NavigationBarLink from '../NavigationBarLink';
import { getUser } from '../Login/login.selectors';
import { toogleSidebar } from '../ResponsiveSidebar/sidebar.actions';
import { isSidebarMounted } from '../ResponsiveSidebar/sidebar.selectors';
import { useHistory } from 'react-router-dom';
import AvatarRoleMenu from '../AvatarRoleMenu';
import SearchBar from '../SearchBar';
import { AareonRoute } from 'app/core/interfaces/AareonRoute';

const NavigationBar: React.FC<any> = (props) => {
	const { routes, roleType, width } = props;

	const dispatch = useDispatch();
	const userInfo = useSelector(getUser);
	const hideSidebar = () => dispatch(toogleSidebar());

	let history = useHistory();
	const mobile = width === 'sm' || width === 'xs';

	return (
		<AppBar position="sticky" className={'c-navigation-bar'}>
			<div className={'c-navigation-bar__container'}>
				<Toolbar className={'c-navigation-bar__toolbar'}>
					{mobile && (
						<IconButton
							onClick={hideSidebar}
							edge="end"
							color="inherit"
							aria-label="menu"
							className={'c-navigation-bar__mobile-menu'}
						>
							<MenuIcon />
						</IconButton>
					)}
					<MainLogo onClick={() => history.push('/')} className={'c-navigation-bar__logo'} />
					<div className={`c-navigation-bar__actions ${mobile && 'c-navigation-bar__actions-mobile'}`}>
						{!mobile && (
							<div className={`${width === 'md' && 'c-navigation-bar__links-md'}`}>
								{routes &&
									routes.length > 0 &&
									routes.map((route: AareonRoute, index: number) => {
										return route.data.visible && <NavigationBarLink route={route} key={index} />;
									})}
								{
									// This is the right way once roles are set up
									/* return (
											route.data.visible &&
											(route.data.role === roleType || roleType === 'admin') && <NavigationBarLink route={route} />
										); */
								}
							</div>
						)}
						<div className={'c-navigation-bar__right-container'}>
							<SearchBar />
							<div className={`${mobile && 'c-navigation-bar__mobile-avatar'}`}>
								<AvatarRoleMenu mainRole={userInfo?.user?.role} />
							</div>
						</div>
					</div>
				</Toolbar>
			</div>
		</AppBar>
	);
};

export default withWidth()(NavigationBar);
