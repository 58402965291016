import React from 'react';
/**
 * @description Hook that alerts clicks outside of the passed ref
 *
 * @param initialIsVisible
 */

const useOnClickOutside = (initialIsVisible: boolean) => {
	const [isComponentVisible, setIsComponentVisible] = React.useState<boolean>(initialIsVisible);
	const ref = React.useRef<any>(null);

	const handleHideDropdown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			setIsComponentVisible(false);
		}
		if (event.key === 'Enter' && ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		}
	};

	const handleClickOutside = (event: MouseEvent | TouchEvent) => {
		// @ts-ignore
		if (ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener('keydown', handleHideDropdown, true);
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('keydown', handleHideDropdown, true);
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
};

export default useOnClickOutside;
