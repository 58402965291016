import { TotalState } from 'app/store/model/TotalState';
import { SearchData } from './model/SearchDataState';

/**
 * SEARCHBAR: SELECTORS
 *
 * @param {object} state Params to selector for TotalState interface.
 * @description Generic interface with static fields.
 * @returns {Function} The function to set the search data.
 */

export const searchDataSelector = (state: TotalState): SearchData[] => state.searchbarState.searchData;
