import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { RowActionProps } from '../model/ActionTable';
import { getIconElement } from '../../../utils/icons';

const RowAction: React.FC<RowActionProps> = (props) => {
	const { callback, name, icon } = props;
	return (
		<div>
			<Tooltip title={name}>
				<IconButton aria-label={icon} onClick={callback} classes={{ root: 'c-action-table__icon' }}>
					{getIconElement(icon)}
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default RowAction;
