import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { ListItem, ListItemText } from '@material-ui/core';
import { removeActiveClasses } from '../../../utils/common';
import { closeSidebar } from '../../ResponsiveSidebar/sidebar.actions';

const VerticalHeader: React.FC<any> = (props: any) => {
	const { scrollItem } = props;
	const dispatch = useDispatch();
	let history = useHistory();
	// FIXME: DON'T USE THIS LOGIC TWICE COPY AND PASTE
	const pathArray = location.pathname.split('/');
	// If the path has more than 1 child, keep the main parent to not animate in childrens
	const transitionKey = pathArray.length > 3 ? `/${pathArray[1]}/${pathArray[2]}` : location.pathname;
	const [selected, setSelected] = React.useState(scrollItem.data.opened || scrollItem.url === transitionKey);
	//Handle access to config cookie page
	const [cookieSettings] = React.useState(!Cookies.get('userCookieList') ? true : false);

	React.useEffect(() => {
		const pathArray = location.pathname.split('/');
		// If the path has more than 1 child, keep the main parent to not animate in childrens
		const transitionKey = pathArray.length > 3 ? `/${pathArray[1]}/${pathArray[2]}` : location.pathname;
		setSelected(scrollItem.url === transitionKey);
	}, [location.pathname]);

	const handleClick = (e: any) => {
		const linkRoute = e.currentTarget.getAttribute('data-url');

		if (linkRoute !== location.pathname) {
			history.push(linkRoute);
			dispatch(closeSidebar());
		}
		setSelected(!selected);
		const activeClass = 'c-sidenav__parent--active';
		removeActiveClasses(activeClass);
	};

	return (
		<React.Fragment>
			<ListItem
				button
				onClick={handleClick}
				disabled={scrollItem.data.key === 'cookie-settings' ? cookieSettings : scrollItem.data.disabled}
				key={scrollItem.data.key}
				data-url={scrollItem.url}
				data-no-childs={!scrollItem.routes || scrollItem.routes.length === 0}
				id={scrollItem.data.key}
				className={'c-sidenav__list ' + (scrollItem.routes && selected ? 'c-sidenav__parent--active' : '')}
			>
				<ListItemText className={'c-sidenav__label'} primary={scrollItem.data.label} />
			</ListItem>
		</React.Fragment>
	);
};

export default VerticalHeader;
