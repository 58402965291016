import { useState, useEffect } from 'react';

const useWindowSize = () => {
	const isClient = typeof window === 'object';

	const getSize = () => {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		};
	};

	const [windowSize, setWindowSize] = useState(getSize);

	const handleResize = () => {
		setWindowSize(getSize());
	};

	useEffect(() => {
		if (!isClient) {
			return;
		}
		// Set window resize event listener
		window.addEventListener('resize', handleResize);
		// Clean Event listener on unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowSize;
};

export default useWindowSize;
