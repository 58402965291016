import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { switchmodeStatus } from '../SwitchMode/switchmode.selectors';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Toolbar, IconButton, Snackbar } from '@material-ui/core';
import { CodeMirrorProps } from 'app/core/interfaces/CodeMirrorUI';
import { detectProgrammingLanguage, generateRandomId } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import ClipboardJS from 'clipboard';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/erlang-dark.css';
import { SwitchStatus } from '../SwitchMode/model/ESwitchStatus';

require('codemirror/mode/javascript/javascript');
require('codemirror/mode/shell/shell');

const CodeMirrorUI: React.FC<CodeMirrorProps> = (props) => {
	const { code, hasLineNumbers = true, hasToolbar = true, type } = props;
	let darkTheme: SwitchStatus = useSelector(switchmodeStatus);
	const [open, setOpen] = useState(false);
	const { t } = useTranslation('codeSnippet');

	const language = type ? type : detectProgrammingLanguage(code);
	const uniqueId = `code-${generateRandomId(7)}`;

	/**
	 * @name langToMirrorMode
	 * @param {string} language
	 * @returns {string} Codemirror option mode value.
	 * @description Maps languages to codemirror option mode.
	 */
	const langToMirrorMode = (language: string): string => {
		switch (language) {
			case 'json':
			case 'javascript':
				return 'javascript';
			case 'sh':
			case 'bash':
				return 'shell';
			default:
				return 'genericSnippet';
		}
	};
	const mode = langToMirrorMode(language);

	// Clipboard functionality
	const clipboard = new ClipboardJS(`#${uniqueId}`);

	clipboard.on('success', function(e: any) {
		setOpen(true);
		e.clearSelection();
	});

	clipboard.on('error', function(e: any) {
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
	});
	// Copied! Message
	const handleClose = (_event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return mode !== 'genericSnippet' ? (
		<React.Fragment>
			{hasToolbar && (
				<Toolbar className={'c-code__toolbar'}>
					<IconButton
						id={uniqueId}
						data-unit-test-id="copy-button"
						aria-label="copy"
						color={'inherit'}
						className={'c-code__button c-code__button--end'}
						data-clipboard-text={code}
					>
						<FileCopyOutlinedIcon />
					</IconButton>
				</Toolbar>
			)}
			<CodeMirror
				className="codemirror_ui-codemirror"
				value={code}
				options={{
					lineNumbers: hasLineNumbers,
					readOnly: false,
					theme: darkTheme === SwitchStatus.on ? 'erlang-dark' : 'default',
					mode: mode,
					scrollbarStyle: null
				}}
				onChange={() => {
					console.log('changed value');
				}}
				onBeforeChange={() => {
					console.log('OnBefore');
				}}
			/>
			<Snackbar
				open={open}
				id="codemirror_ui-snackbar"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				onClose={handleClose}
				autoHideDuration={3000}
				ContentProps={{
					'aria-describedby': 'message-id'
				}}
				message={<span id="message-id">{t('copy')}</span>}
			/>
		</React.Fragment>
	) : (
		<div id="codemirror_ui-genericSnippet" className={'c-code__container'}>
			<code className={'c-code__content'}>{code}</code>
		</div>
	);
};

export default CodeMirrorUI;
