import React from 'react';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import { switchmodeToggle } from './switchmode.actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { switchmodeStatus } from '../SwitchMode/switchmode.selectors';
import { SWITCHMODE_BLACKLIST } from '../SwitchMode/switchmode.const';
import { SwitchStatus } from '../SwitchMode/model/ESwitchStatus';

const SwitchMode: React.FC = () => {
	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(switchmodeToggle());
	};

	return (
		<React.Fragment>
			<div className="c-switch">
				<div className="c-switch__option c-switch__option--one" onClick={handleClick} id="c-switch__option--light">
					<WbSunnyIcon />
				</div>
				<div className="c-switch__option c-switch__option--two" onClick={handleClick} id="c-switch__option--dark">
					<NightsStayIcon />
				</div>
			</div>
		</React.Fragment>
	);
};

/**
 * @function
 * @name forceTheme
 * @param {string} url
 * @description Force light theme base on the url collection
 */
export const forceTheme = (url: string) => {
	const darkTheme: SwitchStatus = useSelector(switchmodeStatus);
	if (SWITCHMODE_BLACKLIST.indexOf(url) !== -1 && darkTheme === SwitchStatus.on) {
		document.body.removeAttribute('data-theme');
	} else if (SWITCHMODE_BLACKLIST.indexOf(url) === -1 && darkTheme === SwitchStatus.on) {
		const hasTheme: null | string = document.body.getAttribute('data-theme');
		if (hasTheme === null) {
			document.body.setAttribute('data-theme', 'dark');
		}
	}
};
export default SwitchMode;
