import { useRef, useCallback } from 'react';

/**
 * @name useDebouncedCallback
 * @param {(...args: any) => any} func
 * @param {number} wait
 * @description Use useDebouncedCallback function to prevent multiples calls
 */

export const useDebouncedCallback = (func: (...args: any) => any, wait: number) => {
	const timeout = useRef();

	return useCallback(
		(...args) => {
			const later = () => {
				clearTimeout(timeout.current);
				func(...args);
			};

			clearTimeout(timeout.current);
			timeout.current = setTimeout(later, wait);
		},
		[func, wait]
	);
};
