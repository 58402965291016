import { TotalState } from 'app/store/model/TotalState';
import { CustomModalContent } from './model/CustomModalContent';

/**
 * CUSTOMMODAL: SELECTORS
 *
 * @param {object} state Params to selector for TotalState interface.
 * @description Generic interface with static fields.
 * @returns {Function} The function to trigger each action.
 */
export const isCustomModalOpen = (state: TotalState): boolean => state.customModalState.open;

export const isCustomModalState = (state: TotalState): CustomModalContent => state.customModalState.customModalData;
