import { SIDEBAR_CLOSE, SIDEBAR_OPEN, SIDEBAR_TOGGLE, SIDEBAR_MOUNT } from './sidebar.const';
import { SidebarActionTypes } from './sidebar.types';

export const closeSidebar = (): SidebarActionTypes => {
	return {
		type: SIDEBAR_CLOSE,
		payload: false
	};
};
export const openSidebar = (): SidebarActionTypes => {
	return {
		type: SIDEBAR_OPEN,
		payload: true
	};
};

export const toogleSidebar = (): SidebarActionTypes => {
	return {
		type: SIDEBAR_TOGGLE
	};
};

export const mountSidebar = (mountSidebar: boolean): SidebarActionTypes => {
	return {
		type: SIDEBAR_MOUNT,
		payload: mountSidebar
	};
};
