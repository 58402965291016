import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import CreateIcon from '@material-ui/icons/Create';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DeleteIcon from '@material-ui/icons/Delete';

/**
 * @function
 * @param {string} icon
 * @description Util function to retrieve svg icons
 * @returns {React.ReactElement} Return JSX icon element
 */
export const getIconElement = (icon?: string): JSX.Element => {
	switch (icon) {
		case 'careers-icon':
			return <CreateIcon />;
		case 'delete':
			return <DeleteIcon />;
		case 'contact-icon':
			return <EmailIcon />;
		case 'edit':
			return <EditIcon />;
		case 'linkedin-icon':
			return <LinkedInIcon />;
		case 'list':
			return <ListIcon />;
		case 'lock':
			return <LockIcon />;
		case 'lock-open':
			return <LockOpenIcon />;
		case 'person':
			return <PersonIcon />;
		case 'telephone-icon':
			return <PhoneIcon />;
		case 'twitter-icon':
			return <TwitterIcon />;
		default:
			return <LockIcon />;
	}
};
