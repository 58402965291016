import { TotalState } from 'app/store/model/TotalState';
import { SwitchStatus } from './model/ESwitchStatus';

/**
 * SWITCHMODE: SELECTORS
 *
 * @function
 * @name switchmodeStatus
 * @param {TotalState} state
 * @returns {SwitchStatus} Returns switch toggle status
 * @description status 'off' for light mode and 'on' for dark mode
 */
export const switchmodeStatus = (state: TotalState): SwitchStatus => state.switchmodeState.status;
