import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './customStyles';
import { Data, IListData, ILinkTitle } from './data';
import NavigatorLink from '../NavigatorLink';

const MAX_COLUMN_ITEM = Data.length;
const COPYRIGHT = `\u00A9 ${new Date().getFullYear()} Aareon`;

const FooterContent: React.FC<any> = (props: any) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} sm={9}>
					{Data.map((d: IListData, i: number) => {
						return (
							<Grid
								key={`${d.order}-${i}`}
								item
								xs={12}
								className={i + 1 < MAX_COLUMN_ITEM ? `${classes.itemAll} ${classes.itemBorder}` : `${classes.itemAll}`}
							>
								{d.headTitle && (
									<Typography variant="h6" gutterBottom className={classes.itemTitle}>
										{d.headTitle}{' '}
									</Typography>
								)}
								<Grid className={`${classes.rowItems} c-footer--not-justify`}>
									{d.linksTitle.map((l: ILinkTitle, j: number) => {
										const linkClass = !l.icon ? `${classes.linkText}` : `${classes.linkText} ${classes.linkTextIcon}`;
										return (
											<NavigatorLink
												key={j}
												linkType={d.linksTitle[j].link.type}
												componentParams={{
													objectKey: d.linksTitle[j].link.type + i + j,
													target: d.linksTitle[j].link.target,
													href: d.linksTitle[j].link.href,
													objectClass: `MuiLink-underlineHover c-footer__link c-footer__link--white marginItem`
												}}
												content={
													<Typography variant="subtitle1" display="block" gutterBottom className={linkClass}>
														{l.text}{' '}
													</Typography>
												}
											/>
										);
									})}
								</Grid>{' '}
							</Grid>
						);
					})}{' '}
				</Grid>
				<Grid item xs={12} sm={3}>
					<Grid>
						<Typography
							variant="subtitle1"
							display="block"
							gutterBottom
							className={`${classes.linkText} ${classes.linkTextIcon} ${classes.rightAlignItem}`}
						>
							{COPYRIGHT}{' '}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default FooterContent;
