import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		itemAll: {
			textAlign: 'center'
		},
		itemBorder: {
			borderRight: 'solid 2px #fff'
		},
		link: {
			display: 'block',
			margin: theme.spacing(1),
			'& :hover': {
				// color: '#FFFFFF' //colorcustom
			}
		},
		itemTitle: {
			fontSize: '22px',
			letterSpacing: '0.3px',
			fontWeight: 'bold'
		},
		linkText: {
			fontSize: '16px',
			fontWeight: 100
		},
		linkTextIcon: {
			verticalAlign: 'super',
			paddingLeft: '1rem'
		},
		tempChanges: {
			display: 'none'
		},
		rowItems: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		},
		rightAlignItem: {
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
);
