import React from 'react';
import { Drawer, IconButton, withWidth } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Menu from '../Menu';
import { useDispatch, useSelector } from 'react-redux';
import { isSidebarOpen, isSidebarMounted } from './sidebar.selectors';
import { closeSidebar } from './sidebar.actions';
import SimpleMenu from '../SimpleMenu';
import { SidebarProps } from '../../core/interfaces/Sidebar';
// @ts-ignore
import AareonWhite from '../../assets/img/aareon_white.svg';
const ResponsiveSidebar: React.FC<SidebarProps> = (props) => {
	const dispatch = useDispatch();
	const { menuFull, itemsMenu } = props;

	const _isSidebarOpen = useSelector(isSidebarOpen);
	const _isSidebarMounted = useSelector(isSidebarMounted);

	React.useEffect(() => {
		_isSidebarOpen && dispatch(closeSidebar());
	}, [_isSidebarMounted]);

	return (
		<React.Fragment>
			{_isSidebarMounted && (
				<aside className={'c-aside'}>
					<Drawer
						variant={'temporary'}
						anchor={'left'}
						open={_isSidebarOpen}
						onClose={() => {
							dispatch(closeSidebar());
						}}
						classes={{
							paper: 'c-aside__paper',
							root: 'c-aside__container'
						}}
						SlideProps={{
							unmountOnExit: true,
							mountOnEnter: true
						}}
					>
						<div className={'c-aside__header'}>
							<IconButton
								className="c-aside__header-close-button"
								component="span"
								onClick={() => {
									dispatch(closeSidebar());
								}}
							>
								<CloseIcon />
							</IconButton>
							<div className={'c-aside__header-logo'}>
								<AareonWhite />
							</div>
						</div>
						{!menuFull ? <Menu itemsMenu={itemsMenu} /> : <SimpleMenu itemsMenu={itemsMenu} />}
					</Drawer>
				</aside>
			)}
		</React.Fragment>
	);
};

export default withWidth()(ResponsiveSidebar);
