import { SET_REFRESH_USER_LIST } from './userList.consts';
import { UserListActionTypes } from './userList.types';
import { UserListState } from './model/UserList';

const initialState: UserListState = {
	shouldRefreshUserList: false
};

const userListReducer = (state = initialState, action: UserListActionTypes): UserListState => {
	switch (action.type) {
		case SET_REFRESH_USER_LIST:
			return {
				...state,
				shouldRefreshUserList: !state.shouldRefreshUserList
			};
		default:
			return state;
	}
};

export default userListReducer;
