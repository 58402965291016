import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AvatarContent from '../AvatarContent';
import { getUser } from '../Login/login.selectors';
import ChangePassword from '../ChangePassword';
import CookieSettings from '../CookieSettings';

const PersonalData: React.FC = () => {
	const [t] = useTranslation('personalData');
	const userInfo = useSelector(getUser);
	const { user, identity } = userInfo;

	return (
		<React.Fragment>
			<Typography
				className={'c-dashboard__title c-personalData__title'}
				component={'h1'}
				variant={'h2'}
				color="textSecondary"
				gutterBottom
			>
				{t('labels.title')}
			</Typography>
			<Grid item container alignItems="center">
				<Grid item>
					<Avatar className="c-avatar c-avatar--big">
						<AvatarContent classes={['c-avatar__label', 'c-avatar__label--bold', 'c-avatar__label--big']} />
					</Avatar>
				</Grid>
				<Grid item xs={5}>
					<Typography className="c-personalData__username" variant="h6">
						{identity.first_name + ' ' + identity.last_name}
					</Typography>
					<Typography variant="h6">{user.username}</Typography>
				</Grid>
			</Grid>
			<Divider className="c-personalData__divider" variant="middle" />
			<ChangePassword />
			<Divider className="c-personalData__divider" variant="middle" />
			<CookieSettings />
		</React.Fragment>
	);
};

export default PersonalData;
