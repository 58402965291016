import React from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MethodLinkProps } from '../model/MethodLinkProps';

const MethodLink: React.FC<MethodLinkProps> = (props) => {
	const { description, isActive, methodType, path, summary, toggleConsole } = props;
	return (
		<div
			className="c-swagger__method-container"
			onClick={() => {
				toggleConsole(path);
			}}
		>
			<div
				className={`c-swagger__method-link c-swagger__method-link--${methodType} ${
					isActive ? 'c-swagger__method-link--active' : ''
				}`}
			>
				<div className="c-swagger__method-link-text-container">
					<span className="c-swagger__method-link-text-path">{path}</span>
					<span className="c-swagger__method-link-text-summary">{summary || description}</span>
				</div>
				<ArrowForwardIosIcon className="c-swagger__arrow-icon" />
			</div>
		</div>
	);
};

export default MethodLink;
