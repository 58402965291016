import * as components from './Imports';

const routesDoc = [
	{
		path: '/',
		url: '/',
		component: components.LayoutOneColumn,
		exact: true,
		data: {
			label: 'Overview',
			key: 'overview',
			description: null,
			icon: null,
			order: 0,
			visible: true,
			role: 'user'
		},
		routes: [
			{
				path: '/',
				url: '/',
				component: components.Overview,
				data: {
					label: 'Overview Component',
					key: 'overview',
					description: null,
					icon: null,
					order: 0
				},
				routes: []
			}
		]
	},
	{
		path: '/dashboard',
		url: '/dashboard',
		component: components.LayoutOneColumn,
		data: {
			label: 'Dashboard',
			key: '',
			order: 3,
			visible: true,
			role: 'user'
		},
		routes: [
			{
				path: '/dashboard',
				url: '/dashboard',
				exact: true,
				component: components.Dashboard,
				data: {
					label: 'Dashboard',
					key: '',
					order: 0
				},
				routes: []
			}
		]
	},
	{
		path: '/page-not-found',
		exact: true,
		component: components.LayoutOneColumn,
		data: { label: '404' },
		routes: [
			{
				path: '/page-not-found',
				exact: true,
				component: components.Logo404,
				data: { label: '404', order: 1000, role: 'user' },
				routes: []
			}
		]
	},

	{
		path: '*',
		component: components.NoMatchRoute,
		data: { label: '404', order: 1001 },
		routes: []
	}
];

export default routesDoc;
