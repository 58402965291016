import React from 'react';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';

import { CustomInputProps } from './model/CustomInput';

const CustomInput: React.FC<CustomInputProps> = (props) => {
	const { disabled = false, endAdornment, error, helperText, id, label, onChange, type = 'text', value } = props;
	return (
		<>
			<FormControl className={'c-custom-input'} error={error} variant="outlined" fullWidth margin="normal">
				<InputLabel
					classes={{
						outlined: 'c-custom-input__label--outlined',
						root: 'c-custom-input__label',
						focused: 'c-custom-input__label--focused'
					}}
					htmlFor={id}
				>
					{label}
				</InputLabel>
				<OutlinedInput
					autoComplete="off"
					classes={{ root: 'c-custom-input--outlined', notchedOutline: 'c-custom-input--notched-outline' }}
					endAdornment={endAdornment}
					id={id}
					value={value}
					onChange={onChange}
					label={label}
					type={type}
					disabled={disabled}
				/>
				<FormHelperText classes={{ root: 'c-custom-input__helper' }} id={`helper-${id}`}>
					{helperText}
				</FormHelperText>
			</FormControl>
		</>
	);
};

export default CustomInput;
