import { parseTokenToJson } from '../../utils/common';
import { Auth } from './model/Auth';
import { Login } from './model/Login';
import { UserInfo } from './model/UserInfo';
import axios from 'axios';
import { config, handleApiError } from '../../services';
import { AUTH_SERVICE, REFRESH_TOKEN_ENDPOINT, HTTPS } from '../../services/services.consts';

/**
 * @name doAuth
 * @param {string} username
 * @param {string} password
 * @returns {Promise<Auth>} A promise with a Auth data objects
 * @description Call to auth service and return a valid jwt.
 */
const doAuth = async (username: string, password: string): Promise<Auth> => {
	try {
		const instance = axios.create({
			baseURL: `https://auth-orm${config.branch}${config.serviceUrlDomain}/`,
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				'X-USERNAME': username,
				'x-password': password,
				fingerprint: config.fingerprint
			}
		});
		console.info('Calling auth service');
		const res = await instance.post('api/authenticate');
		const token: Auth = res.data;
		return token;
	} catch (error) {
		return handleApiError(error, 'Unable to establish a login session, your password or user may be wrong.');
	}
};

/**
 * @name auth
 * @param {string} username
 * @param {string} password
 * @returns {Promise<Login>} A promise with a Login data objects
 * @description Call to doAuth method and if everything goes well returns logged true
 * and token parsed data
 */
export const auth = async (username: string, password: string): Promise<Login> => {
	const data: Auth = await doAuth(username, password);

	let { accessToken, refreshToken } = data;
	const parsedToken: UserInfo = parseTokenToJson(accessToken);
	const login: Login = {
		isLogged: true,
		_token: accessToken,
		_refreshToken: refreshToken,
		userInfo: parsedToken
	};
	return login;
};

/**
 * @name refreshAuthToken
 * @param {string} refreshToken
 * @returns {Promise<Auth>} A promise with a Auth data objects
 * @description Call to set the new refreshtoken and new authtoken.
 */
export const refreshAuthToken = async (refreshToken: string): Promise<Auth> => {
	try {
		const baseURL = `${HTTPS}${AUTH_SERVICE}${config.branch}${config.serviceUrlDomain}`;
		const headers: HeadersInit = new Headers();
		headers.set('TENANT-ID', config.tenantId ? config.tenantId : '');
		headers.set('fingerprint', config.fingerprint ? config.fingerprint : '');

		const res = await fetch(baseURL + REFRESH_TOKEN_ENDPOINT, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: headers,
			redirect: 'follow',
			referrer: 'no-referrer',
			body: JSON.stringify({
				refreshToken: refreshToken
			})
		});
		return await res.json();
	} catch (error) {
		return handleApiError(error, 'Error refresh token.');
	}
};
