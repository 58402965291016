import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link, Events, scrollSpy } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { removeActiveClasses } from '../../../utils/common';
import { isScrolling } from '../../MarkDown/markdown.selectors';
import { markdownScrolls } from '../../MarkDown/markdown.actions';
import { closeSidebar } from '../../ResponsiveSidebar/sidebar.actions';

const VerticalScrollChild: React.FC<any> = (props: any) => {
	const { scrollItem } = props;
	const [t] = useTranslation(['menuTableOfContent']);
	const [indicatorHeight, setIndicatorHeight] = React.useState(0);
	const [indicatorPosition, setIndicatorPosition] = React.useState(0);
	const scrolling = useSelector(isScrolling);
	const dispatch = useDispatch();
	let history = useHistory();
	//Handle access to config cookie page
	const [cookieSettings] = React.useState(!Cookies.get('userCookieList') ? true : false);
	const [fromClick, setFromClick] = React.useState(false);

	// use in selector
	React.useEffect(() => {
		Events.scrollEvent.register('begin', () => {
			dispatch(markdownScrolls(true));
		});
		Events.scrollEvent.register('end', () => {
			dispatch(markdownScrolls(false));
			setFromClick(false);
		});
		scrollSpy.update();
	}, []);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		setIndicatorPosition(0);
	}, [scrollItem]);

	const startScrolling = () => {
		dispatch(markdownScrolls(true));
		setFromClick(true);
	};

	const setLinkActive = (link: HTMLElement) => {
		const activeClass = 'c-sidenav__subitem--active';
		removeActiveClasses(activeClass);
		link.classList.add(activeClass);
	};

	const setIndicatorActive = (indicator: HTMLElement) => {
		const activeClass = 'c-sidenav__indicator--active';
		removeActiveClasses(activeClass);
		indicator.classList.add(activeClass);
	};

	const calculatePosition = (linkArray: HTMLCollection, listItemIndex: string) => {
		let position = 0;
		Array.from(linkArray).forEach((parent: any) => {
			const element = parent.children[0];
			const elementId = element.getAttribute('data-id');
			const elementIndex = elementId ? elementId.split('_')[1] : null;
			if (elementIndex && parseInt(elementIndex, 10) < parseInt(listItemIndex, 10)) {
				position += element.clientHeight;
			}
		});

		return position;
	};

	const moveIndicator = (listItem: any) => {
		// Prevent indicator moving on activeScrollElement
		if (scrolling && fromClick) {
			return;
		}

		// Handle Navigation
		const linkRoute = listItem.getAttribute('data-url');
		if (linkRoute !== location.pathname) {
			history.push(linkRoute);
		}

		const currentHeight = listItem.clientHeight;
		const linkArray = listItem.parentElement.parentElement.children;
		const indicator = document.getElementById(scrollItem.data.key + '_indicator');
		const position = calculatePosition(linkArray, listItem.dataset.index);

		// mark bold selected option
		setLinkActive(listItem);
		// set indicator in selected option
		setIndicatorHeight(currentHeight);
		setIndicatorPosition(position);
		indicator && setIndicatorActive(indicator);
	};

	const handleActive = (containerId: string) => {
		const cleanId = containerId.split('_')[0];
		const listItemId = cleanId + '_link';
		const listItem = document.getElementById(listItemId);
		moveIndicator(listItem);
	};

	const handleListClick = (clickEvent: any) => {
		const listItem = clickEvent.currentTarget;
		const linkRoute = listItem.getAttribute('data-url');
		startScrolling();
		moveIndicator(listItem);
		history.push(linkRoute);
		dispatch(closeSidebar());
	};

	return (
		<React.Fragment>
			{scrollItem?.routes && scrollItem.routes.length ? (
				<>
					<ListItem
						button
						disabled={scrollItem?.data.key === 'cookie-settings' ? cookieSettings : scrollItem.data.disabled}
						key={scrollItem.data.key}
						data-url={scrollItem.url}
						data-no-childs={!scrollItem.routes || scrollItem.routes.length === 0}
						className={'c-sidenav__list c-sidenav__list--header-item'}
					>
						<ListItemText className={'c-sidenav__label'} primary={t('titleSubsection')} />
					</ListItem>
					<Collapse in={true} timeout="auto" unmountOnExit>
						<List component="div" disablePadding className={'c-sidenav__sublist'}>
							{scrollItem.routes.map((route: any, index: number) => (
								<Link
									to={route.data.key + '_container'}
									spy={true}
									smooth={true}
									offset={-165}
									key={route.data.key}
									onSetActive={handleActive}
								>
									<ListItem
										button
										className={'c-sidenav__subitem'}
										onClick={handleListClick}
										data-url={route.url}
										data-id={scrollItem.data.key + '_' + index}
										data-index={index}
										data-container={route.data.key + '_container'}
										id={route.data.key + '_link'}
									>
										<ListItemText className={'c-sidenav__label'} primary={route.data.label} />
									</ListItem>
								</Link>
							))}
							<div className="c-sidenav__slider">
								<div
									className="c-sidenav__indicator"
									id={scrollItem.data.key + '_indicator'}
									style={{
										height: indicatorHeight,
										transform: `translateY( ${indicatorPosition}px)`
									}}
								></div>
							</div>
						</List>
					</Collapse>
				</>
			) : null}
		</React.Fragment>
	);
};

export default VerticalScrollChild;
