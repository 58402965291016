import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userIsLogged } from '../Login/login.selectors';
import { RequireAuthProps } from './RequireAuthProps';

const RequireAuth: React.FC<RequireAuthProps> = (props) => {
	const { Component } = props;
	const isLogged = useSelector(userIsLogged);
	if (!isLogged) {
		return <Redirect to="/login" />;
	}
	return <Component {...props} />;
};
export default RequireAuth;
