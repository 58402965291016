import {
	GET_LOGIN_SUCCESS,
	GET_LOGIN_ERROR,
	LOGOUT,
	SET_REMEMBER_ME_TOGGLE,
	SET_USER_NAME_TO_REMEMBER,
	SET_EXPIRED_SESSION,
	SET_NEW_AUTH,
	CLEAN_ERRORS,
	ROLE_TYPES
} from './login.consts';

import { LoginActionTypes } from './login.types';
import { Login } from './model/Login';
import { Auth } from './model/Auth';
export const setLogout = (): LoginActionTypes => {
	return {
		type: LOGOUT
	};
};

export const setRememberMe = (rememberMe: boolean): LoginActionTypes => {
	return {
		type: SET_REMEMBER_ME_TOGGLE,
		payload: rememberMe
	};
};

export const setUserNameToRemember = (): LoginActionTypes => {
	return {
		type: SET_USER_NAME_TO_REMEMBER
	};
};

export const getLoginSuccess = (login: Login): LoginActionTypes => {
	const role = ROLE_TYPES[login.userInfo.user.role.replaceAll('"', '')];
	return {
		type: GET_LOGIN_SUCCESS,
		payload: {
			...login,
			userInfo: { ...login.userInfo, user: { ...login.userInfo.user, role: role } }
		}
	};
};
export const getLoginError = (error: any): LoginActionTypes => {
	return {
		type: GET_LOGIN_ERROR,
		payload: error
	};
};

export const cleanErrors = (): LoginActionTypes => {
	return {
		type: CLEAN_ERRORS
	};
};
export const setExpireSession = (expired: boolean, retry: number, type: string): LoginActionTypes => {
	return {
		type: SET_EXPIRED_SESSION,
		payload: { expired, retry, type }
	};
};

export const setNewAuth = (auth: Auth): LoginActionTypes => {
	return {
		type: SET_NEW_AUTH,
		payload: auth
	};
};
