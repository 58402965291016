import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import Detector from 'i18next-browser-languagedetector';

i18next
	.use(initReactI18next) // passes i18n down to react-i18next
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(Detector)
	.use(Backend)
	.init({
		lng: 'en',
		fallbackLng: false, // use en if detected lng is not available
		saveMissing: true, // send not translated keys to endpoint
		debug: false,
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		},
		backend: {
			loadPath: `/src/app/assets/locales/{{lng}}/{{ns}}.json`
		},
		// special options for react-i18next
		// learn more: https://react.i18next.com/components/i18next-instance
		react: {
			wait: true,
			useSuspense: false
		}
	});
