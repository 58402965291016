import React, { useEffect, useState } from 'react';
import { routes as initialRoutes, deepRoutesOrder, RouteWithSubRoutes } from '../../routes/routes';
import { StylesProvider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { switchmodeStatus } from '../SwitchMode/switchmode.selectors';
import { SwitchStatus } from '../SwitchMode/model/ESwitchStatus';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { getBrowserInfo } from '../../utils/common';
import CustomModal from '../CustomModal';
import AdviceSnackbar from '../AdviceSnackbar';
import AdviceCookie from '../AdviceCookie';
import { useTranslation } from 'react-i18next';
import useInterceptor from '../../hooks/useInterceptor';

const App = () => {
	const [routes, setRoutes] = useState();
	const [t] = useTranslation('adviceSnackbar');
	let darkTheme: SwitchStatus = useSelector(switchmodeStatus);
	const browserInfo = getBrowserInfo();

	useInterceptor();

	useEffect(() => {
		deepRoutesOrder(initialRoutes);
		setRoutes(initialRoutes);
	}, []);

	useEffect(() => {
		if (darkTheme === SwitchStatus.on) {
			document.body.setAttribute('data-theme', 'dark');
		} else {
			document.body.removeAttribute('data-theme');
		}
	}, [darkTheme]);

	return (
		<StylesProvider injectFirst>
			<Router>
				<Switch>
					{routes &&
						routes.map((subroute, i) => {
							return <RouteWithSubRoutes key={i} {...subroute} parentRoutes={routes} />;
						})}
				</Switch>
			</Router>
			{(browserInfo.includes('IE') || browserInfo.includes('Edge')) && (
				<AdviceSnackbar
					id="snackbar"
					message={t('advice')}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
				/>
			)}
			<AdviceCookie />
			<CustomModal />
		</StylesProvider>
	);
};

export default App;
