import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BKGVideo from '../../assets/img/16x9.png';
import { IVideoPlayer } from '../../core/models/VideoPlayer';
const VIDEO_LOCAL_PATH = '/src/app/assets/functionnal-content-data/videos/';
const VIMEO_PATH = 'https://player.vimeo.com/video/';
const YOUTUBE_PATH = 'https://www.youtube.com/embed/';

const VideoPlayer: React.FC<any> = ({ source, from }: IVideoPlayer): JSX.Element => {
	const { t } = useTranslation('videoPlayer');

	const youtubeVideo = () => (
		<iframe
			src={`${YOUTUBE_PATH}${source}?color=white&showinfo=0&rel=0`}
			frameBorder="0"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			className={'c-videoplayer__player'}
		></iframe>
	);

	const vimeoVideo = () => (
		<iframe
			src={`${VIMEO_PATH}${source}?title=0&byline=0&portrait=0`}
			frameBorder="0"
			allow="autoplay; fullscreen"
			allowFullScreen
			className={'c-videoplayer__player'}
		></iframe>
	);

	const html5Video = () => (
		<video controls className={'c-videoplayer__player'}>
			<source src={`${VIDEO_LOCAL_PATH}${source}.mp4`} type="video/mp4" />
			<source src={`${VIDEO_LOCAL_PATH}${source}.ogv`} type="video/ogv" />
			<source src={`${VIDEO_LOCAL_PATH}${source}.ogg`} type="video/ogg" />
			<source src={`${VIDEO_LOCAL_PATH}${source}.webm`} type="video/webm" />
		</video>
	);

	let video;
	switch (from) {
		case 'youtube':
			video = youtubeVideo();
			break;
		case 'vimeo':
			video = vimeoVideo();
			break;
		case 'html5':
			video = html5Video();
			break;
		default:
			break;
	}

	return video ? (
		<div className="c-videoplayer__wrapper">
			<div className="c-videoplayer__hiframe">
				<img src={BKGVideo} className="c-videoplayer__ratio" />
				{video}
			</div>
		</div>
	) : (
		<div>{t('errorMsg')}</div>
	);
};

export default VideoPlayer;
