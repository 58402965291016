import React, { useEffect } from 'react';
import mermaid from 'mermaid';

const Mermaid: React.FC<any> = (props: any) => {
	/* There's some snippets on the markdowns that are wrong and includes 
	the mermaid word inside the graph so this breaks the mermaid component*/

	const mermaidCode = props.chart.replace(/mermaid\n/gm, '').toString();
	useEffect(() => {
		mermaid.initialize({
			startOnLoad: true,
			flowchart: {
				htmlLabels: false,
				curve: 'linear'
			}
		});
		mermaid.contentLoaded();
	}, []);

	return <div className="mermaid c-markdown__aareon_mermaid">{mermaidCode}</div>;
};

export default Mermaid;
