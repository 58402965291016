import React, { useEffect, useState } from 'react';
import PerformanceTestComponent from './PerformanceTestComponent';
import { iServiceEndpoint } from 'app/core/interfaces/TablePerformance';
import { getEndpoints } from '../../services/dashboard.service';
import { AxiosResponse } from 'axios';

const PerformanceTestContainer: React.FC<any> = () => {
	const [endpoints, setEndpoints] = useState<iServiceEndpoint[]>([]);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		loadEndpoints();
	}, []);

	const loadEndpoints = () => {
		setLoading(true);
		getEndpoints()
			.then((response: AxiosResponse) => {
				setEndpoints(response.data);
				setLoading(false);
			})
			.catch((error: any) => {
				if (error.response) {
					console.log(error.response);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	};

	const handleRefresh = () => {
		loadEndpoints();
	};

	return <PerformanceTestComponent endPointsToCheck={endpoints} handleRefresh={handleRefresh} loading={loading} />;
};

export default PerformanceTestContainer;
