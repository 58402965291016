import { CoreUser, CoreIdentity, ToggleActivateResponse } from 'app/core/interfaces/CoreUser';
import { UserRow } from './../components/UserList/model/UserList';
import {
	CreateUserResponse,
	CreateUserBody,
	EditUserBody,
	ChangePasswordBody,
	EditUserResponse,
	ChangePasswordResponse
} from 'app/core/interfaces/CreateUser';

import {
	ACTIVATE_USERS_ENDPOINT,
	COREUSER_SERVICE,
	CREATE_USERS_ENDPOINT,
	DEACTIVATE_USERS_ENDPOINT,
	EDIT_USERS_ENDPOINT,
	GET_IDENTITY_ENDPOINT,
	HTTPS,
	SMARTUSERS_SERVICE,
	TOKEN_PREFIX,
	USERS_ENDPOINT,
	USERS_SEARCHBY_ENDPOINT,
	CORECONFIG_SERVICE,
	CONFIGURATION_SEARCHBY_ENDPOINT,
	SMARTTENANTS_SERVICE,
	GET_PASSWORD_POLICIES_ENDPOINT,
	CHANGE_PASSWORD_ENDPOINT,
	SUB_PREFIX,
	RESET_PASSWORD_ENDPOINT,
	VALIDATE_RESET_PASSWORD_ENDPOINT
} from './services.consts';

import { config, handleApiError } from '../services';
import {
	ResetPasswordBody,
	ResetPasswordResponse,
	ValidateResetPasswordBody,
	ValidateResetPasswordResponse
} from 'app/components/ResetPassword/model/ResetPassword';

const axios = require('axios');

/**
 * @function
 * @async
 * @name activateUsers
 * @param {string} token The logged in user token
 * @param {number []} userIds An array with user ids to activate
 * @returns {Promise<ToggleActivateResponse>} A promise with a ToggleActivateResponse data
 * @description Call to activateUsers method to activate users
 */
export const activateUsers = async (token: string, userIds: number[]): Promise<ToggleActivateResponse> => {
	try {
		const instance = axios.create({
			baseURL: HTTPS + SMARTUSERS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX,
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token
			}
		});
		let res = await instance.post(ACTIVATE_USERS_ENDPOINT, { user_ids: userIds });
		return res.data.results;
	} catch (error) {
		return handleApiError(error, 'Error activating users.');
	}
};
/**
 * @function
 * @async
 * @name deactivateUsers
 * @param {string} token The logged in user token
 * @param {number []} userIds An array with user ids to deactivate
 * @returns {Promise<ToggleActivateResponse>} A promise with a ToggleActivateResponse data
 * @description Call to deactivateUsers method to deactivate users
 */
export const deactivateUsers = async (token: string, userIds: number[]): Promise<ToggleActivateResponse> => {
	try {
		const instance = axios.create({
			baseURL: HTTPS + SMARTUSERS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX,
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token
			}
		});

		let res = await instance.post(DEACTIVATE_USERS_ENDPOINT, { user_ids: userIds });
		return res.data.results;
	} catch (error) {
		return handleApiError(error, 'Error deactivating users.');
	}
};
/**
 * @function
 * @async
 * @name getAllUsers
 * @param {string} token The logged in user token
 * @description Retrieve a list of users from coreuser service
 * @returns {Promise<CoreUser[]>} A promise with an array of coreuser
 */
const getAllUsers = async (token: string): Promise<CoreUser[]> => {
	try {
		let configRequest = {
			headers: {
				fingerprint: config.fingerprint,
				'TENANT-ID': config.tenantId,
				authorization: TOKEN_PREFIX + token
			},
			timeout: config.timeout
		};
		const baseURL = HTTPS + COREUSER_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		let res = await axios.get(baseURL + USERS_ENDPOINT, configRequest);
		return res.data.results;
	} catch (error) {
		return handleApiError(error, 'Error getting users.');
	}
};

/**
 * @function
 * @async
 * @name getAllASPUsers
 * @param {string} token The logged in user token
 * @description Retrieve a list of users from coreuser service
 * @returns {Promise<CoreUser[]>} A promise with an array of coreuser
 */
const getAllASPUsers = async (token: string): Promise<CoreUser[]> => {
	try {
		const baseURL = HTTPS + COREUSER_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;

		const body: {} = {
			criteria: {
				field: 'role',
				operator: 'like',
				value: '%asp_user%'
			},
			limit: {
				start: 0,
				count: 99999
			}
		};

		let configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token,
				'Content-Type': 'application/json'
			}
		};
		const res = await axios.post(baseURL + USERS_SEARCHBY_ENDPOINT, body, configRequest);
		const users: CoreUser[] = res.data.results;
		return users;
	} catch (error) {
		return handleApiError(error, 'Error getting all asp users.');
	}
};

/**
 * @function
 * @async
 * @name getUserList
 * @param {string} token The logged in user token
 * @description Call to getAllUsers method and transform a list of coreuser into a UserList list
 * @returns {Promise<UserRow[]>} A promise with an array of coreuser
 */
export const getUserList = async (token: string): Promise<UserRow[]> => {
	let transformedUsers: UserRow[] = [];
	let users: CoreUser[] = await getAllUsers(token);
	users.forEach((coreuser) => {
		transformedUsers.push({
			activation_date: coreuser.user.activation_date || '-',
			deactivation_date: coreuser.user.deactivation_date || '-',
			id: coreuser.id,
			role: getRoleName(coreuser.user.default_profile_id),
			active: isUserActive(coreuser.user.activation_date, coreuser.user.deactivation_date),
			user_name: coreuser.user.user_name
		});
	});
	return transformedUsers;
};

/**
 * @function
 * @async
 * @name getASPUserList
 * @param {string} token The logged in user token
 * @description Call to getAllUsers method and transform a list of coreuser into a UserList list
 * @returns {Promise<UserRow[]>} A promise with an array of coreuser
 */
export const getASPUserList = async (token: string): Promise<UserRow[]> => {
	let transformedUsers: UserRow[] = [];
	let users: CoreUser[] = await getAllASPUsers(token);
	users.forEach((coreuser) => {
		transformedUsers.push({
			activation_date: coreuser.user.activation_date || '-',
			deactivation_date: coreuser.user.deactivation_date || '-',
			id: coreuser.id,
			role: getRoleName(coreuser.user.default_profile_id),
			active: isUserActive(coreuser.user.activation_date, coreuser.user.deactivation_date),
			user_name: coreuser.user.user_name
		});
	});
	return transformedUsers;
};

/**
 * @function
 * @name getRoleName
 * @param {number | null | undefined} default_profile_id The default profile id
 * @description Read default_profile_id and return the correct role name
 * @returns {string} The correct role name
 */
export const getRoleName = (default_profile_id: number | null | undefined): string => {
	let role = '-';
	if (default_profile_id) {
		if (default_profile_id !== 3) {
			role = 'tenant';
		} else if (default_profile_id === 3) {
			role = 'admin';
		}
	}

	return role;
};

/**
 * @name getIdentity
 * @param {number} userID The user id
 * @param {string} token The logged in user token
 * @returns {Promise<CoreIdentity>} A promise with a CoreIdentity data
 * @description Call to get user information.
 */
export const getIdentity = async (userID: number, token: string): Promise<CoreIdentity> => {
	try {
		const baseURL = HTTPS + COREUSER_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;

		const body: {} = {
			criteria: {
				field: 'user_id',
				operator: 'in',
				value: [userID]
			}
		};

		let configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token,
				'Content-Type': 'application/json'
			}
		};
		const res = await axios.post(baseURL + GET_IDENTITY_ENDPOINT, body, configRequest);
		const user: CoreIdentity = res.data.results[0];
		return user;
	} catch (error) {
		return handleApiError(error, 'Error getting user identity.');
	}
};

/**
 * @name isUserActive
 * @description Returns the user status based in its activation/deactivation date.
 * @param {Date} activationDate The user activation date
 * @param {Date} deactivationDate The user deactivation date
 * @returns {boolean} Returns true if the user is active.
 */
const isUserActive = (activationDate: Date, deactivationDate: Date): boolean => {
	const now = new Date().getTime();
	const activationTime = new Date(activationDate).getTime();
	const deactivationTime = new Date(deactivationDate).getTime();
	return activationTime < now && (deactivationTime >= now || deactivationDate === null);
};

/**
 * @name createUser
 * @param {CreateUserBody} body The request body
 * @param {string} token The logged in user token
 * @returns {Promise<CreateUserResponse>} A promise with a CreateUserResponse data
 * @description Call to create a new user and return confirmation.
 */
export const createUser = async (body: CreateUserBody, token: string): Promise<CreateUserResponse> => {
	try {
		const baseURL = HTTPS + SMARTUSERS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;

		let configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token
			}
		};
		const res = await axios.post(baseURL + CREATE_USERS_ENDPOINT, body, configRequest);

		const newUser: CreateUserResponse = res.data;
		return newUser;
	} catch (error) {
		return handleApiError(error, 'Error creating user.');
	}
};

/**
 * @name editUser
 * @param {EditUserBody} body The request body
 * @param {string} token The logged in user token
 * @returns {Promise<EditUserResponse>} A promise with a EditUserResponse data
 * @description Call to edit a user and return confirmation.
 */
export const editUser = async (body: EditUserBody, token: string): Promise<EditUserResponse> => {
	try {
		const baseURL = HTTPS + SMARTUSERS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		const configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token
			}
		};
		const res = await axios.patch(baseURL + EDIT_USERS_ENDPOINT, body, configRequest);

		const editUser: EditUserResponse = res.data;
		return editUser;
	} catch (error) {
		return handleApiError(error, 'Error editing user.');
	}
};

/**
 * @name changePassword
 * @param {ChangePasswordBody} pwbody The request body
 * @param {string} token The logged in user token
 * @returns {ChangePasswordResponse} Return a promise response with info.
 * @description Call to change a user password and return confirmation.
 */
export const changePassword = async (pwbody: ChangePasswordBody, token: string): Promise<ChangePasswordResponse> => {
	try {
		const baseURL = HTTPS + SMARTUSERS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		const configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token
			}
		};
		const body: {} = {
			current_password: pwbody.currentPassword,
			password: pwbody.password
		};
		const res = await axios.patch(baseURL + CHANGE_PASSWORD_ENDPOINT, body, configRequest);
		const response = res.data;
		return response;
	} catch (error) {
		return handleApiError(error, 'Error changing password.');
	}
};

/**
 * @name getPasswordPolicies
 * @param {string} token The logged in user token
 * @description Call to change a user password and return confirmation.
 * @returns {string} An array with the password policies
 */
export const getPasswordPoliciesWithToken = async (token: string) => {
	try {
		const baseURL = HTTPS + CORECONFIG_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		const configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + token
			}
		};
		const body = {
			criteria: {
				field: 'name',
				operator: 'in',
				value: [
					'PASSWORD_TYPO_LENGTH',
					'PASSWORD_TYPO_MIN_UPPERS',
					'PASSWORD_TYPO_MIN_LOWERS',
					'PASSWORD_TYPO_MIN_DIGITS',
					'PASSWORD_TYPO_MIN_SPECIALS',
					'PASSWORD_TYPO_MIN_SPECIALSORDIGITS',
					'PASSWORD_TYPO_SIMILITUDE_COMPARE'
				]
			}
		};
		const res = await axios.post(baseURL + CONFIGURATION_SEARCHBY_ENDPOINT, body, configRequest);
		const passwordPolicies = res.data;
		return passwordPolicies;
	} catch (error) {
		return handleApiError(error, 'Error getting password policies with token.');
	}
};

/**
 * @name getPasswordPolicies
 * @description Call to change a user password and return confirmation.
 * @returns {string} An array with the password policies
 */
export const getPasswordPolicies = async () => {
	try {
		const baseURL = HTTPS + SMARTTENANTS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		const configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint
			}
		};
		const res = await axios.get(baseURL + GET_PASSWORD_POLICIES_ENDPOINT, configRequest);
		const passwordPolicies = res.data;
		return passwordPolicies;
	} catch (error) {
		return handleApiError(error, 'Error getting password policies.');
	}
};

/**
 * @name resetPassword
 * @param {string} email The email of the user
 * @param {string} deep_link The link that will be in the email to redirect the user
 * @description This service send a reset password email to the user.
 * @returns {Promise<ResetPasswordResponse>} Return a promise of ResetPasswordResponse type.
 */
export const resetPassword = async (email: string, deep_link: string): Promise<ResetPasswordResponse> => {
	try {
		// NOTE: SMARTTENANTS_SERVICE will be changed to SMARTUSERS_SERVICE
		const baseURL = HTTPS + SMARTTENANTS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		const configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint
			}
		};
		const body: ResetPasswordBody = {
			email,
			deep_link
		};
		const res = await axios.post(baseURL + RESET_PASSWORD_ENDPOINT, body, configRequest);
		return res.data;
	} catch (error) {
		return handleApiError(error, 'Error reseting password.');
	}
};
/**
 * @name validateResetPassword
 * @param {string} tenant_email The email of the user
 * @param {string} token The token generated to reset the password
 * @param {string} password The new password
 * @description This service send a reset password email to the user with a token concatenated in the deep_link param.
 * @returns {Promise<ValidateResetPasswordResponse>} Return a promise of ValidateResetPasswordResponse type.
 */
export const validateResetPassword = async (
	tenant_email: string,
	token: string,
	password: string
): Promise<ValidateResetPasswordResponse> => {
	try {
		// NOTE: SMARTTENANTS_SERVICE will be changed to SMARTUSERS_SERVICE
		const baseURL = HTTPS + SMARTTENANTS_SERVICE + config.branch + config.serviceUrlDomain + SUB_PREFIX;
		const configRequest = {
			timeout: config.timeout,
			headers: {
				'TENANT-ID': config.tenantId,
				fingerprint: config.fingerprint
			}
		};
		const body: ValidateResetPasswordBody = {
			tenant_email,
			token,
			password
		};
		const res = await axios.post(baseURL + VALIDATE_RESET_PASSWORD_ENDPOINT, body, configRequest);
		return res.data;
	} catch (error) {
		return handleApiError(error, 'Error validating reset password.');
	}
};
