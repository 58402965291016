import React, { useState } from 'react';
import { Button, Grid, Snackbar } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ActionTable from '../ActionTable';
import { getUser } from '../Login/login.selectors';
import { ActionTableProps } from '../ActionTable/model/ActionTable';
import { UserRow } from './model/UserList';
import { activateUsers, deactivateUsers, getASPUserList } from '../../services/users.service';
import { TotalState } from '../../store/model/TotalState';
import { getUserToken } from '../Login/login.selectors';
import ProfileFormDialog from '../ProfileFormDialog';
import { UserData } from '../ProfileFormDialog/model/UserData';
import { setOpenDialog } from '../ProfileFormDialog/profileFormDialog.actions';
import { setRefreshUserList } from '../UserList/userList.actions';
import { isProfileFormDialogOpen } from '../ProfileFormDialog/profileFormDialog.selectors';
import { shouldRefreshUserList } from './userList.selectors';
import { openCustomModal } from '../CustomModal/customModal.actions';
import { isCustomModalOpen } from '../CustomModal/customModal.selectors';
import Alert from '../AlertWrapper';
import { useDebouncedCallback } from '../../hooks/useDebounce';
import Config from './userList.config';

const UserList: React.FC = () => {
	const { t, ready } = useTranslation(['userList', 'profileForm', 'customModal'], { useSuspense: false });
	const [users, setUsers] = useState<UserRow[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [user, setUser] = useState<UserData | null>(null);
	const [editUser, setEditUser] = useState<boolean>(false);

	const _token = useSelector((state: TotalState) => getUserToken(state));
	// Create a user typed table from generic actiontable
	const UserTable: React.FC<ActionTableProps<UserRow>> = ActionTable;
	const _isCustomModalOpen = useSelector(isCustomModalOpen);
	const _isProfileFormDialogOpen = useSelector(isProfileFormDialogOpen);
	const _shouldRefreshUserList = useSelector(shouldRefreshUserList);
	const dispatch = useDispatch();
	const history = useHistory();
	const userInfo = useSelector(getUser);
	const [snackbarOpen, setSnackbarOpen] = useState<{ open: boolean; error: boolean; message: string }>({
		open: false,
		error: true,
		message: t('profileForm:form.response.error.create')
	});

	React.useEffect(() => {
		if (!_isProfileFormDialogOpen) {
			setUser(null);
			setIsOpen(false);
		}
	}, [_isProfileFormDialogOpen]);

	React.useEffect(() => {
		if (_shouldRefreshUserList) {
			callUserList();
			dispatch(setRefreshUserList());
		}
	}, [_shouldRefreshUserList]);

	React.useEffect(() => {
		callUserList();
	}, []);

	React.useEffect(() => {
		if (isMobile && !_isCustomModalOpen && ready) {
			dispatch(
				// UserList is not available in mobile version
				// Show message and redirect
				openCustomModal({
					title: t('customModal:userList.title'),
					content: t('customModal:userList.content'),
					button: [{ content: t('customModal:userList.btn'), variant: 'outlined' }],
					clickOutToClose: false
				})
			);
			history.push('/');
		}
	}, [ready]);

	const openProfileFormDialog = (): void => {
		setUser(null);
		setEditUser(false);
		setIsOpen(true);
		dispatch(setOpenDialog());
	};
	const callUserList = async (showLoader: boolean = true) => {
		if (showLoader) {
			setIsLoading(true);
		}
		try {
			const userRow = await getASPUserList(_token);
			setUsers(userRow);
		} catch (err) {
			setUsers([]);
		} finally {
			if (showLoader) {
				setIsLoading(false);
			}
		}
	};
	const handleSnackbarOpen = (error: boolean, message?: string) => {
		const messageError: string = message ? message : '';
		setSnackbarOpen({ open: true, error: error, message: messageError });
	};
	const handleSnackbarClose = () => {
		setSnackbarOpen({ open: false, error: false, message: '' });
	};

	const setActivateUsers = useDebouncedCallback(async (rowList: UserRow[]) => {
		const userIds = rowList.map((row: UserRow) => {
			return row[0];
		});
		const isCurrentUser: boolean = userIds.includes(userInfo.user.userId);

		if (!isCurrentUser) {
			try {
				const activateUserResult = await activateUsers(_token, userIds);
				callUserList(false);
				if (activateUserResult.success === false && activateUserResult.user_ids.length === 0) {
					handleSnackbarOpen(
						true,
						userIds.length > 1 ? t('userList:snackbar.error.activate_plural') : t('userList:snackbar.error.activate')
					);
				} else {
					handleSnackbarOpen(
						false,
						userIds.length > 1
							? t('userList:snackbar.success.activate_plural')
							: t('userList:snackbar.success.activate')
					);
				}
				console.log('ActivateUserResult', JSON.stringify(activateUserResult));
			} catch (err) {
				handleSnackbarOpen(true, t('userList:snackbar.error.generic'));
			}
		} else {
			handleSnackbarOpen(true, t('userList:snackbar.error.currentUser'));
		}
	}, 500);

	const setDeactivateUsers = useDebouncedCallback(async (rowList: UserRow[]) => {
		const userIds = rowList.map((row: UserRow) => {
			return row[0];
		});
		const isCurrentUser: boolean = userIds.includes(userInfo.user.userId);
		if (!isCurrentUser) {
			try {
				const deactivateUserResult = await deactivateUsers(_token, userIds);
				callUserList(false);
				if (deactivateUserResult.success === false && deactivateUserResult.user_ids.length === 0) {
					handleSnackbarOpen(
						true,
						userIds.length > 1
							? t('userList:snackbar.error.deactivate_plural')
							: t('userList:snackbar.error.deactivate')
					);
				} else {
					handleSnackbarOpen(
						false,
						userIds.length > 1
							? t('userList:snackbar.success.deactivate_plural')
							: t('userList:snackbar.success.deactivate')
					);
				}
				console.log('deactivateUserResult', JSON.stringify(deactivateUserResult));
			} catch (err) {
				handleSnackbarOpen(true, t('userList:snackbar.error.generic'));
			}
		} else {
			handleSnackbarOpen(true, t('userList:snackbar.error.currentUser'));
		}
	}, 500);
	const deleteUser = () => {
		console.log('user deleted!');
	};

	const openEditUser = (row) => {
		let _user: UserData = {
			userID: row[0],
			userName: row[1],
			role: row[2],
			activationDate: row[3],
			deactivationDate: row[4],
			firstName: '',
			lastName: ''
		};
		setUser(_user);
		setEditUser(true);
		setIsOpen(true);
	};

	const userTableConfig = Config(openEditUser, deleteUser, setActivateUsers, setDeactivateUsers);

	return (
		!isMobile && (
			<React.Fragment>
				{snackbarOpen.open && (
					<Snackbar
						open={snackbarOpen.open}
						autoHideDuration={6000}
						onClose={handleSnackbarClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						classes={{ root: 'c-snackbar' }}
					>
						{snackbarOpen.error ? (
							<Alert onClose={handleSnackbarClose} severity="error" variant="filled" message={snackbarOpen.message} />
						) : (
							<Alert onClose={handleSnackbarClose} severity="success" variant="filled" message={snackbarOpen.message} />
						)}
					</Snackbar>
				)}
				<Grid container justify="flex-end">
					<Button
						variant="contained"
						disableElevation
						startIcon={<PersonAddIcon />}
						onClick={() => {
							openProfileFormDialog();
						}}
						className={'c-button'}
					>
						{t('userList:createUser')}
					</Button>
				</Grid>
				<ProfileFormDialog open={isOpen && _isProfileFormDialogOpen} user={user} editMode={editUser} />
				<UserTable
					config={userTableConfig}
					isLoading={isLoading}
					isSelectable
					isSortable
					orderByDefault={'activation_date'}
					rows={users}
					title={t('userList:title')}
				/>
			</React.Fragment>
		)
	);
};

export default UserList;
