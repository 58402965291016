import { MARKDOWN_SCROLLING, SET_LOADING } from './markdown.const';
import { MarkdownActionTypes } from './markdown.types';

export const markdownScrolls = (scrolling: boolean): MarkdownActionTypes => {
	return {
		type: MARKDOWN_SCROLLING,
		payload: scrolling
	};
};

export const setLoading = (loading: boolean): MarkdownActionTypes => {
	return {
		type: SET_LOADING,
		payload: loading
	};
};
