import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { iTablePerformanceTestProps, iRequestRowProps, iServiceEndpoint } from 'app/core/interfaces/TablePerformance';
import { useTranslation } from 'react-i18next';

const TablePerformanceTest = (props: iTablePerformanceTestProps): JSX.Element => {
	const { endPointsToCheck, loading } = props;
	const { t } = useTranslation('performanceTest');
	const showTable = (endPointsToCheck: iServiceEndpoint[]) => {
		return endPointsToCheck.map((row, index) => (
			<RequestRow
				key={index}
				call={row.call}
				protocol={row.protocol}
				serviceName={row.serviceName}
				uri={row.uri}
				stateResponse={row.stateResponse}
				codResponse={row.codResponse}
			/>
		));
	};

	const showLoader = (items: number) => {
		let loader = [];
		for (let i = 0; i < items; i++) {
			loader.push(
				<TableRow key={i}>
					<TableCell component="th" scope="row">
						<Skeleton variant="rect" height={15} />
					</TableCell>
					<TableCell align="center">
						<Skeleton variant="rect" height={15} />
					</TableCell>
					<TableCell align="left">
						<Skeleton variant="rect" height={15} />
					</TableCell>
					<TableCell align="center">
						<Skeleton variant="rect" height={15} />
					</TableCell>
					<TableCell align="center">
						<Skeleton variant="rect" height={15} />
					</TableCell>
				</TableRow>
			);
		}
		return loader;
	};

	return (
		<Paper className={'c-tableperformancetest'}>
			<Table aria-label="customized table">
				<TableHead>
					<TableRow>
						<TableCell className={'c-tableperformancetest__header'}>{t('table.field1')}</TableCell>
						<TableCell className={'c-tableperformancetest__header'} align="center">
							{t('table.field2')}
						</TableCell>
						<TableCell className={'c-tableperformancetest__header'} align="left">
							{t('table.field3')}
						</TableCell>
						<TableCell className={'c-tableperformancetest__header'} align="center">
							{t('table.field4')}
						</TableCell>
						<TableCell className={'c-tableperformancetest__header'} align="center">
							{t('table.field5')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{loading ? showLoader(6) : showTable(endPointsToCheck)}</TableBody>
			</Table>
		</Paper>
	);
};
export default TablePerformanceTest;

const RequestRow = (props: iRequestRowProps): JSX.Element => {
	const { call, protocol, serviceName, stateResponse, codResponse } = props;

	return (
		<TableRow>
			<TableCell component="th" scope="row">
				<Box className={`c-tableperformancetest__protocol c-tableperformancetest__protocol--${call.toLowerCase()}`}>
					{call}
				</Box>
			</TableCell>
			<TableCell classes={{ root: 'c-tableperformancetest__cell' }} align="center">
				{protocol}
			</TableCell>
			<TableCell classes={{ root: 'c-tableperformancetest__cell' }} align="left">
				{serviceName}{' '}
			</TableCell>
			<TableCell align="center">
				{stateResponse ? (
					stateResponse === 'OK' ? (
						<CheckCircle fontSize="large" className={'c-tableperformancetest__icon c-summary__icon--success'} />
					) : (
						<CancelIcon fontSize="large" className={'c-tableperformancetest__icon c-summary__icon--fail '} />
					)
				) : (
					<CircularProgress />
				)}
			</TableCell>
			<TableCell classes={{ root: 'c-tableperformancetest__cell' }} align="center">
				{codResponse ? codResponse === 999 ? 'Response not received' : codResponse : <CircularProgress />}
			</TableCell>
		</TableRow>
	);
};
