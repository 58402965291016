import { HTTP_404_ERROR, HTTP_500_ERROR, HTTP_OTHER_ERROR } from '../consts';
import { ErrorActionTypes } from '../actions/actionsTypes';
import { iError } from '../models';

const initialState: iError = {
	showErrorModal: false,
	errorMessage: ''
};

const errorsReducer = (state = initialState, action: ErrorActionTypes) => {
	switch (action.type) {
		case HTTP_404_ERROR:
			action.props.push('/404');
			return {
				...state
			};
		case HTTP_500_ERROR:
			action.props.push('/500');
			return {
				...state
			};
		case HTTP_OTHER_ERROR:
			return {
				...state,
				showErrorModal: true,
				errorMessage: action.error.response.data
			};
		default:
			return state;
	}
};

export default errorsReducer;
