import { TotalState } from 'app/store/model/TotalState';

/**
 * SIDEBAR: SELECTORS
 *
 */
/**
 * @function
 * @name isScrolling
 * @param {TotalState} state
 * @returns {boolean} Returns true if markdown is scrolling.
 */
export const isScrolling = (state: TotalState): boolean => state.markdownState.scrolling;

/**
 * @function
 * @name getLoading
 * @param {TotalState} state
 * @returns {boolean} Returns true if markdown is loading.
 */
export const getLoading = (state: TotalState): boolean => state.markdownState.isLoading;
