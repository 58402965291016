import React from 'react';
import Main from './section/Main';
import Grid from '@material-ui/core/Grid';
import { AareonLayout } from 'app/core/interfaces/AareonLayout';
import Slide from '@material-ui/core/Slide';
import { useDispatch } from 'react-redux';
import { mountSidebar } from '../../components/ResponsiveSidebar/sidebar.actions';
import Footer from '../../components/Footer';

const LayoutOneColumn: React.FC<AareonLayout> = (props) => {
	const { route } = props;
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(mountSidebar(true));
	}, []);

	return (
		<Grid container>
			<Slide direction="left" in={true} timeout={350} mountOnEnter unmountOnExit>
				<Grid container>
					<Grid item xs={12}>
						<Main routes={route.routes} />
					</Grid>
					<Grid item xs={12}>
						<Footer />
					</Grid>
				</Grid>
			</Slide>
		</Grid>
	);
};

export default LayoutOneColumn;
