import React from 'react';

import { DocProps } from '../model/DocProps';
import MethodLink from '../MethodLink';
const Doc: React.FC<DocProps> = (props) => {
	const { data, selectedMethod, toggleConsole } = props;
	return (
		<div>
			<h2>{data?.info?.title}</h2>
			<div className="c-swagger__doc-schema-container">
				{Object.keys(data?.paths)?.map((endpoint: string) => {
					return Object.keys(data?.paths[endpoint])?.map((method) => {
						return (
							<MethodLink
								description={data?.paths[endpoint][method].description || ''}
								isActive={
									selectedMethod[Object.keys(selectedMethod)[0]]?.operationId ===
									data?.paths[endpoint][method]?.operationId
										? true
										: false
								}
								methodType={method}
								summary={data?.paths[endpoint][method].summary || ''}
								path={endpoint}
								key={endpoint}
								toggleConsole={toggleConsole}
							></MethodLink>
						);
					});
				})}
			</div>
		</div>
	);
};
export default Doc;
