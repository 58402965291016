import React from 'react';
import Mermaid from '../AareonMermaid';
import CodeMirrorUI from '../CodeMirror';

const CodeWrapper: React.FC<any> = ({ children, ...props }) => {
	const code = children;
	const { isReadOnly } = props;
	// Markdown parser add a class with the type as [lang-{type}] when parses code snippets like ```sh || ```mermaid || ```json
	const type = props.className ? props.className.replace(/lang-/gm, '') : null;
	// Statement to check if code snippet is mermaid graphics or not
	const isMermaid = type === 'mermaid' || code.match('mermaid') || code.match('graph LR');

	return isMermaid ? (
		<Mermaid chart={code}></Mermaid>
	) : (
		<CodeMirrorUI code={code} isReadOnly={isReadOnly} type={type} />
	);
};

export default CodeWrapper;
