import customModalReducer from '../components/CustomModal/customModal.reducer';
import errorsReducer from '../components/Error/reducers/errorHandlerReducer';
import loginReducer from '../components/Login/login.reducer';
import markdownReducer from '../components/MarkDown/markdown.reducer';
import profileFormDialogReducer from '../components/ProfileFormDialog/profileFormDialog.reducer';
import searchBarReducer from '../components/SearchBar/searchbar.reducer';
import sidebarReducer from '../components/ResponsiveSidebar/sidebar.reducer';
import switchmodeReducer from '../components/SwitchMode/switchmode.reducer';
import userListReducer from '../components/UserList/userList.reducer';
import { persistReducer, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootPersistConfig = {
	key: 'root',
	storage: storage
};

const reducers = persistCombineReducers(rootPersistConfig, {
	customModalState: customModalReducer,
	errorsState: errorsReducer,
	loginState: loginReducer,
	markdownState: markdownReducer,
	profileFormDialogState: profileFormDialogReducer,
	searchbarState: searchBarReducer,
	sidebarState: sidebarReducer,
	switchmodeState: switchmodeReducer,
	userListState: userListReducer
});

export default persistReducer(rootPersistConfig, reducers);
