import React from 'react'; // we need this to make JSX compile
import Moment from 'react-moment';
import { Typography, CircularProgress, Box } from '@material-ui/core';

type DatePerformaceTestProps = {
	loading?: boolean;
};

export const DatePerformaceTest = ({ loading }: DatePerformaceTestProps): JSX.Element => {
	const dateToFormat = new Date();
	return (
		<Box>
			{loading ? (
				<CircularProgress />
			) : (
				<Box>
					<Typography variant="h5" component="div">
						<Moment format="DD/MM/YYYY">{dateToFormat}</Moment>
					</Typography>
					<Typography variant="h6" component="div">
						<Moment format="h:mm a">{dateToFormat}</Moment>
					</Typography>
				</Box>
			)}
		</Box>
	);
};
