import * as loginService from '../components/Login/login.service';
import * as searchbarService from '../components/SearchBar/searchbar.service';
import { ServiceError } from 'app/core/interfaces/ServiceError';

/**
 * @returns {string} backend services domain to use
 */
const backendDomain = (): string => {
	let domain = window._env_.SERVICE_URL_DOMAIN;
	return domain;
};

export const config = {
	serviceUrlDomain: backendDomain(),
	tenantId: window._env_.TENANT_ID,
	fingerprint: window._env_.FINGERPRINT,
	branch: window._env_.BRANCH,
	timeout: 30000
};

export const handleApiError = (error: any, messageDefault: string): Promise<never> => {
	let status = 'error';
	let code = '999';
	let message = messageDefault;
	let serviceError: ServiceError = {
		code,
		status,
		message
	};
	// manage error in different ways depending on the server response
	if (error.response?.data.message.includes(']')) {
		serviceError = { ...error.response.data, message: error.response.data.message.split('] ')[1] };
		return Promise.reject(serviceError);
	}
	if (error.response?.data.message) {
		serviceError = { ...error.response.data, message: error.response.data.message };
		return Promise.reject(serviceError);
	}
	if (error.response.data.errors.length > 0) {
		serviceError = {
			...error.response.data,
			message: error.response.data.errors[0].error + ': ' + error.response.data.errors[0].error_description
		};
		return Promise.reject(serviceError);
	}
	return Promise.reject(serviceError);
};

const services = {
	loginService,
	searchbarService
};

export default services;
