import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormActionEditProfileProps } from '../model/ProfileFormDialog';

const FormActionEditProfile: React.FC<FormActionEditProfileProps> = (props) => {
	const { t } = useTranslation('profileForm');
	const { handleClose, handleSubmit, disableAccept } = props;

	return (
		<>
			<Grid container justify="space-around">
				<Grid item xs={5}>
					<Button
						className={'c-button c-button--cancel'}
						disableElevation
						fullWidth={true}
						id="cancelBtn"
						onClick={handleClose}
						variant="contained"
					>
						{t('form.cancelBtn')}
					</Button>
				</Grid>
				<Grid item xs={5}>
					<Button
						className={'c-button'}
						disabled={disableAccept}
						disableElevation
						fullWidth={true}
						id="acceptBtn"
						onClick={handleSubmit}
						variant="contained"
					>
						{t('form.confirmBtn.save')}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};
export default FormActionEditProfile;
