import React from 'react';
import { Grid, Typography, Box, CircularProgress } from '@material-ui/core';
import TablePerformanceTest from './TablePerformanceTest';
import { Summary } from './Summary';
import { DatePerformaceTest } from './DatePerformaceTest';
import { iServiceEndpoint } from 'app/core/interfaces/TablePerformance';
import { helpCheck } from '../../../services/dashboard.service';
import { filterDupByKey } from '../../../utils/common';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { iPerformanceTestProps } from 'app/core/models/PerformanceTest';
import { AxiosResponse } from 'axios';

const PerformanceTestComponent = (props: iPerformanceTestProps): JSX.Element => {
	let { endPointsToCheck, handleRefresh, loading } = props;
	let servicesUniques: iServiceEndpoint[] = [];
	let initCalls = { successCalls: 0, failedCalls: 0, errorCalls: 0 };
	const [calls, setCalls] = React.useState(initCalls);
	const [endpoints, setEndpoints] = React.useState(endPointsToCheck);
	const { t } = useTranslation('performanceTest');

	React.useEffect(() => {
		servicesUniques = filterDupByKey(endPointsToCheck, 'uri');
		testEndpoints();
	}, []);

	React.useEffect(() => {
		if (servicesUniques.length === 0) {
			servicesUniques = filterDupByKey(endPointsToCheck, 'uri');
		}
		testEndpoints();
	}, [endPointsToCheck]);

	React.useEffect(() => {
		updateCalls();
	}, [endpoints]);

	const updateEndPoints = (uri: string, stateResponse: string, codResponse: number): void => {
		let ncalls: number = 0;
		endPointsToCheck.forEach((endpointItem: iServiceEndpoint, indexEndoint: number) => {
			if (uri === endpointItem.uri && !endpointItem.codResponse) {
				ncalls = ncalls + 1;
				endPointsToCheck[indexEndoint].stateResponse = stateResponse;
				endPointsToCheck[indexEndoint].codResponse = codResponse;
			}
		});
		setEndpoints(endPointsToCheck.slice());
	};

	const testEndpoints = () => {
		servicesUniques.forEach((serviceItem, indexService) => {
			if (!serviceItem.stateResponse) {
				helpCheck(serviceItem)
					.then((data: AxiosResponse) => {
						const statusText = data.status === 204 || data.status === 200 ? 'OK' : 'error';
						servicesUniques[indexService].stateResponse = statusText;
						servicesUniques[indexService].codResponse = data.status;
						updateEndPoints(servicesUniques[indexService].uri, statusText, data.status);
					})
					.catch((error: any) => {
						// Error
						let stateResponse = 'error';
						let codResponse = 999;
						if (error.response) {
							/*
							 * The request was made and the server responded with a
							 * status code that falls out of the range of 2xx
							 */
							servicesUniques[indexService].stateResponse = error.response.statusText
								? error.response.statusText
								: 'error';
							servicesUniques[indexService].codResponse = error.response.status;
							stateResponse = error.response.statusText ? error.response.statusText : 'error';
							codResponse = error.response.status;
						} else if (error.request) {
							/*
							 * The request was made but no response was received, `error.request`
							 * is an instance of XMLHttpRequest in the browser and an instance
							 * of http.ClientRequest in Node.js
							 */
							console.log(error.request);
						} else {
							// Something happened in setting up the request and triggered an Error
							console.log('Error', error.message);
						}
						console.log(error.config);
						updateEndPoints(servicesUniques[indexService].uri, stateResponse, codResponse);
					});
			}
		});
	};

	const updateCalls = () => {
		initCalls = { successCalls: 0, failedCalls: 0, errorCalls: 0 };
		endPointsToCheck.forEach((endpointItem: any) => {
			if (endpointItem.codResponse) {
				switch (endpointItem.codResponse) {
					case 204:
					case 200:
						initCalls.successCalls = initCalls.successCalls + 1;
						break;
					case 500:
						initCalls.errorCalls = initCalls.errorCalls + 1;
						break;
					default:
						initCalls.failedCalls = initCalls.failedCalls + 1;
				}
			}
		});
		setCalls(initCalls);
	};

	return (
		<Box className="c-dashboard">
			<Box className={'c-performancetest'}>
				<Grid container spacing={5}>
					<Grid item xs={12} md={6} className={'c-dashboard__paragraph'}>
						<Typography
							className={'c-dashboard__title'}
							component={'h1'}
							variant={'h2'}
							color="textSecondary"
							gutterBottom
						>
							{t('title')}
						</Typography>
						<Typography component={'strong'} className={'c-dashboard__introduction-title'} display={'inline'}>
							{t('introduction.title')}
						</Typography>
						<Typography component={'p'} className={'c-dashboard__introduction-subtitle'} display={'inline'}>
							{t('introduction.subtitle')}
						</Typography>
						<Typography component={'p'} className={'c-dashboard__introduction-description'}>
							{t('introduction.description')}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={8} className="c-performancetest__date">
								<DatePerformaceTest />
							</Grid>
							<Grid item xs={4} className={'c-performancetest__wrapperBtn'}>
								<Button
									variant="contained"
									className={`c-button c-performancetest__reloadBtn `}
									disabled={loading}
									onClick={handleRefresh}
								>
									{t('reload')}
									{loading && <CircularProgress size={24} className={'c-performancetest__progressBtn'} />}
								</Button>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Summary
								successCalls={calls.successCalls}
								failedCalls={calls.failedCalls}
								errorCalls={calls.errorCalls}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<TablePerformanceTest endPointsToCheck={endpoints} loading={loading} />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default PerformanceTestComponent;
