import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from '../../routes/routes';
import Grid from '@material-ui/core/Grid';
import { AareonLayout } from 'app/core/interfaces/AareonLayout';
import { Box, CssBaseline, Typography } from '@material-ui/core';
import Logo from '../../components/Logo';
import LanguageMenu from '../../components/LanguageMenu';
import { useTranslation } from 'react-i18next';

const LoginLayout: React.FC<AareonLayout> = (props) => {
	const {
		route: { routes }
	} = props;
	const { t } = useTranslation(['login', 'adviceSnackbar']);
	return (
		<Grid container>
			<Grid item xs={12}>
				<Box component="main" className={'' + ' c-login'}>
					<CssBaseline />
					<Box className="c-login__logo">
						<Logo />
					</Box>
					<Box className="c-login__language">
						<LanguageMenu login />
					</Box>
					<Box className={'c-login__formContainer'}>
						<Typography component="h1" variant="h1" className={'c-login__title'}>
							{t('title')}
						</Typography>
						<Switch>
							{routes &&
								routes.length &&
								routes.map((route, i) => {
									return <RouteWithSubRoutes key={i} {...route} parentRoutes={routes} />;
								})}
						</Switch>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default LoginLayout;
