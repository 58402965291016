/**
 * SEARCHBAR: ACTION TYPES CONSTS
 */
export const GET_SEARCHDATA_REQUEST = 'GET_SEARCHDATA_REQUEST';
export const GET_SEARCHDATA_SUCCESS = 'GET_SEARCHDATA_SUCCESS';
export const GET_SEARCHDATA_ERROR = 'GET_SEARCHDATA_ERROR';
export const GET_SEARCHDATA_ENDPOINT = '/src/app/assets/static-files/search.json';

/**
 * SEARCHRESULTS: TYPES OF THE RESULTS
 */
export const GUIDES = 'guides';
export const APIREFERENCE = 'api-reference';
