import React, { useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button } from '@material-ui/core';

const ScrollToTopButton: React.FC = () => {
	const [showButton, setButtonVisibility] = React.useState(false);

	useEffect(() => {
		window.addEventListener('scroll', checkScroll);

		return () => {
			window.removeEventListener('scroll', checkScroll);
		};
	}, []);

	const checkScroll = () => {
		const scrollValue = window.scrollY ? window.scrollY : document.documentElement.scrollTop;
		setButtonVisibility(scrollValue > 200 ? true : false);
	};

	return (
		<React.Fragment>
			{showButton && (
				<Button size="small" className="c-scrolltopbutton" onClick={() => animateScroll.scrollToTop()}>
					<ArrowForwardIosIcon className="c-scrolltopbutton__icon" />
				</Button>
			)}
		</React.Fragment>
	);
};

export default ScrollToTopButton;
