import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import { Avatar, Button, Menu, MenuItem } from '@material-ui/core';

// @ts-ignore
import ManagementIcon from '../../assets/img/icons/managementIcon.svg';
import { iRoleMenu } from 'app/core/interfaces/RoleMenu';
import { setLogout } from '../Login/login.actions';
import SwitchMode from '../SwitchMode';
import AvatarContent from '../AvatarContent';

import LanguageMenu from '../LanguageMenu';

const AvatarRoleMenu: React.FC<iRoleMenu> = (props) => {
	const { mainRole } = props;
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [t] = useTranslation('roleMenu');
	// TODO: Change admin role for ROLE_ADMIN without quotes when backend fix the auth service
	// TODO: Put into a const file
	const isAdmin = mainRole === 'admin';
	let history = useHistory();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		dispatch(setLogout());
	};
	return (
		<Avatar className={'c-avatar'} id="role-avatar">
			<Button
				id="avatar-menu-button"
				aria-controls="avatar-role-menu"
				aria-haspopup="true"
				onClick={handleClick}
				className={'c-avatar__label c-avatar__label--bold'}
			>
				<AvatarContent />
			</Button>
			<Menu
				elevation={0}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				id="avatar-role-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				classes={{ paper: 'c-roleMenu__menu', list: 'c-roleMenu__list' }}
				onClose={() => {
					handleClose();
				}}
			>
				<MenuItem className={'c-roleMenu__list-item'} id="menuItem-profile" onClick={() => history.push('/profile')}>
					<PersonIcon className={'c-roleMenu__icon'} />
					<span className={'c-roleMenu__label'}>{t('profile')}</span>
				</MenuItem>
				{isAdmin && (
					<MenuItem className={'c-roleMenu__list-item'} id="menuItem-admin" onClick={() => history.push('/admin')}>
						<ManagementIcon className={'u-customIcon c-roleMenu__icon'} />
						<span className={'c-roleMenu__label'}>{t('user-management')}</span>
					</MenuItem>
				)}
				<MenuItem className={'c-roleMenu__list-item'} id="menuItem-theme">
					<LanguageMenu />
				</MenuItem>
				<MenuItem className={'c-roleMenu__list-item'} id="menuItem-theme">
					<span className={'c-roleMenu__label'}>{t('theme')}</span>
					<SwitchMode />
				</MenuItem>
				<MenuItem className={'c-roleMenu__list-item'} id="menuItem-logout" onClick={handleLogOut}>
					<ExitToAppIcon className={'c-roleMenu__icon'} />
					<span className={'c-roleMenu__label'}>{t('logout')}</span>
				</MenuItem>
			</Menu>
		</Avatar>
	);
};

export default AvatarRoleMenu;
