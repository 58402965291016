import React from 'react';
import { withWidth } from '@material-ui/core';
import { LogoState } from './model/LogoState';

// @ts-ignore
import AareonWhite from '../../assets/img/aareon_white.svg';
// @ts-ignore
import AareonWhiteSimple from '../../assets/img/aareon_white_simple.svg';

const MainLogo: React.FC<LogoState> = (props) => {
	const { width } = props;
	const mobile = width === 'sm' || width === 'xs';

	return (
		<div {...props}> {mobile ? <AareonWhiteSimple /> : <AareonWhite style={{ width: '192px', height: '45px' }} />}</div>
	);
};

export default withWidth()(MainLogo);
