import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { getIconElement } from '../../utils/icons';
import { SimpleMenuProps } from 'app/core/interfaces/SimpleMenu';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { getUser } from '../Login/login.selectors';
import { useSelector } from 'react-redux';

const SimpleMenu: React.FC<SimpleMenuProps> = (props) => {
	const { itemsMenu } = props;
	const userInfo = useSelector(getUser);
	const mainRole = userInfo?.user?.role;
	let history = useHistory();

	return (
		<List component="nav" className={`c-sidenav u-aareon-scrollbar`}>
			{itemsMenu.map((parentRoute, index) => {
				const hasPermissions = parentRoute.data.role ? parentRoute.data.role === mainRole : true;
				return (
					parentRoute &&
					parentRoute.routes &&
					hasPermissions && (
						<Box key={`c-sidenav__category-${index}`}>
							<ListSubheader className={'c-sidenav__category'}>{parentRoute.data.label}</ListSubheader>
							{parentRoute.routes.map((route, childrenIndex) => {
								const hasPermissions = route.data.role ? route.data.role === mainRole : true;
								const label = route.data.label;
								return (
									hasPermissions && (
										<ListItem
											button
											selected={location.pathname === route.url}
											classes={{
												selected: 'c-sidenav__simple-parent--active',
												root: 'c-sidenav__list c-sidenav__simple-parent'
											}}
											data-index={index}
											key={`c-sidenav__list-${childrenIndex}`}
											onClick={() => history.push(route.url)}
										>
											<ListItemIcon className={'c-sidenav__label c-sidenav__label--blue'}>
												{getIconElement(route.data.icon)}
											</ListItemIcon>
											<ListItemText primary={label} className={'c-sidenav__label c-sidenav__label--blue'} />
										</ListItem>
									)
								);
							})}
						</Box>
					)
				);
			})}
		</List>
	);
};

export default SimpleMenu;
