import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { isCustomModalOpen, isCustomModalState } from './customModal.selectors';
import { closeCustomModal } from './customModal.actions';

// TODO: Refactor this spaguetti code
const CustomModal: React.FC = () => {
	const _isCustomModalOpen = useSelector(isCustomModalOpen);
	const _isCustomModalState = useSelector(isCustomModalState);
	const dispatch = useDispatch();

	const handleCloseModal = () => {
		if (_isCustomModalOpen) {
			dispatch(closeCustomModal());
		}
	};

	return (
		<div className="c-custommodal">
			<Dialog
				classes={{ paper: 'c-custommodal__container' }}
				aria-labelledby="customized-dialog-title"
				onClick={_isCustomModalState.clickOutToClose ? handleCloseModal : () => {}}
				open={_isCustomModalOpen}
			>
				<MuiDialogTitle classes={{ root: 'c-custommodal__title' }} disableTypography>
					<Typography variant="h5">{_isCustomModalState.title}</Typography>
				</MuiDialogTitle>
				<MuiDialogContent classes={{ dividers: 'c-custommodal__dividers' }} dividers>
					<Typography gutterBottom>{_isCustomModalState.content}</Typography>
				</MuiDialogContent>
				<MuiDialogActions>
					{_isCustomModalState.button.map((i_button, index) => (
						<Button
							className={'c-custommodal__btn c-custommodal__btn--cancel'}
							key={`dialogBtn-${index}`}
							onClick={handleCloseModal}
							variant={i_button.variant}
						>
							{i_button.content}
						</Button>
					))}
				</MuiDialogActions>
			</Dialog>
		</div>
	);
};

export default CustomModal;
