import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, Typography, CircularProgress, Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { PasswordPolicyProps } from './model/PasswordPolicyBox';

const PasswordPolicyBox: React.FC<PasswordPolicyProps> = (props) => {
	const { policies, validations, mobile, loaded } = props;
	const [t] = useTranslation(['changePassword']);

	return loaded ? (
		<>
			<div
				className={
					mobile ? 'c-change-password__policies c-change-password__policies--mobile' : 'c-change-password__policies'
				}
			>
				<Typography paragraph className={'c-change-password__policies-paragraph--title'} id="title">
					<ErrorOutlineIcon fontSize="large" className={'c-change-password__icon-adv'} />
					<InputLabel className={'c-change-password__policies-label c-change-password__policies-label--title'}>
						{t('rules.title')}
					</InputLabel>
				</Typography>
				{policies.min_length !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="length">
						{validations.length ? (
							<CheckCircle className={'c-change-password__icon-correct'} />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.minChar', { count: policies.min_length })}
						</InputLabel>
					</Typography>
				)}
				{policies.min_lower_case_characters !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="lower">
						{validations.lowercase ? (
							<CheckCircle className={'c-change-password__icon-correct'} />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.minLower', { count: policies.min_lower_case_characters })}
						</InputLabel>
					</Typography>
				)}
				{policies.min_upper_case_characters !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="upper">
						{validations.uppercase ? (
							<CheckCircle className={'c-change-password__icon-correct'} id="checkCircle" />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} id="cancelIcon" />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.minUpper', { count: policies.min_upper_case_characters })}
						</InputLabel>
					</Typography>
				)}
				{policies.min_digits !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="digits">
						{validations.number ? (
							<CheckCircle className={'c-change-password__icon-correct'} />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.minDigits', { count: policies.min_digits })}
						</InputLabel>
					</Typography>
				)}
				{policies.min_special_digits !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="special">
						{validations.specialchar ? (
							<CheckCircle className={'c-change-password__icon-correct'} />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.minSpecial', { count: policies.min_special_digits })}
						</InputLabel>
					</Typography>
				)}
				{policies.min_min_special_or_digits !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="specialdigits">
						{validations.specialnumberchar ? (
							<CheckCircle className={'c-change-password__icon-correct'} />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.minSpecialOrDigits', { count: policies.min_min_special_or_digits })}
						</InputLabel>
					</Typography>
				)}
				{policies.compare_characters !== 0 && (
					<Typography className={'c-change-password__policies-paragraph'} id="comparechars">
						{validations.comparechars ? (
							<CheckCircle className={'c-change-password__icon-correct'} />
						) : (
							<CancelIcon className={'c-change-password__icon-error'} />
						)}
						<InputLabel className={'c-change-password__policies-label'}>
							{t('rules.compareChars', { count: policies.compare_characters })}
						</InputLabel>
					</Typography>
				)}
			</div>
		</>
	) : (
		<Grid container direction="row" justify="center" alignItems="center">
			<CircularProgress />
		</Grid>
	);
};

export default PasswordPolicyBox;
