import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Snackbar,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	Grid
} from '@material-ui/core';
import Alert from '../AlertWrapper';
import { getCookieList, getUserCookies, setUserCookies } from '../../services/cookie.service';
import { Cookie } from 'app/core/interfaces/AdviceCookie';
//import { useHistory } from 'react-router-dom';

// TODO - Move to flux
const CookieSettings: React.FC = () => {
	const [t] = useTranslation('cookiesInfo');
	const [cookieList, setUserCookieList] = React.useState<Cookie[]>([]);
	const [snackbarOpen, setSnackbarOpen] = useState<{ open: boolean; success: boolean }>({
		open: false,
		success: false
	});
	// Should be this in a config file?
	const expirationTime = 181;
	const hideDuration = 6000;
	// FIXME - Use history hook as others component does
	// let history = useHistory(); TODO: This method freeze the unit test: https://github.com/ReactTraining/react-router/issues/6939

	const saveCookies = () => {
		setUserCookies(cookieList, expirationTime);
		handleSnackbarOpen(true);
	};

	const toggleCookie = (cookieName: string) => {
		let toggledCookie = cookieList.find((cookie) => cookie.cookieName === cookieName);
		if (toggledCookie) {
			toggledCookie.isChecked = !toggledCookie.isChecked;
		}
		setUserCookieList([...cookieList]);
	};

	useEffect(() => {
		const loadCookies = async () => {
			const cookies = await getCookieList();
			const userCookies = await getUserCookies();
			let fullCookies: Cookie[] = [];
			if (cookies && userCookies) {
				fullCookies = userCookies.concat(
					cookies.data.filter((item) => userCookies.map((cookie) => cookie.cookieName).indexOf(item.cookieName) < 0)
				);
			}
			setUserCookieList(fullCookies);
		};
		loadCookies();
	}, []);

	const handleSnackbarOpen = (success: boolean) => {
		setSnackbarOpen({ open: true, success: success });
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen({ open: false, success: true });
	};

	return (
		<React.Fragment>
			<Snackbar
				id="test-snackbarcookie"
				open={snackbarOpen.open}
				autoHideDuration={hideDuration}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				classes={{ root: 'c-snackbar' }}
			>
				{snackbarOpen.success ? (
					<Alert
						onClose={handleSnackbarClose}
						severity="success"
						variant="filled"
						message={t('cookieSettings.saveCookiesAlert.success')}
					/>
				) : (
					<Alert
						onClose={handleSnackbarClose}
						severity="error"
						variant="filled"
						message={t('cookieSettings.saveCookiesAlert.error')}
					/>
				)}
			</Snackbar>
			<Typography className={'c-dashboard__title'} component={'h1'} variant={'h2'} color="textSecondary" gutterBottom>
				{t('cookieInfoView.pagetitle')}
			</Typography>
			<TableContainer>
				<Table aria-label="simple table">
					<TableBody>
						{cookieList &&
							cookieList.map((cookie, index: number) => {
								return (
									<TableRow key={index} classes={{ root: 'c-cookie-infoview__tablebody' }}>
										<TableCell scope="row">
											<React.Fragment>
												{<h4 className="c-cookie-settings__text">{t(cookie.title)}</h4>}
												{<span className="c-cookie-settings__text">{t(cookie.text)}</span>}
											</React.Fragment>
										</TableCell>
										<TableCell scope="row">
											<Switch
												id={cookie.cookieName}
												disabled={cookie.disabled}
												checked={cookie.isChecked}
												onChange={() => {
													toggleCookie(cookie.cookieName);
												}}
												color="primary"
												name={`${cookie.cookieName}`}
												inputProps={{ 'aria-label': 'primary checkbox' }}
												classes={{
													colorPrimary: 'c-action-table__switch'
												}}
											/>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<Grid container direction="row" justify="flex-end">
				<Grid item>
					<Button
						className={'c-button c-cookie-settings__btn'}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => {
							saveCookies();
						}}
					>
						{t('mainCookie.btnAcceptContent')}
					</Button>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default CookieSettings;
