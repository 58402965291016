import { SET_OPEN_DIALOG, SET_CLOSE_DIALOG } from './profileFormDialog.consts';
import { ProfileFormDialogActionTypes } from './profileFormDialog.types';
import { ProfileFormDialogState } from './model/ProfileFormDialog';

const initialState: ProfileFormDialogState = {
	open: false
};

const profileDialogReducer = (state = initialState, action: ProfileFormDialogActionTypes): ProfileFormDialogState => {
	switch (action.type) {
		case SET_OPEN_DIALOG:
			return {
				...state,
				open: true
			};
		case SET_CLOSE_DIALOG:
			return {
				...state,
				open: false
			};
		default:
			return state;
	}
};

export default profileDialogReducer;
