import React from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Plane from '../../themes/primary/img/overview/icons/plane.svg';
import Doc from '../../themes/primary/img/overview/icons/doc.svg';
import Bulb from '../../themes/primary/img/overview/icons/bulb.svg';
import Code from '../../themes/primary/img/overview/icons/code.svg';
import Tools from '../../themes/primary/img/overview/icons/tools.svg';
import LinkIcon from '../../themes/primary/img/overview/icons/link.svg';

const OverviewCard: React.FC<any> = ({ cardTitle, cardText, cardIcon, urlPath, showLink }: any) => {
	const src = `src/app/themes/primary/img/overview/icons/${cardIcon}.svg`;
	let icon = <Plane />;
	switch (cardIcon) {
		case 'plane':
			icon = <Plane className={'c-overview-card__img plane'} />;
			break;
		case 'doc':
			icon = <Doc className={'c-overview-card__img doc'} />;
			break;
		case 'bulb':
			icon = <Bulb className={'c-overview-card__img bulb'} />;
			break;
		case 'code':
			icon = <Code className={'c-overview-card__img code'} />;
			break;
		case 'tools':
			icon = <Tools className={'c-overview-card__img tools'} />;
			break;
		case 'link':
			icon = <LinkIcon className={'c-overview-card__img link'} />;
			break;
		default:
			icon = <LinkIcon className={'c-overview-card__img link'} />;
			break;
	}
	return (
		<div className={'c-overview-card'}>
			<Grid container direction="row" justify="flex-start" alignItems="center">
				<Grid item md={3}>
					<Hidden only={['xs', 'sm']}>{icon}</Hidden>
				</Grid>
				<Grid item xs={12} sm={12} md={9}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={2} sm={4}>
							<Hidden only={['md', 'lg', 'xl']}>{icon}</Hidden>
						</Grid>
						<Grid item xs={10} sm={8} md={12}>
							{!showLink ? (
								<Typography className={'c-overview-card__title-card'}>{cardTitle}</Typography>
							) : (
								<Link to={`${urlPath}`} className={'c-overview-card__card-link'}>
									<Typography className={'c-overview-card__title-card-link'}>{cardTitle}</Typography>
								</Link>
							)}
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={12} sm={12} md={12}>
							<Typography variant="body2" className={'c-overview-card__title-body'}>
								{cardText}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default OverviewCard;
