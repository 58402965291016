import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { InputPassword as InputPW } from '../../core/models/InputPassword';

const InputPassword: React.FC<InputPW> = (props) => {
	const {
		name,
		id,
		classes,
		showPasswordAvailable = true,
		autoComplete = 'off',
		onChange,
		onShowPasswordChange,
		placeholder,
		value,
		error = false,
		dataError = '',
		disabled = false
	} = props;

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		if (onShowPasswordChange) {
			onShowPasswordChange(!showPassword);
		}
		setShowPassword(!showPassword);
	};
	const handleOnChange = (e: object): void => {
		if (onChange && e) {
			onChange(e);
		}
	};

	return (
		<Input
			name={name}
			id={id}
			classes={classes}
			value={value}
			type={showPassword && showPasswordAvailable ? 'text' : 'password'}
			autoComplete={autoComplete}
			onChange={handleOnChange}
			placeholder={placeholder}
			error={error}
			data-error={dataError}
			disabled={disabled}
			endAdornment={
				showPasswordAvailable && (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							className={'c-booinput__icon'}
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				)
			}
		/>
	);
};

export default InputPassword;
