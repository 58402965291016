import { TotalState } from 'app/store/model/TotalState';

/**
 * SIDEBAR: SELECTORS
 *
 */

/**
 * @function
 * @name isSidebarOpen
 * @param {TotalState} state
 * @returns {boolean} Returns true if sidebar is open.
 */
export const isSidebarOpen = (state: TotalState): boolean => state.sidebarState.open;

/**
 * @function
 * @name isSidebarMounted
 * @param {TotalState} state
 * @returns {boolean} Returns true if sidebar is mount.
 */
export const isSidebarMounted = (state: TotalState): boolean => state.sidebarState.mount;
