import { CUSTOM_MODAL_CLOSE, CUSTOM_MODAL_OPEN } from './customModal.const';
import { CustomModalActionTypes } from './customModal.types';
import { CustomModalState } from './model/CustomModalState';

const initialState: CustomModalState = {
	open: false,
	customModalData: {
		title: '',
		content: '',
		button: [{ content: '', variant: 'outlined', action: () => {} }],
		clickOutToClose: true
	}
};

const customModalReducer = (state = initialState, action: CustomModalActionTypes): CustomModalState => {
	switch (action.type) {
		case CUSTOM_MODAL_OPEN:
			return {
				...state,
				open: true,
				customModalData: {
					title: action.payload.title ? action.payload.title : initialState.customModalData.title,
					content: action.payload.content,
					button: action.payload.button,
					clickOutToClose: action.payload.clickOutToClose
				}
			};
		case CUSTOM_MODAL_CLOSE:
			return {
				...state,
				open: false,
				customModalData: {
					title: initialState.customModalData.title,
					content: initialState.customModalData.content,
					button: initialState.customModalData.button,
					clickOutToClose: initialState.customModalData.clickOutToClose
				}
			};
		default:
			return state;
	}
};

export default customModalReducer;
