import React from 'react';
import { CustomRowActionProps } from '../ActionTable/model/ActionTable';
import Switch from '@material-ui/core/Switch';

const ActivationAction: React.FC<CustomRowActionProps> = (props) => {
	const { active, callback } = props;
	return (
		<div>
			<Switch
				checked={active}
				onChange={callback}
				name="checkedA"
				color="primary"
				inputProps={{ 'aria-label': 'secondary checkbox' }}
				classes={{
					colorPrimary: 'c-action-table__switch'
				}}
			/>
		</div>
	);
};
export default ActivationAction;
