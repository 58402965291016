import React from 'react';
import Grid from '@material-ui/core/Grid';
import FooterContent from './content';

const Footer: React.FC = () => {
	return (
		<footer id="MainFooter" className={`c-footer`}>
			<Grid container direction="column" justify="center" alignItems="stretch">
				<FooterContent />
			</Grid>
		</footer>
	);
};

export default Footer;
