import React from 'react';
import { Divider, List } from '@material-ui/core';
import { AareonRoute } from 'app/core/interfaces/AareonRoute';
import { MenuTableOfContentProps } from './MenuTableOfContentProps';
import VerticalHeader from './VerticalHeader';
import VerticalScrollChild from './VerticalScrollChild';
import VerticalChild from './VerticalChild';

const MenuTableOfContent: React.FC<MenuTableOfContentProps> = (props) => {
	const { itemsMenu, specialIEMenu, short, level, scrollable, type, className } = props;
	const specialIEClass = specialIEMenu && short ? 'c-sidenav--ie-special' : 'c-sidenav--ie-top-fix';
	const shortClass = short ? 'c-sidenav--short' : '';
	const classType =
		type === 'nav'
			? `c-sidenav u-aareon-scrollbar ${specialIEClass} ${shortClass} c-sidenav--${className}`
			: `c-sidenav--${className}`;
	const [child, setChild] = React.useState<AareonRoute>(itemsMenu[0]);

	React.useEffect(() => {
		let child = itemsMenu[0];
		itemsMenu.forEach((item) => {
			if (location.pathname.indexOf(item.url) !== -1) {
				child = item;
			}
		});
		setChild(child);
	}, [location.pathname]);

	return (
		<React.Fragment>
			<Divider />
			<List component={type} className={classType}>
				{level === 1 &&
					itemsMenu.map((item: AareonRoute, index: number) => {
						return item && item.data.visible && <VerticalHeader scrollItem={item} key={index} />;
					})}
				{level === 2 &&
					(scrollable ? <VerticalScrollChild scrollItem={child} /> : <VerticalChild scrollItem={child} />)}
			</List>
		</React.Fragment>
	);
};

export default MenuTableOfContent;
