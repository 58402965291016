import React, { useEffect, useState } from 'react';
import {
	Button,
	Box,
	Grid,
	InputBase,
	InputLabel,
	Link,
	Typography,
	CircularProgress,
	Checkbox,
	FormControlLabel,
	FormHelperText
} from '@material-ui/core';
import InputPassword from '../InputPassword';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useDispatch, useSelector } from 'react-redux';
import { loginError, isRememberMe, isGetUserNameToRemember, getExpiredSession } from './login.selectors';
import {
	getLoginError,
	setRememberMe,
	setUserNameToRemember,
	setExpireSession,
	cleanErrors,
	getLoginSuccess
} from './login.actions';
import { getSearchData } from '../SearchBar/searchbar.actions';
import { useHistory } from 'react-router-dom';
import { userIsLogged } from './login.selectors';
import { auth } from './login.service';
import { Login as LoginObject } from './model/Login';

const Login: React.FC = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const loginMsgError = useSelector(loginError);
	const userNameToRememberIt = useSelector(isGetUserNameToRemember);
	const expiredSession = useSelector(getExpiredSession);
	const _rememberMe = useSelector(isRememberMe);
	const { t } = useTranslation(['login', 'adviceSnackbar']);
	const isLogged = useSelector(userIsLogged);
	let history = useHistory();
	let rememberMe = _rememberMe;

	useEffect(() => {
		dispatch(cleanErrors());
	}, []);

	useEffect(() => {
		let whereToGo = '/login';
		if (isLogged) {
			whereToGo = location.pathname;
			if (location.pathname === '/login') {
				whereToGo = '/';
			}
		}
		history?.push(whereToGo);
	}, [isLogged]);
	const checkEmptyString = (data: string) => {
		return data.replace(/\s+/g, '') === '';
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const { password, username }: any = event.currentTarget.elements;
		if (checkEmptyString(username.value) || checkEmptyString(password.value)) {
			checkEmptyString(username.value) &&
				!username.parentNode.classList.contains('c-booinput__input-error') &&
				username.parentNode.classList.toggle('c-booinput__input-error');
			checkEmptyString(password.value) &&
				password.parentNode.classList.replace('MuiInput-underline', 'c-booinput__input-error');
			return;
		}
		setLoading(true);
		auth(username.value, password.value)
			.then((response: LoginObject) => {
				dispatch(getLoginSuccess({ ...response }));
				dispatch(getSearchData());
				dispatch(setRememberMe(rememberMe));

				if (rememberMe) {
					dispatch(setUserNameToRemember());
				}
			})
			.catch((error: Error) => {
				dispatch(getLoginError(error));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRememberMe = () => {
		rememberMe = !rememberMe;
	};
	const LoginForm = () => (
		<Box className={'c-login__form'}>
			{loginMsgError && (
				<Box className={'c-login__error'}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={2} className="c-login__errorIconWrapper">
							<ErrorOutlineIcon className="c-login__errorIcon" />
						</Grid>
						<Grid item xs={10}>
							<Typography component="span" className={''}>
								{loginMsgError}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			)}
			<form noValidate onSubmit={handleSubmit}>
				<InputLabel htmlFor="username" className={'c-booinput__label c-login__textLabelForm'}>
					{t('form.user')}
				</InputLabel>
				<InputBase
					name="username"
					id="username"
					data-error={t('form.userError')}
					classes={{ root: 'c-booinput', input: 'c-booinput__inner-input' }}
					defaultValue={userNameToRememberIt}
				/>

				<InputLabel htmlFor="password" className={'c-booinput__label c-login__textLabelForm'}>
					{t('form.password')}
				</InputLabel>

				<InputPassword
					name="password"
					id="password"
					dataError={t('form.passwordError')}
					classes={{ root: 'c-booinput', input: 'c-booinput__inner-input' }}
				/>
				<FormHelperText className={'c-login__textLabelForm c-login__textLabelForm--right'}>
					<Link href="/login/forgot" className={'c-login__textLabelForm c-login__link'}>
						{t('form.forgotPassword')}
					</Link>
				</FormHelperText>
				<Box mt={3} mb={3}>
					<Button
						variant="contained"
						type="submit"
						id="loginButton"
						classes={{
							root: 'c-button c-login__submitBtn',
							label: 'c-login__submitLabel',
							disabled: 'c-login__submitBtn--disabled'
						}}
						disabled={loading}
					>
						{t('form.button.login')}
						{loading && <CircularProgress size={24} className={'c-login__submitProgress'} />}
					</Button>
				</Box>
				<FormControlLabel
					value="end"
					control={
						<Checkbox
							color="default"
							classes={{
								root: 'c-login__checkbox'
							}}
							onChange={handleRememberMe}
							defaultChecked={_rememberMe}
						/>
					}
					label={t('form.rememberme')}
					labelPlacement="end"
				/>
				<Grid container direction="row" justify="center" alignItems="flex-start">
					<Link
						href="https://www.aareon.com/sixcms/detail.php/3627"
						className={'c-login__textLabelForm c-login__link'}
						target="_blank"
					>
						{t('form.privacyLink')}
					</Link>
				</Grid>
			</form>
		</Box>
	);

	const ErrorSessionMessage = (props: { type: string }) => {
		const { type } = props;

		let errorMessage: string = '';
		if (type === 'time') {
			errorMessage = 'sessionError.messageExpiredSession';
		} else if (type === 'editUser') {
			errorMessage = 'sessionError.messageEditUser';
		}

		return (
			<React.Fragment>
				<Box className={'c-login__errorSession'} mb={3}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={2} className="c-login__errorIconWrapper">
							<ErrorOutlineIcon className="c-login__errorSessionIcon" />
						</Grid>
						<Grid item xs={10}>
							<Typography component="span" className={''}>
								{t(errorMessage)}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Grid container direction="row" justify="center" alignItems="flex-start">
					<Typography
						component="span"
						className={'c-login__textLabelForm c-login__link'}
						onClick={() => dispatch(setExpireSession(false, expiredSession.initialRetry, ''))}
					>
						{t('sessionError.link')}
					</Typography>
				</Grid>
			</React.Fragment>
		);
	};

	return <>{expiredSession?.expired ? <ErrorSessionMessage type={expiredSession.type} /> : <LoginForm />}</>;
};

export default Login;
