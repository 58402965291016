import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogProps, Divider, Snackbar } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../AlertWrapper';
import { setCloseDialog, setOpenDialog } from './profileFormDialog.actions';
import { isProfileFormDialogOpen } from './profileFormDialog.selectors';
import { CreateUserBody, EditUserBody } from 'app/core/interfaces/CreateUser';
import { createUser, editUser, getIdentity } from '../../services/users.service';
import { setLogout, setExpireSession } from '../Login/login.actions';
import { getUserToken, getUser } from '../Login/login.selectors';
import { setRefreshUserList } from '../UserList/userList.actions';
import { USERNAME } from './../../utils/Validators/regexp';
import { useRef } from 'react';
import FormActionEditProfile from './FormActionEditProfile';
import FormActionCreateProfile from './FormActionCreateProfile';
import FormCreateProfile from './FormCreateProfile';
import FormEditProfile from './FormEditProfile';
import { ProfileCreatorProps } from './model/ProfileCreator';

const MAXWIDTH: DialogProps['maxWidth'] = 'sm';

const ProfileCreator: React.FC<ProfileCreatorProps> = (props) => {
	const { t } = useTranslation('profileForm');
	const { open, user, editMode } = props;
	const dispatch = useDispatch();

	const _isProfileFormDialogOpen = useSelector(isProfileFormDialogOpen);
	const userInfo = useSelector(getUser);

	const [snackbarOpen, setSnackbarOpen] = useState<{ open: boolean; error: boolean; message: string }>({
		open: false,
		error: true,
		message: t('form.response.error.create')
	});

	// Username
	const [username, setUsername] = useState<string>('');
	const [usernameError, setUsernameError] = useState<boolean>(false);
	const [isValidUsername, setIsValidUsername] = useState<boolean>(false);
	const [helperUsername, setHelperUsername] = useState<string>('form.required');
	// First name
	const [firstName, setFirstName] = useState<string>('');
	const [isValidFirstName, setIsValidFirstName] = useState<boolean>(false);
	const [firstNameError, setFirstNameError] = useState<boolean>(false);
	// Last name
	const [lastName, setLastName] = useState<string>('');
	const [isValidLastName, setIsValidLastName] = useState<boolean>(false);
	const [lastNameError, setLastNameError] = useState<boolean>(false);
	// Role
	const [role, setRole] = useState<string>('tenant');
	//Password
	const [password, setPassword] = useState<string>('');
	const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
	const [passwordError, setPasswordError] = useState<boolean>(false);
	// Re-password
	const [rePassword, setRePassword] = useState<string>('');
	const [rePasswordError, setRePasswordError] = useState<boolean>(false);
	const [isValidRePassword, setIsValidRePassword] = useState<boolean>(false);
	const [helperRePassword, setHelperRePassword] = useState<string>('form.required');
	// Activation Date
	const [activationDate, setActivationDate] = useState<moment.Moment | null>(moment(new Date()));
	const [isValidActivationDate, setIsValidActivationDate] = useState<boolean>(true);
	// Deactivation Date
	const [deactivationDate, setDeactivationDate] = useState<moment.Moment | null>(null);
	const [isValidDeactivationDate, setIsValidDeactivationDate] = useState<boolean>(true);
	// Inputs
	const [createAnother, setCreateAnother] = useState<boolean>(false);
	const [disableAccept, setDisableAccept] = useState<boolean>(true);

	const token = useSelector(getUserToken);

	useEffect(() => {
		if (!editMode) {
			// create user
			if (
				isValidActivationDate &&
				isValidDeactivationDate &&
				isValidFirstName &&
				isValidLastName &&
				isValidUsername &&
				isValidPassword &&
				isValidRePassword
			) {
				setDisableAccept(false);
			} else {
				setDisableAccept(true);
			}
		}
	}, [
		isValidActivationDate,
		isValidDeactivationDate,
		isValidUsername,
		isValidFirstName,
		isValidLastName,
		isValidPassword,
		isValidRePassword
	]);

	// used to just trigger useEffect logic on updates, but not on the initial render
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			if (editMode && user) {
				getUserIdentity();
			} else {
				cleanFields();
			}
		}
	}, [props.editMode, props.user]);

	// Checks if user has been modified
	useEffect(() => {
		if (editMode && user) {
			if (
				(firstName !== user.firstName ||
					lastName !== user.lastName ||
					username !== user.userName ||
					role !== user.role ||
					(activationDate?.format('yyyy-MM-DD') !== user.activationDate && isValidActivationDate) ||
					hasDeactivationDateChanged()) &&
				isValidFirstName &&
				isValidLastName &&
				isValidUsername &&
				isValidActivationDate &&
				isValidDeactivationDate
			) {
				setDisableAccept(false);
			} else {
				setDisableAccept(true);
			}
		}
	}, [username, firstName, lastName, role, activationDate, deactivationDate]);

	/**
	 * @name deactivationDate
	 * @description Check if deactivation date has changed on edit. This is needed because possible values are:
	 *
	 *  - deactivationDate => null || moment
	 *  - user.deactivationDate => '-' || moment.format('yyyy-MM-DD')
	 * @returns {boolean} returns if the date has been modified
	 */
	const hasDeactivationDateChanged = (): boolean => {
		if (deactivationDate?.isValid()) {
			return deactivationDate?.format('yyyy-MM-DD') !== user.deactivationDate;
		} else {
			if ((deactivationDate === null && user.deactivationDate === '-') || deactivationDate !== null) {
				return false;
			}
			return true;
		}
	};
	/**
	 * @name handleFirstNameOnChange
	 * @description Manage input value change in first name
	 * @param {React.ChangeEvent<HTMLInputElement>} event the event on the input
	 */
	const handleFirstNameOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setFirstName(event.target.value);
		validateFirstName(event.target.value);
	};
	/**
	 * @name validateFirstName
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {string} value The value to validate.
	 */
	const validateFirstName = (value: string): void => {
		if (value.length > 0) {
			setIsValidFirstName(true);
			setFirstNameError(false);
		} else {
			setIsValidFirstName(false);
			setFirstNameError(true);
		}
	};
	/**
	 * @name handleLastNameOnChange
	 * @description Manage input value change in last name
	 * @param {React.ChangeEvent<HTMLInputElement>} event the event on the input
	 */
	const handleLastNameOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setLastName(event.target.value);
		validateLastName(event.target.value);
	};
	/**
	 * @name validateLastName
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {string} value The value to validate.
	 */
	const validateLastName = (value: string): void => {
		if (value.length > 0) {
			setIsValidLastName(true);
			setLastNameError(false);
		} else {
			setIsValidLastName(false);
			setLastNameError(true);
		}
	};
	/**
	 * @name handleUsernameOnChange
	 * @description Manage input value change in username
	 * @param {React.ChangeEvent<HTMLInputElement>} event the event on the input
	 */
	const handleUsernameOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setUsername(event.target.value);
		validateUsername(event.target.value);
	};
	/**
	 * @name validateUsername
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {string} value The value to validate.
	 */
	const validateUsername = (value: string): void => {
		if (USERNAME.test(value)) {
			setIsValidUsername(true);
			setUsernameError(false);
			setHelperUsername('form.required');
		} else {
			setIsValidUsername(false);
			setUsernameError(true);
			setHelperUsername('form.username.invalid');
		}
	};
	/**
	 * @name handlePasswordOnChange
	 * @description Manage input value change in password
	 * @param {React.ChangeEvent<HTMLInputElement>} event the event on the input
	 */
	const handlePasswordOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setPassword(event.target.value);
		validatePassword(event.target.value);
	};
	/**
	 * @name validatePassword
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {string} value The value to validate.
	 */
	const validatePassword = (value: string): void => {
		if (rePassword.length === value.length && value === rePassword) {
			setIsValidPassword(true);
			setPasswordError(false);
			setRePasswordError(false);
			setIsValidRePassword(true);
			setHelperRePassword(t('form.required'));
		} else {
			setIsValidPassword(false);
			setRePasswordError(true);
			setHelperRePassword(`${t('form.rePassword.error.match')}`);
		}
	};
	//set today constant to check activation and desactivation date
	const today = new Date();

	/**
	 * @name handleRePasswordOnChange
	 * @description Manage input value change in repassword
	 * @param {React.ChangeEvent<HTMLInputElement>} event the event on the input
	 */
	const handleRePasswordOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setRePassword(event?.target.value);
		validateRePassword(event?.target.value);
	};
	/**
	 * @name validateRePassword
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {string} value The value to validate.
	 */
	const validateRePassword = (value: string): void => {
		if (value === password) {
			setIsValidRePassword(true);
			setRePasswordError(false);
			setIsValidPassword(true);
			setPasswordError(false);
			setHelperRePassword(t('form.required'));
		} else {
			setIsValidRePassword(false);
			setRePasswordError(true);
			setHelperRePassword(`${t('form.rePassword.error.match')}`);
		}
	};
	/**
	 * @name handleRoleOnChange
	 * @description Manage input value change in role
	 * @param {React.ChangeEvent<HTMLInputElement>} event the event on the input
	 */
	const handleRoleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setRole(event.target.value);
	};
	/**
	 * @name handleActivationDateChange
	 * @description Manage input value change in activation date
	 * @param {moment.Moment} date the selected date
	 */
	const handleActivationDateChange = (date: moment.Moment | null) => {
		setActivationDate(date);
		if (date) {
			validateActivationDate(date);
		}
	};
	/**
	 * @name validateActivationDate
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {moment.Moment} date The value to validate.
	 */
	const validateActivationDate = (date: moment.Moment): void => {
		deactivationDate === null
			? setIsValidActivationDate(date?.isValid() && date.diff(today, 'day') >= 0 && date?.isSameOrAfter(today, 'day'))
			: setIsValidActivationDate(
					date?.isValid() &&
						date.diff(today, 'day') >= 0 &&
						date?.isSameOrAfter(today, 'day') &&
						deactivationDate.diff(date, 'day') >= 1
			  );
	};
	/**
	 * @name handleDectivationDateChange
	 * @description Manage input value change in deactivation date
	 * @param {moment.Moment} date the selected date
	 */
	const handleDeactivationDateChange = (date: moment.Moment | null) => {
		setDeactivationDate(date);
		if (date) {
			validateDeactivationDate(date);
		}
	};
	/**
	 * @name validateDeactivationDate
	 * @description Validate the input and check if it's valid and if it has any errors
	 * @param {moment.Moment} date The value to validate.
	 */
	const validateDeactivationDate = (date: moment.Moment): void => {
		if (date?.isValid() && date?.isAfter(today, 'day') && date.diff(activationDate, 'day') >= 1) {
			setIsValidDeactivationDate(date?.isValid());
			setIsValidActivationDate(true);
		} else {
			setIsValidActivationDate(false);
			setIsValidDeactivationDate(false);
		}
	};
	/**
	 * @name handleCreateAnother
	 * @description Set if is needed to create another user
	 */
	const handleCreateAnother = () => {
		setCreateAnother(!createAnother);
	};
	/**
	 * @name handleSnackbarOpen
	 * @description Manage if the snackbar is open
	 * @param {boolean} error Tells if it's an error
	 * @param {string} message The message displayed
	 */
	const handleSnackbarOpen = (error: boolean, message?: string) => {
		const messageError: string = message ? message : '';
		setSnackbarOpen({ open: true, error: error, message: messageError });
	};
	/**
	 * @name handleClose
	 * @description Manage the dialog close
	 */
	const handleClose = () => {
		dispatch(setCloseDialog());
		cleanFields();
	};
	/**
	 * @name cleanFields
	 * @description Clean the form fields
	 */
	const cleanFields = () => {
		setActivationDate(moment(new Date()));
		setDeactivationDate(null);
		setFirstName('');
		setFirstNameError(false);
		setIsValidFirstName(false);
		setLastName('');
		setLastNameError(false);
		setIsValidLastName(false);
		setUsername('');
		setUsernameError(false);
		setHelperUsername('form.required');
		setIsValidUsername(false);
		setPassword('');
		setPasswordError(false);
		setIsValidPassword(false);
		setRePassword('');
		setRePasswordError(false);
		setHelperRePassword('form.required');
		setIsValidRePassword(false);
		setRole('tenant');
		setCreateAnother(false);
		setDisableAccept(true);
	};
	/**
	 * @name handleSnackbarClose
	 * @description Manage the snackbar close
	 */
	const handleSnackbarClose = () => {
		setSnackbarOpen({ open: false, error: false, message: '' });
	};
	/**
	 * @name handleSubmit
	 * @description Manage the submit in the create form
	 */
	const handleSubmit = () => {
		setDisableAccept(true);
		const body: CreateUserBody = {
			activation_date: activationDate !== null ? activationDate.format('yyyy-MM-DD') : null,
			deactivation_date: deactivationDate !== null ? deactivationDate.format('yyyy-MM-DD') : null,
			last_name: lastName,
			first_name: firstName,
			email: username,
			password: password,
			profile: role
		};

		createUser(body, token).then(
			() => {
				handleSnackbarOpen(false);
				if (!createAnother) {
					handleClose();
				} else {
					cleanFields();
				}
				dispatch(setRefreshUserList());
			},
			(error) => {
				handleSnackbarOpen(true, error.message);
				if (error.message.includes('Validation error [email]')) {
					setIsValidUsername(false);
					setUsernameError(true);
					setHelperUsername('form.username.registered');
				}
			}
		);
	};
	/**
	 * @name handleEditSubmit
	 * @description Manage the submit in the edit form
	 */
	const handleEditSubmit = () => {
		setDisableAccept(true);
		const body: EditUserBody = {
			user_id: user.userID
		};
		if (user.userName !== username) {
			body.email = username;
		}

		if (user.firstName !== firstName) {
			body.first_name = firstName;
		}

		if (user.lastName !== lastName) {
			body.last_name = lastName;
		}

		if (user.role !== role) {
			body.profile = role;
		}
		if (isValidActivationDate) {
			body.activation_date = activationDate !== null ? activationDate.format('yyyy-MM-DD') : null;
		}
		if (isValidActivationDate) {
			body.deactivation_date = deactivationDate !== null ? deactivationDate.format('yyyy-MM-DD') : null;
		}
		editUser(body, token).then(
			() => {
				if (userInfo.user.email === user.userName) {
					dispatch(setExpireSession(true, 0, 'editUser'));
					dispatch(setLogout());
				} else {
					handleSnackbarOpen(false);
					handleClose();
					dispatch(setRefreshUserList());
				}
			},
			(error) => {
				handleSnackbarOpen(true, error.message);
				if (error.message.includes('Validation error [email]')) {
					setIsValidUsername(false);
					setUsernameError(true);
					setHelperUsername('form.username.registered');
				}
			}
		);
	};
	/**
	 * @name getUserIdentity
	 * @description Get the user information and set it in the component
	 */
	const getUserIdentity = () => {
		getIdentity(user.userID, token).then(
			(response) => {
				// firstName and lastName need to be stored to check if there are any changes on edit
				user.firstName = response.identity.first_name;
				setFirstName(user.firstName);
				user.lastName = response.identity.name;
				setLastName(user.lastName);
				setUsername(user.userName);
				setRole(user.role);
				setActivationDate(moment(user.activationDate, 'YYYYY-MM-DD'));
				if (user.deactivationDate !== '-') {
					setDeactivationDate(moment(user.deactivationDate, 'YYYYY-MM-DD'));
				} else {
					setDeactivationDate(null);
				}
				dispatch(setOpenDialog());
				setIsValidFirstName(true);
				setIsValidLastName(true);
				setIsValidUsername(true);
				setDisableAccept(true);
			},
			(error: Error) => {
				console.error('Error getting user: ', error);
			}
		);
	};

	return (
		<>
			{snackbarOpen.open && (
				<Snackbar
					open={snackbarOpen.open}
					autoHideDuration={6000}
					onClose={handleSnackbarClose}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					classes={{ root: 'c-snackbar' }}
				>
					{snackbarOpen.error ? (
						<Alert onClose={handleSnackbarClose} severity="error" variant="filled" message={t(snackbarOpen.message)} />
					) : (
						<Alert
							onClose={handleSnackbarClose}
							severity="success"
							variant="filled"
							message={editMode ? t('form.response.accept.edit') : t('form.response.accept.create')}
						/>
					)}
				</Snackbar>
			)}
			{_isProfileFormDialogOpen && (
				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={MAXWIDTH}>
					<div className="c-profilecreator__dialog">
						<DialogTitle className={' c-profilecreator__title'} id="form-dialog-title" disableTypography>
							{editMode ? t('form.title.edit') : t('form.title.create')}
						</DialogTitle>
						<Divider className={'c-profilecreator__separator'} />
						<DialogContent>
							{editMode ? (
								<FormEditProfile
									activationDate={activationDate}
									handleActivationDateChange={handleActivationDateChange}
									deactivationDate={deactivationDate}
									handleDeactivationDateChange={handleDeactivationDateChange}
									firstName={firstName}
									firstNameError={firstNameError}
									handleFirstNameOnChange={handleFirstNameOnChange}
									lastName={lastName}
									lastNameError={lastNameError}
									handleLastNameOnChange={handleLastNameOnChange}
									username={username}
									usernameError={usernameError}
									helperUsername={helperUsername}
									handleUsernameOnChange={handleUsernameOnChange}
									role={role}
									handleRoleOnChange={handleRoleOnChange}
								/>
							) : (
								<FormCreateProfile
									activationDate={activationDate}
									handleActivationDateChange={handleActivationDateChange}
									deactivationDate={deactivationDate}
									handleDeactivationDateChange={handleDeactivationDateChange}
									firstName={firstName}
									firstNameError={firstNameError}
									handleFirstNameOnChange={handleFirstNameOnChange}
									lastName={lastName}
									lastNameError={lastNameError}
									handleLastNameOnChange={handleLastNameOnChange}
									username={username}
									usernameError={usernameError}
									helperUsername={helperUsername}
									handleUsernameOnChange={handleUsernameOnChange}
									password={password}
									passwordError={passwordError}
									handlePasswordOnChange={handlePasswordOnChange}
									rePassword={rePassword}
									rePasswordError={rePasswordError}
									helperRePassword={helperRePassword}
									handleRePasswordOnChange={handleRePasswordOnChange}
									role={role}
									handleRoleOnChange={handleRoleOnChange}
								/>
							)}
						</DialogContent>
						<Divider className={'c-profilecreator__separator'} />
						<DialogActions>
							{editMode ? (
								<FormActionEditProfile
									handleClose={handleClose}
									handleSubmit={handleEditSubmit}
									disableAccept={disableAccept}
								/>
							) : (
								<FormActionCreateProfile
									createAnother={createAnother}
									handleCreateAnother={handleCreateAnother}
									handleClose={handleClose}
									handleSubmit={handleSubmit}
									disableAccept={disableAccept}
								/>
							)}
						</DialogActions>
					</div>
				</Dialog>
			)}
		</>
	);
};
export default ProfileCreator;
