import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from '../../routes/routes';
import Grid from '@material-ui/core/Grid';
import { AareonLayout } from 'app/core/interfaces/AareonLayout';

const BaseLayout: React.FC<AareonLayout> = (props) => {
	const {
		route: { routes }
	} = props;

	return (
		<Grid container>
			<Grid item xs={12}>
				<Switch>
					{routes &&
						routes.length &&
						routes.map((route, i) => {
							return <RouteWithSubRoutes key={i} {...route} parentRoutes={routes} />;
						})}
				</Switch>
			</Grid>
		</Grid>
	);
};

export default BaseLayout;
