import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { debounce } from 'ts-debounce';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import CodeIcon from '@material-ui/icons/Code';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import withWidth from '@material-ui/core/withWidth';

import { searchDataSelector } from './searchbar.selectors';
import { getSearchData } from './searchbar.actions';
import { GUIDES, APIREFERENCE } from './searchbar.consts';
import { SearchProps, SearchResultsProps, searchResult } from './model/SearchBar';
import { SearchData } from './model/SearchDataState';
import useOnClickOutside from '../../hooks/useOnClickOutside';

export const SearchResult: React.FC<SearchResultsProps> = (props) => {
	const { searchResults, isVisible, isMobile } = props;
	const { t } = useTranslation('searchResults');
	const [searchToDisplay, setSearchToDisplay] = React.useState<searchResult[]>([]);
	const [componentVisible, setComponentVisible] = React.useState<boolean>(isVisible);
	let history = useHistory();
	/**
	 * @function
	 * @name handleListClick
	 * @param {string} url The URL to navigate
	 * @description Function that navigates to the chosen address of the results
	 */
	const handleListClick = (url: string) => {
		history.push(url);
	};

	React.useEffect(() => {
		let transformedSearch: searchResult[] = [];
		searchResults.forEach((searchItem) => {
			const transformedCategory = transformedSearch.filter((transformedItem) => {
				return transformedItem.type === searchItem.type;
			});
			if (transformedCategory.length > 0) {
				transformedCategory[0].data.push(searchItem);
			} else {
				transformedSearch.push({ type: searchItem.type, data: [searchItem] });
			}
		});
		setSearchToDisplay(transformedSearch);
	}, [searchResults]);

	React.useEffect(() => {
		setComponentVisible(isVisible);
	}, [isVisible]);

	return (
		<Paper
			className={`c-searchresult ${isMobile && 'c-searchresult__mobile'}`}
			style={{ display: componentVisible ? 'block' : 'none' }}
		>
			{searchToDisplay.map((result, index) => {
				return (
					<React.Fragment key={`search-results-${index}`}>
						<Chip
							className="c-searchresult__chip-main"
							variant="outlined"
							avatar={
								result.type === GUIDES ? (
									<DescriptionIcon className="c-searchresult__chip-icon" />
								) : result.type === APIREFERENCE ? (
									<CodeIcon className="c-searchresult__chip-icon" />
								) : (
									undefined
								)
							}
							label={result.type.toLocaleUpperCase()}
							onDelete={() => null}
							deleteIcon={<span className="c-searchresult__chip-number">({result.data.length})</span>}
						/>
						<List dense={true}>
							{result.data.map((searchItem, index: number) => {
								const scrollKey = searchItem.path.split('/').pop();
								return (
									<Link
										to={scrollKey + '_container'}
										spy
										smooth
										offset={-140}
										key={`search-link-${index}`}
										className={'c-searchresult__link'}
									>
										<ListItem
											key={`search-results-list-${index}`}
											id={`search-results-list-${index}`}
											button
											onClick={() => handleListClick(searchItem.path)}
										>
											<ListItemIcon className="c-searchresult__list-icon">
												{searchItem.type === GUIDES ? <DescriptionIcon /> : <CodeIcon />}
											</ListItemIcon>
											<ListItemText primary={searchItem.title} />
										</ListItem>
									</Link>
								);
							})}
						</List>
					</React.Fragment>
				);
			})}
			{searchResults.length <= 0 && <p id="not_found">{t('notFound')}</p>}
		</Paper>
	);
};

const SearchBar: React.FC<SearchProps> = (props) => {
	const { width } = props;
	const breakPoints: string[] = ['xs', 'sm', 'md'];
	const mobile = breakPoints.indexOf(width) > -1;
	const [showResults, setShowResults] = React.useState<boolean>(false);
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [searchResults, setSearchResults] = React.useState<SearchData[]>([]);
	const dispatch = useDispatch();
	const searchDataContent = useSelector(searchDataSelector);
	const { ref, isComponentVisible, setIsComponentVisible } = useOnClickOutside(false);

	/**
	 * @function
	 * @name closeAndClearSearch
	 * @description Function that close search input and search results on mobile devices
	 */
	const closeAndClearSearch = () => {
		setShowResults(false);
		setSearchTerm('');
	};

	/**
	 * @function
	 * @name debouncedBlur
	 * @description Use the HOF debounce in the blur event
	 * @returns {Function} The debounced function
	 */
	const debouncedBlur: Function = () => debounce(handleSearchBlur, 150, { isImmediate: false });

	/**
	 * @function
	 * @name manageShowSearchBar
	 * @description Function that manages the visibility of the search input
	 */
	const manageShowSearchBar = () => {
		setIsComponentVisible(!isComponentVisible);
		setShowResults(false);
	};

	/**
	 * @function
	 * @name handleClearSearch
	 * @description Function that clean the search terms
	 */
	const handleClearSearch = () => {
		setSearchTerm('');
	};

	/**
	 * @function
	 * @name handleSearchBlur
	 * @description Function that handles the onBlur event in the results pane, hiding it
	 */
	const handleSearchBlur = () => {
		setShowResults(false);
	};

	/**
	 * @function
	 * @name handleSearchFocus
	 * @description Function that handles when you go to search for terms
	 */
	const handleSearchFocus = () => {
		if (searchTerm.length >= 3) {
			setShowResults(true);
		}
	};

	/**
	 * @function
	 * @name pickSearchData
	 * @param {string} terms The terms to search
	 * @description Function to manage the search
	 */
	const pickSearchData = (terms: string) => {
		const results = searchDataContent.filter((data) => {
			let termsMuted = '';
			terms.split(' ').forEach((term) => {
				termsMuted += `(?=.*${term})`;
			});
			const regTerm = new RegExp(`^${termsMuted}.*$`, 'igm');
			return data.keywords.match(regTerm) || data.titleWords.match(regTerm);
		});
		setSearchResults(results);
	};

	/**
	 * @function
	 * @name manageInputSearchValue
	 * @param {React.ChangeEvent<HTMLInputElement>} event The event change of the input search
	 * @description Function to manage the input search
	 */
	const manageInputSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setSearchTerm(event.currentTarget.value);
		if (event.target.value.length >= 3) {
			pickSearchData(event.target.value);
		}
	};

	React.useEffect(() => {
		if (searchTerm.length >= 3) {
			setShowResults(true);
		} else {
			setShowResults(false);
		}
	}, [searchTerm]);

	React.useEffect(() => {
		isComponentVisible || closeAndClearSearch();
	}, [isComponentVisible]);

	React.useEffect(() => {
		if (searchDataContent.length === 0) {
			dispatch(getSearchData());
		}
	}, []);

	return (
		<React.Fragment>
			<div ref={ref}>
				{mobile && (
					<IconButton className="c-searchbar__iconButton-mobile" aria-label="search" onClick={manageShowSearchBar}>
						<SearchIcon />
					</IconButton>
				)}
				{((isComponentVisible && mobile) || !mobile) && (
					<Paper className={`c-searchbar ${mobile && 'c-searchbar__mobile'}`}>
						<InputBase
							autoComplete="off"
							autoFocus={mobile}
							className={'c-searchbar__input'}
							name="searchInputBar"
							id="searchInputBar"
							placeholder="Search"
							inputProps={{ 'aria-label': 'search' }}
							onChange={manageInputSearchValue}
							onBlur={debouncedBlur()}
							onFocus={handleSearchFocus}
							value={searchTerm}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="search"
										onClick={handleClearSearch}
										className={'c-searchbar__iconButton-clear'}
									>
										{searchTerm.length === 0 ? <SearchIcon /> : <ClearIcon />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</Paper>
				)}
			</div>
			<SearchResult searchResults={searchResults} isVisible={showResults} isMobile={mobile} />
		</React.Fragment>
	);
};

export default withWidth()(SearchBar);
