import { LOWER, UPPER, NUMBER, SPECIALCHAR, SPECIALNUMBERCHAR, WHITESPACE } from './PasswordPolicy.const';
import { PasswordValidated, Policies } from './model/PasswordPolicy';

/**
 * @name isLengthValid
 * @param {string} password
 * @param {number} min
 * @description Check if password meets the length requeriments.
 *
 * @returns {boolean} password meets the length requeriments.
 */
const isLengthValid = (password: string, min: number): boolean => {
	return password.length >= min;
};

/**
 * @name isLowerValid
 * @param {string} password
 * @param {number} min
 * @description Check if password meets the lowercase requeriments.
 *
 * @returns {boolean} password meets the lowercase requeriments.
 */
const isLowerValid = (password: string, min: number): boolean => {
	const lowerPattern = new RegExp(`${LOWER}{${min},}`);
	return password.match(lowerPattern) !== null;
};

/**
 * @name isUpperValid
 * @param {string} password
 * @param {number} min
 * @description Check if password meets the uppercase requeriments.
 *
 * @returns {boolean} password meets the uppercase requeriments.
 */
const isUpperValid = (password: string, min: number): boolean => {
	const upperPattern = new RegExp(`${UPPER}{${min},}`);
	return password.match(upperPattern) !== null;
};

/**
 * @name isNumberValid
 * @param {string} password
 * @param {number} min
 * @description Check if password meets the number requeriments.
 *
 * @returns {boolean} password meets the number requeriments.
 */
const isNumberValid = (password: string, min: number): boolean => {
	const numberPattern = new RegExp(`${NUMBER}{${min},}`);
	return password.match(numberPattern) !== null;
};

/**
 * @name isSpecialValid
 * @param {string} password
 * @param {number} min
 * @description Check if password meets the special chars requeriments.
 *
 * @returns {boolean} password meets the special chars requeriments.
 */
const isSpecialValid = (password: string, min: number): boolean => {
	const symbolPattern = new RegExp(`${SPECIALCHAR}{${min},}`);
	return password.match(symbolPattern) !== null && password.match(WHITESPACE) === null;
};

/**
 * @name isSpecialNumberValid
 * @param {string} password
 * @param {number} min
 * @description Check if password meets the special chars requeriments.
 *
 * @returns {boolean} password meets the special chars requeriments.
 */
const isSpecialNumberValid = (password: string, min: number): boolean => {
	const symbolNumberPattern = new RegExp(`${SPECIALNUMBERCHAR}{${min},}`);
	return password.match(symbolNumberPattern) !== null && password.match(WHITESPACE) === null;
};

/**
 * @name isCompareCharsValid
 * @param {string} password
 * @param {number} numChars
 * @param {string} compare
 * @description Check if password meets the personal data requeriments.
 *
 * @returns {boolean} password meets the personal data requeriments.
 */
const isCompareCharsValid = (password: string, numChars: number, compare: string): boolean => {
	const posMatches = possibleMatches(compare, numChars);
	const matchesArray: string[] = [];
	if (posMatches === null) {
		return true;
	}

	posMatches.map((possibility: string) => {
		matchesArray.push(`(.*${possibility})`);
	});

	const pattern = new RegExp('(' + matchesArray.join('|') + ')', 'gi');
	const match: RegExpMatchArray | null = password.match(pattern);

	return match === null;
};

/**
 * @name possibleMatches
 * @param {string} compare
 * @param {number} numChars
 * @description Return all possibles matches.
 *
 * @returns {string[]} with the example: ['Any', 'ny\.', 'y\.N', '\.Na', 'Nam', 'ame']
 */
const possibleMatches = (compare: string, numChars: number): string[] | null => {
	if (compare.length < numChars) {
		return null;
	}

	const possibleMatches: string[] = [];
	let start: number = 0;
	while (compare.length >= start + numChars) {
		possibleMatches.push(compare.substring(start, start + numChars));
		start++;
	}
	return possibleMatches;
};

/**
 * @name validatePassword
 * @param {string} password
 * @param {Policies} validations
 * @param {string} compare
 * @description Call the different requeriments functions to the password and return
 * 				an Array with the result.
 *
 * @typedef {PasswordValidated} validatePassword Requeriments result.
 * @property {boolean} validatePassword.length Password meets the length requeriments
 * @property {boolean} validatePassword.lowercase Password meets the lowercase requeriments
 * @property {boolean} validatePassword.uppercase Password meets the uppercase requeriments
 * @property {boolean} validatePassword.number Password meets the number requeriments
 * @property {boolean} validatePassword.specialchar Password meets the special chars requeriments
 * @property {boolean} validatePassword.specialnumberchar Password meets the  special chars and numbers requeriments
 * @property {boolean} validatePassword.comparechars Password meets the personal data requeriments
 *
 * @returns {PasswordValidated} validatePassword Requeriments result.
 */
export const validatePassword = (password: string, validations: Policies, compare: string): PasswordValidated => {
	let passwordValidated: PasswordValidated = {
		length: false,
		lowercase: false,
		uppercase: false,
		number: false,
		specialchar: false,
		specialnumberchar: false,
		comparechars: false
	};

	if (validations.min_length > 0) {
		passwordValidated.length = isLengthValid(password, validations.min_length);
	}
	if (validations.min_lower_case_characters > 0) {
		passwordValidated.lowercase = isLowerValid(password, validations.min_lower_case_characters);
	}
	if (validations.min_upper_case_characters > 0) {
		passwordValidated.uppercase = isUpperValid(password, validations.min_upper_case_characters);
	}
	if (validations.min_digits > 0) {
		passwordValidated.number = isNumberValid(password, validations.min_digits);
	}
	if (validations.min_special_digits > 0) {
		passwordValidated.specialchar = isSpecialValid(password, validations.min_special_digits);
	}
	if (validations.min_min_special_or_digits > 0) {
		passwordValidated.specialnumberchar = isSpecialNumberValid(password, validations.min_min_special_or_digits);
	}
	if (validations.compare_characters > 0) {
		passwordValidated.comparechars = isCompareCharsValid(password, validations.compare_characters, compare);
	}

	return passwordValidated;
};

/**
 * @name validateRepeatPassword
 * @param {string} password
 * @param {string} password2
 * @description Compare strings and return if they are equals.
 *
 * @returns {boolean} validatePassword Requeriments result.
 */
export const validateRepeatPassword = (password: string, password2: string): boolean => {
	return password === password2;
};
