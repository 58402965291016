import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

import { ActionsToolbarProps, BulkActionProps } from '../model/ActionTable';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ConfirmDialog from '../../ConfirmDialog';

const CustomToolbarSelect = (props: ActionsToolbarProps) => {
	const { bulkActions, selectedRows, displayData } = props;
	const { t } = useTranslation(['userList', 'actionTable']);
	const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
	const [confirmTitle, setConfirmTitle] = React.useState('');
	const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(() => () => {});

	const onClickHandler = (callback: ([]) => void | any) => {
		const data: any[] = [];
		if (selectedRows.data) {
			selectedRows.data.map((value: any, index: number) => {
				data[index] = displayData[value.index].data;
			});
		}
		setConfirmDialogCallback(() => () => callback(data));
	};

	return (
		<React.Fragment>
			<Select
				value="bulk"
				classes={{ root: 'c-action-table__bulk', icon: 'c-action-table__icon' }}
				input={<Input className={'c-action-table__bulk'} />}
			>
				<MenuItem key="bulk" value="bulk">
					{t('actionTable:bulkActions')}
				</MenuItem>
				{bulkActions?.map((action: BulkActionProps) => {
					return (
						<MenuItem
							value={action.name}
							key={action.name}
							onClick={() => {
								setConfirmDialogOpen(true);
								onClickHandler(action.callback);
								setConfirmTitle(t(`userList:${action.name}`));
							}}
						>
							{t(`userList:${action.name}`)}
						</MenuItem>
					);
				})}
			</Select>
			<ConfirmDialog
				callback={confirmDialogCallback}
				description={t('actionTable:actionsToolbar.confirmDescription')}
				isOpen={confirmDialogOpen}
				title={confirmTitle}
				onClose={() => setConfirmDialogOpen(false)}
			/>
		</React.Fragment>
	);
};

export default CustomToolbarSelect;
