import { SWITCHMODE_TOGGLE } from './switchmode.const';
import { SwitchmodeState } from './model/SwitchmodeState';
import { SwitchmodeActionTypes } from './switchmode.types';
import { SwitchStatus } from './model/ESwitchStatus';

const initialState: SwitchmodeState = {
	status: SwitchStatus.on
};

const switchmodeReducer = (state = initialState, action: SwitchmodeActionTypes): SwitchmodeState => {
	switch (action.type) {
		case SWITCHMODE_TOGGLE:
			return {
				...state,
				status: state.status === SwitchStatus.on ? SwitchStatus.off : SwitchStatus.on
			};
		default:
			return state;
	}
};

export default switchmodeReducer;
