const axios = require('axios');

/**
 * @function
 * @name getFile
 * @param {string} url
 * @returns {string} plain text string with markdown file
 * @description Get files
 */
export const getFile = async (url: string) => {
	try {
		const file = await axios.get(url);
		return file.data;
	} catch (err) {
		console.error(err);
	}
};
