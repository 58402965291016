import { MARKDOWN_SCROLLING, SET_LOADING } from './markdown.const';
import { MarkdownActionTypes, SetLoading } from './markdown.types';
import { MarkdownState } from './model/MarkdownState';

const initialState: MarkdownState = {
	scrolling: false,
	isLoading: false
};

const sideBarReducer = (state = initialState, action: MarkdownActionTypes | SetLoading): MarkdownState => {
	switch (action.type) {
		case MARKDOWN_SCROLLING:
			return {
				...state,
				scrolling: action.payload
			};
		case SET_LOADING:
			return {
				...state,
				isLoading: action.payload
			};
		default:
			return state;
	}
};

export default sideBarReducer;
