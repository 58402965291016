/**
 * LOGIN: ACTION TYPES CONSTS
 */
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_ERROR = 'GET_LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_REMEMBER_ME_TOGGLE = 'SET_REMEMBER_ME_TOGGLE';
export const SET_USER_NAME_TO_REMEMBER = 'SET_USER_NAME_TO_REMEMBER';
export const SET_EXPIRED_SESSION = 'SET_EXPIRED_SESSION';
export const SET_NEW_AUTH = 'SET_NEW_AUTH';
export const CLEAN_ERRORS = 'CLEAN_ERRORS';

export const ROLE_TYPES: any = {
	ROLE_ADMIN: 'admin',
	ROLE_TENANT: 'user'
};
