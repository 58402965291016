import React from 'react';
import { IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CircularProgress, Divider } from '@material-ui/core';
import axios from 'axios';
import { config, handleApiError } from '../../../services';
import CodeMirrorUI from '../../CodeMirror';
import { TOKEN_PREFIX } from './../../../services/services.consts';
import { useSelector } from 'react-redux';
import { TotalState } from '../../../store/model/TotalState';
import { getUserToken } from '../../Login/login.selectors';
import { ConsoleProps } from '../model/ConsoleProps';

const Console: React.FC<ConsoleProps> = (props) => {
	const { data, path, selectedMethod, servers = [], toggleConsole } = props;

	const [tabValue, setTabValue] = React.useState<string>('params');

	const [serverValue, setServerValue] = React.useState<string>('');

	const [responseValue, setResponseValue] = React.useState<any>();

	const [isLoadingCall, setLoadingCall] = React.useState<boolean>(false);

	React.useEffect(() => {
		setTabValue('params');
		setResponseValue({});
	}, [selectedMethod]);

	React.useEffect(() => {
		if (servers.length > 0) {
			servers.forEach((server: any) => {
				if (server?.url.match(config.serviceUrlDomain) && serverValue === '') {
					setServerValue(server.url || '');
				}
			});
		}
	}, [servers]);

	const _token = useSelector((state: TotalState) => getUserToken(state));

	let bodyCode = {};

	const changeTabHandler = (_event: React.ChangeEvent<{}>, newValue: string) => {
		setTabValue(newValue);
	};

	const plainRefSchema = (schema: any, flatest?: boolean) => {
		let plainSchema: any = {};
		const hasNoRef = schema && !schema.hasOwnProperty('$ref');
		const hasNoRefAndNoLength = hasNoRef && Object.keys(schema).length <= 0;
		const nodeBlacklist = ['allOf', 'application/json', 'schema'];

		if (hasNoRefAndNoLength || typeof schema !== 'object' || Array.isArray(schema)) {
			let schemaAux: Object[] = [];
			if (Array.isArray(schema) && schema.length > 0) {
				schema.forEach((child) => {
					if (typeof child === 'object') {
						schemaAux.push(plainRefSchema(child, flatest));
					}
				});
			}
			return schemaAux.length > 0 ? schemaAux.reduce((r, c) => Object.assign(r, c), {}) : schema;
		}

		if (hasNoRef && Object.keys(schema).length > 0) {
			if (schema.hasOwnProperty('properties')) {
				Object.keys(schema['properties']).forEach((child) => {
					plainSchema[child] = plainRefSchema(schema['properties'][child], flatest);
				});
			} else {
				let auxFlag;
				Object.keys(schema).forEach((child) => {
					if (nodeBlacklist.includes(child)) {
						plainSchema = plainRefSchema(schema[child], flatest);
					} else {
						if (flatest && child === 'example') {
							auxFlag = schema[child];
						} else {
							plainSchema[child] = plainRefSchema(schema[child], flatest);
						}
					}
				});
				if (auxFlag) {
					plainSchema = auxFlag;
				}
			}
		}

		if (schema.hasOwnProperty('$ref')) {
			plainSchema = plainRefSchema(findRefObject(getRefName(schema.$ref)), flatest);
		}
		return plainSchema;
	};

	const getRefName = (reference: string): string => {
		return reference.split('/').pop() || '';
	};

	const findRefObject = (refKey: string): any => {
		return data?.components?.schemas[refKey];
	};

	const getErrorDescription = (errorCode: string): string => {
		const errors: any = {
			'400': 'Bad request, invalid data provided.',
			'401': 'Unauthenticated, authentication key is missing.',
			'403': 'Access forbidden, user has no right to access the data.',
			'404': 'Not found',
			'500': 'Internal server error.',
			'503': 'Service temporarily unavailable'
		};
		return errors[errorCode];
	};

	const getPathWithoutParam = (path: string): string => {
		return path.split('{')[0] || path;
	};

	const executeCall = async (selectedMethod: any) => {
		try {
			setLoadingCall(true);
			const headers = {
				'tenant-id': config.tenantId,
				fingerprint: config.fingerprint,
				Authorization: TOKEN_PREFIX + _token
			};

			const methodType: string = Object.keys(selectedMethod)[0];

			const instance = axios.create({
				baseURL: serverValue,
				timeout: config.timeout,
				headers: headers
			});

			let jsonData = {};

			let res;

			switch (methodType) {
				case 'delete':
					res = await instance.delete(path);
					break;
				case 'get':
					res = await instance.get(`${getPathWithoutParam(path)}`);
					break;
				case 'head':
					res = await instance.head(path);
					break;
				case 'patch':
					jsonData = plainRefSchema(selectedMethod[methodType]?.requestBody?.content['application/json'], true);
					res = await instance.patch(path, jsonData);
					break;
				case 'post':
					jsonData = plainRefSchema(selectedMethod[methodType]?.requestBody?.content['application/json'], true);
					res = await instance.post(path, jsonData);
					break;
				case 'put':
					jsonData = plainRefSchema(selectedMethod[methodType]?.requestBody?.content['application/json'], true);
					res = await instance.put(path, jsonData);
					break;
				default:
					console.error(`Method ${methodType} not allowed`);
					break;
			}
			setResponseValue(res);
		} catch (error) {
			handleApiError(error, 'Error');
			setResponseValue(error.message);
		}
		setLoadingCall(false);
	};

	return (
		<div>
			<div className="c-swagger__icon-container">
				<IconButton
					id={'close-button'}
					data-unit-test-id="close-button"
					aria-label="close"
					className={'c-swagger__close-button'}
					size="small"
					onClick={() => toggleConsole('')}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<h5>{serverValue}</h5>
			<div className="c-swagger__url-container">
				<span className={`c-swagger__method-type c-swagger__method-type--${Object.keys(selectedMethod)[0]}`}>
					{Object.keys(selectedMethod)[0]}
				</span>
				<span className="c-swagger__path">{path}</span>
			</div>
			<div className="c-swagger__tab-container">
				<Tabs
					value={tabValue}
					classes={{
						indicator: 'c-swagger__tab-indicator'
					}}
					onChange={changeTabHandler}
					scrollButtons="auto"
					aria-label="disabled tabs example"
				>
					<Tab value="params" label="Params/Headers" />
					<Tab disabled={Object.keys(selectedMethod)[0] === 'get'} value="body" label="Body" />
					<Tab value="response" label="Responses" />
				</Tabs>
			</div>
			{tabValue === 'params' && (
				<div>
					{Object.keys(selectedMethod).map((value: string) => {
						if (selectedMethod[value]?.parameters) {
							return selectedMethod[value]?.parameters?.map((params: any) => {
								return (
									<Card key={params.name} className="c-swagger__params-container">
										<CardContent className="c-swagger__params-content">
											<span className="c-swagger__params-name">{params.name}</span>
											<span className="c-swagger__params-in">{params.in}</span>
											<span className="c-swagger__params-description">{`${params.description}`}</span>
											<span className="c-swagger__params-type">{params.schema.type}</span>
										</CardContent>
									</Card>
								);
							});
						} else {
							return (
								<Card key={'no-params'} className="c-swagger__params-container">
									<CardContent className="c-swagger__params-content">
										<span className="c-swagger__params">No params for this request</span>
									</CardContent>
								</Card>
							);
						}
					})}
				</div>
			)}
			{tabValue === 'body' && (
				<div>
					{Object.keys(selectedMethod).map((value) => {
						if (selectedMethod[value]?.requestBody) {
							const schemaReference = selectedMethod[value]?.requestBody?.content['application/json'];
							bodyCode = plainRefSchema(schemaReference, true);
						}
					})}
					<CodeMirrorUI code={JSON.stringify(bodyCode, null, 2)} type={'javascript'} isReadOnly={true}></CodeMirrorUI>
				</div>
			)}
			{tabValue === 'response' && (
				<div>
					{Object.keys(selectedMethod).map((value) => {
						let schemaResponse = {};
						return Object.keys(selectedMethod[value]?.responses).map((response: any) => {
							if (selectedMethod[value].responses[response].content) {
								schemaResponse = plainRefSchema(selectedMethod[value].responses[response], true);
							}
							return (
								<Card className="c-swagger__static-response" key={response}>
									<CardContent>
										<h4 className="c-swagger__response-title">
											{`${response} ${selectedMethod[value].responses[response].description}`}
										</h4>
										{selectedMethod[value]?.responses[response].content && (
											<CodeMirrorUI
												className="c-swagger__codemirror"
												code={JSON.stringify(schemaResponse, null, 2)}
												type={'javascript'}
												isReadOnly={true}
											></CodeMirrorUI>
										)}
									</CardContent>
								</Card>
							);
						});
					})}
				</div>
			)}
			<Button
				onClick={() => {
					executeCall(selectedMethod);
				}}
				variant="contained"
				color="primary"
				className="c-button c-swagger__button"
			>
				Execute
			</Button>
			<div className="c-swagger__response-container">
				{isLoadingCall === true && <CircularProgress />}
				{responseValue?.status && isLoadingCall === false && (
					<div className="c-swagger__server-response-container">
						<Divider variant="middle" />
						<Card className="c-swagger__response-card" variant="outlined">
							<CardContent>
								<h4 className="c-swagger__response-title">
									{responseValue.status}{' '}
									{selectedMethod[Object.keys(selectedMethod)[0]]?.responses[responseValue?.status]?.description
										? selectedMethod[Object.keys(selectedMethod)[0]]?.responses[responseValue?.status]?.description
										: getErrorDescription(responseValue.status)}
								</h4>
								<CodeMirrorUI
									code={JSON.stringify(responseValue.data, null, 2)}
									type={'javascript'}
									isReadOnly={true}
									hasToolbar={false}
								></CodeMirrorUI>
							</CardContent>
						</Card>
					</div>
				)}
			</div>
		</div>
	);
};
export default Console;
