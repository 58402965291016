import React from 'react';
import { ResetPasswordProps } from './model/ResetPassword';
import ChangePassword from '../ChangePassword';
import { withWidth } from '@material-ui/core';

const ForgotPassword: React.FC<ResetPasswordProps> = () => {
	return (
		<>
			<ChangePassword isUserLoggedIn={false} lightLabel verticalAligned></ChangePassword>
		</>
	);
};
export default withWidth()(ForgotPassword);
