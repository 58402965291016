import axios from 'axios';
import { GET_SEARCHDATA_ENDPOINT } from './searchbar.consts';
import { SearchData } from './model/SearchDataState';
import { handleApiError } from '../../services';

/**
 * @function
 * @name getSearchData
 * @description Retrieve an array with all the search data objects
 * @returns {Promise<SearchData[]>} A promise with an array of search data objects
 */
export const getSearchData = async (): Promise<SearchData[]> => {
	try {
		const { data } = await axios.get(GET_SEARCHDATA_ENDPOINT);
		return data;
	} catch (error) {
		return handleApiError(error, 'Error getting search data.');
	}
};
