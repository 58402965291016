import React, { useEffect } from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import Rocketphoto from '../../themes/primary/img/overview/rocket.png';
import OverviewCard from '../OverviewCard';
import { useTranslation } from 'react-i18next';

const Overview: React.FC = () => {
	const { t } = useTranslation('overview');
	return (
		<React.Fragment>
			<div className={'c-overview'}>
				<Typography className={'c-overview__title'} color="textSecondary" gutterBottom>
					{t('sections.quickStart.title')}
				</Typography>

				<Grid container spacing={5}>
					<Grid item xs={12} md={8} className={'c-overview__paragraph'}>
						<p>
							<strong>{t('sections.quickStart.greeting')}</strong>
							{t('sections.quickStart.subtitle')}
							<strong>{t('sections.quickStart.tool')}</strong>
						</p>
						<p>{t('sections.quickStart.description')}</p>
					</Grid>
					<Grid item md={4} className={'c-overview__rocketImage'}>
						<Hidden only={['xs', 'sm']}>
							<img alt="A decorative rocket image" src={Rocketphoto} />
						</Hidden>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12} sm={4}>
						<OverviewCard
							cardTitle={t('cards.getStarted.title')}
							cardText={t('cards.getStarted.description')}
							cardIcon={'plane'}
							urlPath={'/guides/get-started'}
							showLink={true}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<OverviewCard
							cardTitle={t('cards.guides.title')}
							cardText={t('cards.guides.description')}
							cardIcon={'doc'}
							urlPath={'/guides'}
							showLink={true}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<OverviewCard
							cardTitle={t('cards.apiReference.title')}
							cardText={t('cards.apiReference.description')}
							cardIcon={'bulb'}
							urlPath={'/api-reference'}
							showLink={true}
						/>
					</Grid>
				</Grid>

				<Typography className={'c-overview__title'} color="textSecondary" gutterBottom>
					{t('sections.monitoring.title')}
				</Typography>

				<Grid container>
					<Grid item xs={12} sm={4}>
						<OverviewCard
							cardTitle={t('cards.dashboard.title')}
							cardText={t('cards.dashboard.description')}
							cardIcon={'tools'}
							urlPath={'/dashboard'}
							showLink={true}
						/>
					</Grid>
				</Grid>
			</div>
		</React.Fragment>
	);
};

export default Overview;

// * UNCOMENT TO SHOW CHECK AND BUTTON OF HIDE AND REMEMBER:
/*
<Grid container spacing={1} className={'c-overview__remember'}>
	<Grid item xs={12}>
		<FormControlLabel 
			control={<Checkbox value="checkedA" color="primary" />} 
			label="Don´t show me this widget again" 
		/>
		<Button variant="outlined" className={classes.button}>Close</Button>
	</Grid>
</Grid>
*/
