import React from 'react';
import Main from './section/Main';
import Aside from './section/Aside';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import Slide from '@material-ui/core/Slide';
import { AareonLayout } from '../../core/interfaces/AareonLayout';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import useWindowSize from '../../utils/CustomHooks/UseWindowSize';
import { mountSidebar } from '../../components/ResponsiveSidebar/sidebar.actions';

const LayoutTwoColumn: React.FC<AareonLayout> = (props) => {
	const { route, width } = props;
	const isHidden = width === 'sm' || width === 'xs';
	const [visible, setVisible] = React.useState<boolean>(!isHidden);
	const [asideWidth, setAsideWidth] = React.useState<any>();
	const asideGridRef = React.useRef<HTMLDivElement>(null);
	const windowSize = useWindowSize();
	const dispatch = useDispatch();
	React.useLayoutEffect(() => {
		if (isHidden) {
			setVisible(false);
			dispatch(mountSidebar(true));
		} else {
			setVisible(true);
		}
	}, [width]);

	/**
	 * Like we have a fixed sidenav and is not possible to handle it width (Because been fixed means is out of the flow), the only way
	 * is to inherit from parent's fixed width who is calculated using dynamic grid percentage width
	 */
	React.useLayoutEffect(() => {
		if (asideGridRef.current && asideGridRef.current.clientWidth) {
			setAsideWidth(asideGridRef.current.clientWidth);
		}
	}, [asideGridRef.current, windowSize]);

	const handleResponsiveSidebar = () => {
		if (isHidden) {
			dispatch(mountSidebar(true));
		} else {
			dispatch(mountSidebar(false));
		}
	};

	return (
		<Grid container>
			<Slide
				direction="right"
				in={visible}
				timeout={350}
				onEntered={handleResponsiveSidebar}
				mountOnEnter
				unmountOnExit
			>
				<Grid ref={asideGridRef} item md={3} lg={3} xl={2}>
					<Aside fixedWidth={asideWidth}>
						<Menu itemsMenu={route.routes} />
					</Aside>
				</Grid>
			</Slide>
			<Slide direction="left" in={true} timeout={350} mountOnEnter unmountOnExit>
				<Grid item xs={12} sm={12} md={9} lg={9} xl={10}>
					<Main routes={route.routes} />
					<Footer />
				</Grid>
			</Slide>
		</Grid>
	);
};

export default withWidth()(LayoutTwoColumn);
