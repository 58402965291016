import React from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import CloseIcon from '@material-ui/icons/Close';
import { AdviceSnackbarProps } from '../../core/models/Snackbar';

// TODO: Refactor this spaguetti code
const AdviceSnackbar: React.FC<AdviceSnackbarProps> = (props) => {
	const { message, anchorOrigin } = props;
	const [snackbarState, setSnackbarState] = React.useState<boolean>(true);

	const handleOnClose = () => {
		setSnackbarState(false);
	};

	const closeButton = (
		<IconButton
			className="c-snackbar__closeBtn"
			component="span"
			onClick={() => {
				handleOnClose();
			}}
		>
			<CloseIcon />
		</IconButton>
	);

	return (
		<div className="c-snackbar__root">
			<Snackbar anchorOrigin={anchorOrigin} open={snackbarState} className="c-snackbar">
				<SnackbarContentWrapper message={message} action={closeButton} />
			</Snackbar>
		</div>
	);
};

export default AdviceSnackbar;
