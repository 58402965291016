import { TotalState } from 'app/store/model/TotalState';

/**
 * PROFILEFORMDIALOG: SELECTORS
 *
 * @function
 * @name shouldRefreshUserList
 * @param {TotalState} state
 * @returns {boolean} Returns true if app should refresh user list
 */
export const shouldRefreshUserList = (state: TotalState): boolean => state.userListState.shouldRefreshUserList;
