import React from 'react';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-scroll';
import { removeActiveClasses } from '../../utils/common';
import { useHistory } from 'react-router-dom';
import { isScrolling } from '../MarkDown/markdown.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { closeSidebar } from '../ResponsiveSidebar/sidebar.actions';
import Cookies from 'js-cookie';

const VerticalScroll: React.FC<any> = (props: any) => {
	const { scrollItem, fromClick, startScrolling } = props;
	const [indicatorHeight, setIndicatorHeight] = React.useState(0);
	const [indicatorPosition, setIndicatorPosition] = React.useState(0);
	const scrolling = useSelector(isScrolling);
	const dispatch = useDispatch();
	let history = useHistory();
	// FIXME: DON'T USE THIS LOGIC TWICE COPY AND PASTE
	const pathArray = location.pathname.split('/');
	// If the path has more than 1 child, keep the main parent to not animate in childrens
	const transitionKey = pathArray.length > 3 ? `/${pathArray[1]}/${pathArray[2]}` : location.pathname;
	const [open, setOpen] = React.useState(scrollItem.data.opened || scrollItem.url === transitionKey);
	//Handle access to config cookie page
	const [cookieSettings] = React.useState(!Cookies.get('userCookieList') ? true : false);

	React.useEffect(() => {
		const pathArray = location.pathname.split('/');
		// If the path has more than 1 child, keep the main parent to not animate in childrens
		const transitionKey = pathArray.length > 3 ? `/${pathArray[1]}/${pathArray[2]}` : location.pathname;
		setOpen(scrollItem.url === transitionKey);
	}, [location.pathname]);

	const handleClick = (e: any) => {
		const linkRoute = e.currentTarget.getAttribute('data-url');
		const hasNoChilds = eval(e.currentTarget.getAttribute('data-no-childs'));
		if ((linkRoute !== location.pathname && hasNoChilds) || scrollItem.routes.length < 2) {
			history.push(linkRoute);
			dispatch(closeSidebar());
		}
		setOpen(!open);
		if (hasNoChilds) {
			const indicatorActiveClass = 'c-sidenav__indicator--active';
			const subitemActiveClass = 'c-sidenav__subitem--active';
			removeActiveClasses(indicatorActiveClass);
			removeActiveClasses(subitemActiveClass);
		}
	};

	const setLinkActive = (link: HTMLElement) => {
		const activeClass = 'c-sidenav__subitem--active';
		removeActiveClasses(activeClass);
		link.classList.add(activeClass);
	};

	const setIndicatorActive = (indicator: HTMLElement) => {
		const activeClass = 'c-sidenav__indicator--active';
		removeActiveClasses(activeClass);
		indicator.classList.add(activeClass);
	};

	const calculatePosition = (linkArray: HTMLCollection, listItemIndex: string) => {
		let position = 0;
		Array.from(linkArray).forEach((parent: any) => {
			const element = parent.children[0];
			const elementId = element.getAttribute('data-id');
			const elementIndex = elementId ? elementId.split('_')[1] : null;
			if (elementIndex && parseInt(elementIndex, 10) < parseInt(listItemIndex, 10)) {
				position += element.clientHeight;
			}
		});

		return position;
	};

	const moveIndicator = (listItem: any) => {
		// Prevent indicator moving on activeScrollElement
		if (scrolling && fromClick) {
			return;
		}

		// Handle Navigation
		const linkRoute = listItem.getAttribute('data-url');
		if (linkRoute !== location.pathname) {
			history.push(linkRoute);
		}

		const currentHeight = listItem.clientHeight;
		const linkArray = listItem.parentElement.parentElement.children;
		const indicator = document.getElementById(scrollItem.data.key + '_indicator');
		const position = calculatePosition(linkArray, listItem.dataset.index);

		setLinkActive(listItem);
		setIndicatorHeight(currentHeight);
		setIndicatorPosition(position);
		indicator && setIndicatorActive(indicator);
	};

	const handleActive = (containerId: string) => {
		const cleanId = containerId.split('_')[0];
		const listItemId = cleanId + '_link';
		const listItem = document.getElementById(listItemId);
		moveIndicator(listItem);
	};

	const handleListClick = (clickEvent: any) => {
		const listItem = clickEvent.currentTarget;
		const linkRoute = listItem.getAttribute('data-url');
		startScrolling();
		moveIndicator(listItem);
		history.push(linkRoute);
		dispatch(closeSidebar());
	};

	return (
		<React.Fragment>
			<ListItem
				button
				onClick={handleClick}
				disabled={scrollItem.data.key === 'cookie-settings' ? cookieSettings : scrollItem.data.disabled}
				key={scrollItem.data.key}
				data-url={scrollItem.url}
				data-no-childs={!scrollItem.routes || scrollItem.routes.length === 0}
				className={
					'c-sidenav__list ' +
					(scrollItem.routes && scrollItem.routes.length === 0 ? 'c-sidenav__parent ' : '') +
					(scrollItem.routes && scrollItem.routes.length === 0 && location.pathname === scrollItem.url
						? 'c-sidenav__parent--active'
						: '')
				}
			>
				{scrollItem.routes && scrollItem.routes.length > 1 ? !open ? <ArrowRightIcon /> : <ArrowDropDownIcon /> : null}
				<ListItemText className={'c-sidenav__label'} primary={scrollItem.data.label} />
			</ListItem>

			{scrollItem.routes && scrollItem.routes.length !== 1 && (
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding className={'c-sidenav__sublist'}>
						{scrollItem.routes.map((route: any, index: number) => {
							return (
								route.data.visible && (
									<Link
										to={route.data.key + '_container'}
										spy={true}
										smooth={true}
										offset={-160}
										key={index}
										onSetActive={handleActive}
									>
										<ListItem
											button
											className={'c-sidenav__subitem'}
											onClick={handleListClick}
											data-url={route.url}
											data-id={scrollItem.data.key + '_' + index}
											data-index={index}
											data-container={route.data.key + '_container'}
											id={route.data.key + '_link'}
										>
											<ListItemText className={'c-sidenav__label'} primary={route.data.label} />
										</ListItem>
									</Link>
								)
							);
						})}
						<div className="c-sidenav__slider">
							<div
								className="c-sidenav__indicator"
								id={scrollItem.data.key + '_indicator'}
								style={{
									height: indicatorHeight,
									transform: `translateY( ${indicatorPosition}px)`
								}}
							></div>
						</div>
					</List>
				</Collapse>
			)}
		</React.Fragment>
	);
};

export default VerticalScroll;
