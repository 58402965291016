import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';
import services from '../services';
import { persistStore } from 'redux-persist';

const composeEnhancers = composeWithDevTools({
	trace: true,
	traceLimit: 25
});

const middlewares = [thunk.withExtraArgument(services)];

export const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);
