import React, { useState } from 'react';
import MUIDataTable, {
	TableToolbarSelect,
	TableFilter,
	MUIDataTableOptions,
	MUIDataTableToolbarSelect,
	MUIDataTableState,
	MUIDataTableColumn
} from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	FormControl,
	ListItemText,
	Checkbox,
	Select,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	FormControlLabel,
	Input
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ActivationAction from '../ActivationAction';
import CustomToolbarSelect from './ActionsToolbarSelect';
import RowAction from './RowAction';
import { ActionTableProps, BulkActionProps, HeadCell, TableMeta, CustomMUIDataTableProps } from './model/ActionTable';

const CustomMUIDataTable: React.FC<CustomMUIDataTableProps> = (props: any) => {
	// let newProps = Object.assign({}, props);

	return <MUIDataTable {...props} />;
};

const CustomCheckbox = (props: any) => {
	let newProps = Object.assign({}, props);

	return <Checkbox {...newProps} color="default" classes={{ root: 'c-action-table__checkbox' }} />;
};

const CustomFilter = (props: any) => {
	return <TableFilter classes={{ root: 'c-action-table__filter' }} {...props} />;
};

const CustomTableToolbarSelect = (props: MUIDataTableToolbarSelect) => {
	return <TableToolbarSelect {...props} classes={{ root: 'c-action-table__actions' }} />;
};

const getColumns = (headCells: HeadCell[]) => {
	const { t } = useTranslation(['userList', 'profileForm', 'generics']);
	let columns: string[] = [];

	headCells.map((headCell: HeadCell) => {
		let data: any;
		if (headCell.type === 'string') {
			data = {
				name: headCell.id,
				label: t(`userList:${headCell.label}`),
				options: {
					filter: headCell.options.filter,
					sort: headCell.options.sort,
					display: headCell.options.display,
					setCellHeaderProps: () => {
						return {
							className: `c-action-table__header c-action-table__header-cell ${
								headCell.alignHead === 'center' ? `c-action-table__header-center` : ``
							} `,
							style: {
								textAlign: headCell.alignHead
							}
						};
					},
					setCellProps: () => {
						return {
							className: 'c-action-table__cell',
							style: {
								textAlign: headCell.alignContent
							}
						};
					},
					customBodyRender: (value: string) => {
						let val = value;
						if (headCell.id === 'role') {
							val = t(`profileForm:form.radio.role.options.${value}.name`);
						} else if (headCell.id === 'activation_date' && value !== '-') {
							val = new Date(value).toLocaleDateString();
						}
						return val;
					}
				}
			};

			if (headCell.id === 'activation_date' && headCell.options.filterType === 'custom') {
				const [date, setDate] = useState<string | Date | undefined>(new Date());
				data.options.filterType = headCell.options.filterType;
				data.options.filterOptions = {
					logic: (value: string, filters: any[]) => {
						if (filters.length) return !filters.includes(value);
						return false;
					},
					display: (
						filterList: MUIDataTableState['filterList'],
						onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
						index: number,
						column: MUIDataTableColumn,
						_filterData: MUIDataTableState['filterData']
					) => {
						return (
							<FormControl>
								<FormControl className="c-action-table__filter-label" variant="outlined" fullWidth margin="normal">
									<FormLabel>{t(`userList:${headCell.label}`)}</FormLabel>
								</FormControl>
								<MuiPickersUtilsProvider utils={MomentUtils}>
									<KeyboardDatePicker
										className={'c-custom-input'}
										format="DD/MM/yyyy"
										id="date-picker"
										inputVariant="outlined"
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
										margin="normal"
										onChange={(date: moment.Moment | null, value?: string | null | undefined) => {
											filterList[index] = [value ? value : ''];
											setDate(date?.toLocaleString());
											onChange(filterList[index], index, column);
										}}
										placeholder="dd/mm/yyyy"
										value={date}
										variant="inline"
									/>
								</MuiPickersUtilsProvider>
							</FormControl>
						);
					}
				};
			} else if (headCell.options.filterType === 'custom') {
				data.options.filterType = headCell.options.filterType;
				data.options.filterOptions = {
					logic: (value: string, filters: any[]) => {
						if (filters.length) return !filters.includes(value);
						return false;
					},
					display: (
						filterList: MUIDataTableState['filterList'],
						onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
						index: number,
						column: MUIDataTableColumn,
						filterData: MUIDataTableState['filterData']
					) => {
						return (
							<FormControl>
								<FormControl className="c-action-table__filter-label" variant="outlined" fullWidth margin="normal">
									<FormLabel>{t(`userList:${headCell.label}`)}</FormLabel>
								</FormControl>
								<Select
									multiple
									value={filterList[index]}
									renderValue={(selected: any) => selected.join(', ')}
									onChange={(event) => {
										filterList[index] = event.target.value as string[];
										onChange(filterList[index], index, column);
									}}
									classes={{
										root: 'c-action-table__filter-select c-action-table__filter-select--container',
										icon: 'c-action-table__icon'
									}}
									input={<Input className={'c-action-table__filter-select'} />}
									MenuProps={{ classes: { paper: 'c-action-table__filter-select--container-background' } }}
								>
									{filterData[index].map((item) => (
										<MenuItem key={item} value={item}>
											<Checkbox
												color="primary"
												className="c-action-table__checkbox"
												checked={filterList[index].indexOf(item) > -1}
											/>
											<ListItemText primary={item} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					}
				};
			}
		} else if (headCell.type === 'number') {
			data = {
				name: headCell.id,
				label: t(`userList:${headCell.label}`),
				options: {
					filter: headCell.options.filter,
					sort: headCell.options.sort,
					display: headCell.options.display
				}
			};
		} else if (headCell.type === 'switch') {
			data = {
				name: headCell.id,
				label: t(`userList:${headCell.label}`),
				options: {
					filter: headCell.options.filter,
					sort: headCell.options.sort,
					empty: headCell.options.empty,
					setCellHeaderProps: () => {
						return {
							className: 'c-action-table__header c-action-table__header-cell',
							style: {
								textAlign: headCell.alignHead
							}
						};
					},
					setCellProps: () => {
						return {
							style: {
								textAlign: headCell.alignContent
							}
						};
					},
					customBodyRender: (value: boolean, tableMeta: TableMeta) => {
						return (
							<ActivationAction
								active={value}
								callback={() => {
									if (value) {
										headCell.options.callbacks[1].callback([tableMeta.rowData]);
									} else {
										headCell.options.callbacks[0].callback([tableMeta.rowData]);
									}
								}}
							></ActivationAction>
						);
					}
				}
			};

			if (headCell.options.filterType === 'custom') {
				const [radio, setRadio] = useState<boolean | string>('');
				data.options.filterType = headCell.options.filterType;
				data.options.customFilterListOptions = {
					render: (v: any) => (v[0] ? t('userList:table.active') : t('userList:table.inactive'))
				};
				data.options.filterOptions = {
					logic: (value: string, filters: any[]) => {
						if (filters.length) return !filters.includes(value);
						return false;
					},
					display: (
						filterList: any[],
						onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
						index: number,
						column: MUIDataTableColumn,
						filterData: MUIDataTableState['filterData']
					) => {
						return (
							<FormControl>
								<FormControl className="c-action-table__filter-label" variant="outlined" fullWidth margin="normal">
									<FormLabel>{t(`userList:${headCell.label}`)}</FormLabel>
								</FormControl>
								<RadioGroup
									aria-label={t(`userList:${headCell.label}`)}
									className="c-action-table__filter-radio"
									onChange={(event) => {
										filterList[index] = [event.target.value === 'true'];
										setRadio(event.target.value === 'true');
										onChange(filterList[index], index, column);
									}}
									value={radio}
									row
								>
									{filterData[index].map((item, index) => (
										<FormControlLabel
											value={item}
											key={index}
											control={<Radio color="default" />}
											label={item ? t('generics:yes') : t('generics:no')}
											color="default"
										/>
									))}
								</RadioGroup>
							</FormControl>
						);
					}
				};
			}
		} else if (headCell.type === 'actions') {
			data = {
				name: headCell.id,
				label: t(`userList:${headCell.label}`),
				options: {
					filter: headCell.options.filter,
					sort: headCell.options.sort,
					empty: headCell.options.empty,
					setCellHeaderProps: () => {
						return {
							className: 'c-action-table__header c-action-table__header-cell',
							style: {
								textAlign: headCell.alignHead
							}
						};
					},
					setCellProps: () => {
						return {
							style: {
								textAlign: headCell.alignContent
							},
							className: 'c-action-table__actions-container'
						};
					},
					customBodyRender: (_value: any, tableMeta: TableMeta) => {
						return (
							<React.Fragment>
								{headCell.options.callbacks &&
									headCell.options.callbacks.map((action, index: number) => {
										return (
											<RowAction
												callback={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
													e?.stopPropagation(); // needed to prevent row selection
													action.callback(tableMeta.rowData);
												}}
												icon={action.icon}
												key={`${action.name}__${index}`}
												name={t(`userList:${action.name}`)}
											></RowAction>
										);
									})}
							</React.Fragment>
						);
					}
				}
			};
		}

		columns.push(data);
	});

	return columns;
};

const getOptions = (
	bulkActions: BulkActionProps[],
	orderByDefault: any | null,
	pagination: boolean,
	isSelectable: boolean,
	isSortable: boolean,
	isLoading: boolean
) => {
	const { t } = useTranslation(['actionTable']);

	const [sortBy, setSortBy] = useState<{ name: string; direction: 'desc' | 'asc' }>({
		name: orderByDefault,
		direction: 'asc'
	});

	const options: MUIDataTableOptions = {
		search: true,
		filter: true,
		filterType: 'checkbox',
		responsive: 'standard',
		pagination: pagination,
		download: false,
		print: false,
		viewColumns: false,
		sort: isSortable,
		selectableRows: isSelectable ? 'multiple' : 'none',
		sortOrder: {
			name: sortBy.name,
			direction: sortBy.direction
		},
		setTableProps: () => {
			return {
				padding: 'none',
				size: 'small',
				className: 'c-action-table__actions'
			};
		},
		setRowProps: () => {
			return {
				className: 'c-action-table__row'
			};
		},
		customToolbarSelect: (
			selectedRows: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } },
			displayData: Array<{ data: any[]; dataIndex: number }>,
			setSelectedRows: (rows: number[]) => void
		) => (
			<CustomToolbarSelect
				selectedRows={selectedRows}
				displayData={displayData}
				setSelectedRows={setSelectedRows}
				bulkActions={bulkActions}
			/>
		),
		textLabels: {
			body: {
				noMatch: isLoading ? (
					<CircularProgress size={30} className={'c-action-table__loadingProgress'} />
				) : (
					t('muidatatable.body.noMatch')
				),
				toolTip: t('muidatatable.body.toolTip'),
				columnHeaderTooltip: (column: any) => `Sort for ${column.label}`
			},
			pagination: {
				next: t('muidatatable.pagination.next'),
				previous: t('muidatatable.pagination.previous'),
				rowsPerPage: t('muidatatable.pagination.rowsPerPage'),
				displayRows: t('muidatatable.pagination.displayRows')
			},
			toolbar: {
				search: t('muidatatable.toolbar.search'),
				downloadCsv: t('muidatatable.toolbar.downloadCsv'),
				print: t('muidatatable.toolbar.print'),
				viewColumns: t('muidatatable.toolbar.viewColumns'),
				filterTable: t('muidatatable.toolbar.filterTable')
			},
			filter: {
				all: t('muidatatable.filter.all'),
				title: t('muidatatable.filter.title'),
				reset: t('muidatatable.filter.reset')
			},
			viewColumns: {
				title: t('muidatatable.viewColumns.title'),
				titleAria: t('muidatatable.viewColumns.titleAria')
			},
			selectedRows: {
				text: t('muidatatable.selectedRows.text'),
				delete: t('muidatatable.selectedRows.delete'),
				deleteAria: t('muidatatable.selectedRows.deleteAria')
			}
		},
		onTableChange: (action: string, tableState: MUIDataTableState) => {
			if (action !== 'propsUpdate') {
				setSortBy({ name: tableState.sortOrder.name, direction: tableState.sortOrder.direction });
			}
		}
	};
	return options;
};

const ActionTable = (props: ActionTableProps) => {
	const {
		config,
		orderByDefault,
		pagination = true,
		rows,
		title,
		isLoading = false,
		isSelectable = true,
		isSortable = true
	} = props;
	const columns = getColumns(config[0]);
	const bulkActions = config[1];
	const options: MUIDataTableOptions = getOptions(
		bulkActions,
		orderByDefault,
		pagination,
		isSelectable,
		isSortable,
		isLoading
	);

	return (
		<CustomMUIDataTable
			title={title}
			data={rows}
			columns={columns}
			options={options}
			classes={{ paper: 'c-action-table__paper' }}
			components={{
				Checkbox: CustomCheckbox,
				TableToolbarSelect: CustomTableToolbarSelect,
				TableFilter: CustomFilter
			}}
		/>
	);
};

export default ActionTable;
