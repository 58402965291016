// @ts-ignore
import Dark404Logo from '../../assets/img/Dark404Logo.svg';
// @ts-ignore
import Light404Logo from '../../assets/img/Light404Logo.svg';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { SwitchStatus } from '../SwitchMode/model/ESwitchStatus';
import { useSelector } from 'react-redux';
import { switchmodeStatus } from '../SwitchMode/switchmode.selectors';
import { useTranslation } from 'react-i18next';
import { Logo404Props } from '../../core/interfaces/Logo404Props';

const Logo404: React.FC<Logo404Props> = (props) => {
	const { mainText, infoText, buttonText } = props;
	const [t] = useTranslation('logo404');
	let history = useHistory();
	let darkTheme: SwitchStatus = useSelector(switchmodeStatus);
	const onStatus = SwitchStatus.on;

	return (
		<div className="c-logo404__wrapper">
			{darkTheme === onStatus ? <Dark404Logo id={'dark-logo'} /> : <Light404Logo id={'light-logo'} />}
			<Typography className={'c-logo404__typo--main'} variant="h2" id="main-text-404">
				{t(mainText)}
			</Typography>
			<Typography className={'c-logo404__typo--info'} variant="h6" id="info-text-404">
				{t(infoText)}
			</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					history.push('/');
				}}
				className="c-button c-logo404__button"
			>
				{t(buttonText)}
			</Button>
		</div>
	);
};

export default Logo404;
