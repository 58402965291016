import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const LANGUAGES = require('../../assets/locales/en/languageMenu.json');

const LanguageMenu: React.FC<any> = (props) => {
	const { login } = props;
	const { t, i18n } = useTranslation('languageMenu');
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const handleLanguageSelection = (lang: string) => (): void => {
		console.log(lang);
		//TODO: Create the content in the languages aproved
		//i18n.changeLanguage(lang);
		handleClose();
	};

	return (
		<React.Fragment>
			<div onClick={handleClick} className={`c-language-menu__button ${login && 'c-language-menu__login'}`}>
				<div className={'c-language-menu__container'}>
					<TranslateIcon className="c-language-menu__icon-left" />
					<span className="c-language-menu__span">{LANGUAGES[i18n.language] ? LANGUAGES[i18n.language] : t('en')}</span>
				</div>
				<ExpandMoreIcon className="c-language-menu__icon-right" fontSize="small" />
			</div>

			<Menu
				id="language-simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				classes={{ paper: 'c-language-menu__menu' }}
			>
				{Object.keys(LANGUAGES).map((key: string, index: number) => {
					return (
						<MenuItem
							className="c-language-menu__item"
							key={`${key}-${index}`}
							selected={i18n.language === key}
							onClick={handleLanguageSelection(key)}
						>
							{t(key)}
						</MenuItem>
					);
				})}
			</Menu>
		</React.Fragment>
	);
};

export default LanguageMenu;
