// ## START imports
import hljs from 'highlight.js';
import { KJUR, b64utoutf8 } from 'jsrsasign';
import { UserInfo } from 'app/components/Login/model/UserInfo';
// ## END imports

/**
 * @name removeActiveClasses
 * @param {string} activeClass
 * @description This function is made for remove some active classes on different
 * components or instances of the same component
 */
export const removeActiveClasses = (activeClass: string) => {
	const activeElements = document.getElementsByClassName(activeClass);
	// Remove the active class wherever it is
	for (let item of activeElements) {
		item.classList.remove(activeClass);
	}
};
/**
 * @name filterDupByKey
 * @param {T[]} arr  - The array to group
 * @param {string} key - Field to group by
 * @returns {T[]} Return array gruped
 * @template T
 * @description This function groups an array of objects by the field
 */
export function filterDupByKey<T>(arr: T[], key: string): T[] {
	const temp = arr.map((el) => (el as any)[key]);
	return arr.filter((el, i) => temp.indexOf((el as any)[key]) === i);
}
/**
 * @name clone
 * @param {object} obj  - Object to clone
 * @returns {object} Return object cloned
 * @description This function clone a object
 */
export const clone = (obj: object): object => {
	if (null == obj || 'object' !== typeof obj) return obj;
	const copy = obj.constructor();
	for (let attr in obj) {
		if (obj.hasOwnProperty(attr)) copy[attr] = (obj as any)[attr];
	}
	return copy;
};

/**
 * @name getBrowserInfo
 * @returns {string} Browser name and version.
 * @description Gets information about the browser used.
 */
export const getBrowserInfo = (): string => {
	const userAgent = navigator.userAgent;
	let name = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	let version;
	if (/trident/i.test(name[1])) {
		version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		return 'IE ' + (version[1] || '');
	}
	if (name[1] === 'Chrome') {
		version = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
		if (version != null) {
			return version
				.slice(1)
				.join(' ')
				.replace('OPR', 'Opera');
		}
	}
	name = name[2] ? [name[1], name[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((version = userAgent.match(/version\/(\d+)/i)) != null) {
		name.splice(1, 1, version[1]);
	}
	return name.join(' ');
};

/**
 * @name setAvatarContent
 * @param {any} user The logged user
 * @returns {string} Return letters to set inside of avatar
 * @description Set the content of Avatar Material UI component
 */
export const setAvatarContent = (user: any): string => {
	let avatarContent = '';
	if (user && user.identity) {
		const firstname = user.identity.first_name;
		const name = user.identity.name;
		if (firstname && name) {
			avatarContent = firstname.charAt(0) + name.charAt(0);
		} else if (firstname || name) {
			avatarContent = firstname ? firstname.charAt(0) : name.charAt(0);
		}
	}
	return avatarContent;
};

/**
 * @name detectProgrammingLanguage
 * @param {string} code
 * @returns {string} Programming language name.
 * @description This function detects the programming language name using highligh.js library
 * also it marks all curl found as shell code due to markdown possible failure.
 */
export const detectProgrammingLanguage = (code: string): string => {
	return code.match(/curl\s-X\s/gm) ? 'sh' : hljs.highlightAuto(code).language || 'sh';
};

/**
 * @name generateRandomId
 * @param {number} length
 * @returns {string} Random alpha numeric id.
 * @description Returns a random alpha numeric string based on length passed
 */
export const generateRandomId = (length: number): string => {
	let id = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		id += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return id;
};

/**
 * @name parseTokenToJson
 * @param {string} token
 * @description Use jwt third party library to parse jwt to readable json
 * @returns {UserInfo} Returns token parsed as json object
 */
export const parseTokenToJson = (token: string): UserInfo => {
	// @ts-ignore
	return KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split('.')[1])).context;
};
