import { SIDEBAR_CLOSE, SIDEBAR_OPEN, SIDEBAR_TOGGLE, SIDEBAR_MOUNT } from './sidebar.const';
import { SidebarActionTypes } from './sidebar.types';
import { SidebarState } from './model/SidebarState';

const initialState: SidebarState = {
	open: false,
	mount: false
};

const sideBarReducer = (state = initialState, action: SidebarActionTypes): SidebarState => {
	switch (action.type) {
		case SIDEBAR_OPEN:
			return {
				...state,
				open: true
			};
		case SIDEBAR_CLOSE:
			return {
				...state,
				open: false
			};
		case SIDEBAR_TOGGLE:
			return {
				...state,
				open: !state.open
			};
		case SIDEBAR_MOUNT:
			return {
				...state,
				mount: action.payload
			};
		default:
			return state;
	}
};

export default sideBarReducer;
