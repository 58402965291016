import React from 'react';
import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from 'react-router-dom';

// TODO: REFACTOR ALL THIS CODE
const returnLinkType = (i_linkType: string, i_params?: object, i_content?: any) => {
	switch (i_linkType) {
		case 'href':
			return (
				<Link className={i_params.objectClass} key={i_params.objectKey} href={i_params.href} target={i_params.target}>
					{i_content}
				</Link>
			);
		case 'router':
			return (
				<LinkRouter className={i_params.objectClass} key={i_params.objectKey} to={i_params.href}>
					{i_content}
				</LinkRouter>
			);
		default:
			return (
				<Link className={i_params.objectClass} key={i_params.objectKey} href={i_params.href} target={i_params.target}>
					{i_content}
				</Link>
			);
	}
};

/**
 * @name NavigatorLink
 * @param {object} props object with differents props
 * @description return differents links types according to linkType prop
 * @returns {React.ReactElement<any>} Return linktType
 */
export default function NavigatorLink(props: any): React.ReactElement<any> {
	const { linkType, componentParams, content } = props;

	return returnLinkType(linkType, componentParams, content);
}
