import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationBarLinkProps } from './model/NavigationBarLink';

const NavigationBarLink: React.FC<NavigationBarLinkProps> = (props) => {
	const { route } = props;
	let location = useLocation();
	const pathArray = location.pathname.split('/');

	return (
		<>
			{route && (
				<Link
					className={`c-navigation-bar-link ${`/${pathArray[1]}` === route.url ? 'c-navigation-bar-link--active' : ''}`}
					to={route.url}
				>
					{route.data.label}
				</Link>
			)}
		</>
	);
};

export default NavigationBarLink;
