import * as components from './Imports';

const routesAuto = [
	{
		path: '/guides',
		url: '/guides',
		component: components.LayoutThreeColumn,
		data: { label: 'Guides', key: 'guides', order: 1, visible: true, role: 'user' },
		routes: [
			{
				component: components.Markdown,
				exact: true,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/overview.md',
					key: 'overview',
					label: 'Overview',
					opened: false,
					order: 0,
					role: 'user',
					visible: true
				},
				path: '/guides',
				routes: [],
				url: '/guides'
			},
			{
				component: components.Markdown,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/how-to-call-a-service.md',
					key: 'how-to-call-services',
					label: 'How to call a Service',
					opened: false,
					order: 2,
					role: 'user',
					visible: true
				},
				path: '/guides/how-to-call-services/:anchor?',
				routes: [
					{
						data: {
							label: 'Calling a service',
							key: 'calling-a-service',
							order: 0,
							description: 'Calling a service',
							visible: true
						},
						path: '/guides/how-to-call-services/calling-a-service',
						url: '/guides/how-to-call-services/calling-a-service'
					},
					{
						data: {
							label: 'Information about the header',
							key: 'headers-info',
							order: 1,
							description: 'Information about the header',
							visible: true
						},
						path: '/guides/how-to-call-services/headers-info',
						url: '/guides/how-to-call-services/headers-info'
					},
					{
						data: {
							label: 'Information about authentication',
							key: 'auth-info',
							order: 2,
							description: 'Information about authentication',
							visible: true
						},
						path: '/guides/how-to-call-services/auth-info',
						url: '/guides/how-to-call-services/auth-info'
					},
					{
						data: {
							label: 'Get All and list all',
							key: 'get-all',
							order: 3,
							description: 'Get All and list all',
							visible: true
						},
						path: '/guides/how-to-call-services/get-all',
						url: '/guides/how-to-call-services/get-all'
					},
					{
						data: { label: 'Get by Ids', key: 'get-by-id', order: 4, description: 'Get by Ids', visible: true },
						path: '/guides/how-to-call-services/get-by-id',
						url: '/guides/how-to-call-services/get-by-id'
					},
					{
						data: { label: 'Search By', key: 'search-by', order: 5, description: 'Search By', visible: true },
						path: '/guides/how-to-call-services/search-by',
						url: '/guides/how-to-call-services/search-by'
					}
				],
				url: '/guides/how-to-call-services'
			},
			{
				component: components.Markdown,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/get-started.md',
					key: 'get-started',
					label: 'Get Started',
					opened: false,
					order: 1,
					role: 'user',
					visible: true
				},
				path: '/guides/get-started/:anchor?',
				routes: [
					{
						data: {
							label: 'Before you start',
							key: 'before-you-start',
							order: 0,
							description: 'Before you start',
							visible: true
						},
						path: '/guides/get-started/before-you-start',
						url: '/guides/get-started/before-you-start'
					},
					{
						data: {
							label: 'Example of a call of a GET api',
							key: 'how-to-get-api',
							order: 1,
							description: 'Example of a call of a GET api',
							visible: true
						},
						path: '/guides/get-started/how-to-get-api',
						url: '/guides/get-started/how-to-get-api'
					},
					{
						data: {
							label: 'Return format',
							key: 'return-format',
							order: 2,
							description: 'Return format',
							visible: true
						},
						path: '/guides/get-started/return-format',
						url: '/guides/get-started/return-format'
					},
					{
						data: {
							label: 'Standard HTTP response code',
							key: 'response-codes',
							order: 3,
							description: 'Standard HTTP response code',
							visible: true
						},
						path: '/guides/get-started/response-codes',
						url: '/guides/get-started/response-codes'
					},
					{
						data: {
							label: 'General information about retrieved data',
							key: 'general-info',
							order: 4,
							description: 'General information about retrieved data',
							visible: true
						},
						path: '/guides/get-started/general-info',
						url: '/guides/get-started/general-info'
					},
					{
						data: {
							label: 'Recommended tool',
							key: 'recommended-tools',
							order: 5,
							description: 'Recommended tool',
							visible: true
						},
						path: '/guides/get-started/recommended-tools',
						url: '/guides/get-started/recommended-tools'
					},
					{
						data: {
							label: 'List of tutorials on internet',
							key: 'tutorials',
							order: 6,
							description: 'List of tutorials on internet',
							visible: true
						},
						path: '/guides/get-started/tutorials',
						url: '/guides/get-started/tutorials'
					},
					{
						data: {
							label: 'Introduction about Aareon Smart Platform: Smart Services',
							key: 'introduction-smart-services',
							order: 7,
							description: 'Introduction about Aareon Smart Platform: Smart Services',
							visible: true
						},
						path: '/guides/get-started/introduction-smart-services',
						url: '/guides/get-started/introduction-smart-services'
					}
				],
				url: '/guides/get-started'
			},
			{
				component: components.Markdown,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/crm-services.md',
					key: 'crm-services',
					label: 'CRM services',
					opened: false,
					order: 5,
					role: 'user',
					visible: true
				},
				path: '/guides/crm-services/:anchor?',
				routes: [
					{
						data: {
							label: 'Introduction of CRM context',
							key: 'intro',
							order: 0,
							description: 'Introduction of CRM context',
							visible: true
						},
						path: '/guides/crm-services/intro',
						url: '/guides/crm-services/intro'
					},
					{
						data: {
							label: 'Tenant information',
							key: 'tenant-info',
							order: 1,
							description: 'Tenant information in CRM context',
							visible: true
						},
						path: '/guides/crm-services/tenant-info',
						url: '/guides/crm-services/tenant-info'
					},
					{
						data: {
							label: 'Insurances attached to a contract',
							key: 'insurances-contracts',
							order: 3,
							description: 'Insurances attached to a contract in CRM context',
							visible: true
						},
						path: '/guides/crm-services/insurances-contracts',
						url: '/guides/crm-services/insurances-contracts'
					},
					{
						data: {
							label: 'Claims and follow-ups',
							key: 'claims',
							order: 4,
							description: 'Claims and follow-ups in CRM context',
							visible: true
						},
						path: '/guides/crm-services/claims',
						url: '/guides/crm-services/claims'
					},
					{
						data: {
							label: 'Claim classification',
							key: 'claim-classification',
							order: 5,
							description: 'Claim classification (in CRM context)',
							visible: true
						},
						path: '/guides/crm-services/claim-classification',
						url: '/guides/crm-services/claim-classification'
					},
					{
						data: {
							label: 'Create a claim',
							key: 'add-claim',
							order: 6,
							description: 'Create a claim in CRM context',
							visible: true
						},
						path: '/guides/crm-services/add-claim',
						url: '/guides/crm-services/add-claim'
					},
					{
						data: {
							label: 'Change a phone information of a tenant',
							key: 'change-phone-info',
							order: 7,
							description: 'Change a phone information of a tenant in CRM context',
							visible: true
						},
						path: '/guides/crm-services/change-phone-info',
						url: '/guides/crm-services/change-phone-info'
					},
					{
						data: {
							label: 'Change the email of a tenant',
							key: 'change-email',
							order: 8,
							description: 'Change the email of a tenant in CRM context',
							visible: true
						},
						path: '/guides/crm-services/change-email',
						url: '/guides/crm-services/change-email'
					},
					{
						data: {
							label: 'Third Parties',
							key: 'third-parties',
							order: 9,
							description: 'Third Parties in CRM context',
							visible: true
						},
						path: '/guides/crm-services/third-parties',
						url: '/guides/crm-services/third-parties'
					},
					{
						data: {
							label: 'How to get Identity',
							key: 'get-identity',
							order: 10,
							description: 'How to get Identity in CRM context',
							visible: true
						},
						path: '/guides/crm-services/get-identity',
						url: '/guides/crm-services/get-identity'
					},
					{
						data: {
							label: 'ASP CRM context',
							key: 'asp-crm-context',
							order: 11,
							description: 'Smart Platform CRM context',
							visible: true
						},
						path: '/guides/crm-services/asp-crm-context',
						url: '/guides/crm-services/asp-crm-context'
					}
				],
				url: '/guides/crm-services'
			},
			{
				component: components.Markdown,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/core-services.md',
					key: 'core-services',
					label: 'Core Services',
					opened: false,
					order: 3,
					role: 'user',
					visible: true
				},
				path: '/guides/core-services/:anchor?',
				routes: [
					{
						data: { label: 'Authentication', key: 'auth', order: 0, description: 'Authentication', visible: true },
						path: '/guides/core-services/auth',
						url: '/guides/core-services/auth'
					},
					{
						data: {
							label: 'User information',
							key: 'core-user-info',
							order: 1,
							description: 'User information',
							visible: true
						},
						path: '/guides/core-services/core-user-info',
						url: '/guides/core-services/core-user-info'
					},
					{
						data: {
							label: 'Contact information of a identity',
							key: 'contact-info',
							order: 2,
							description: 'Contact information of a identity',
							visible: true
						},
						path: '/guides/core-services/contact-info',
						url: '/guides/core-services/contact-info'
					},
					{
						data: { label: 'Address', key: 'address', order: 3, description: 'Address', visible: true },
						path: '/guides/core-services/address',
						url: '/guides/core-services/address'
					}
				],
				url: '/guides/core-services'
			},
			{
				component: components.Markdown,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/brm-services.md',
					key: 'brm-services',
					label: 'BRM Services',
					opened: false,
					order: 4,
					role: 'user',
					visible: true
				},
				path: '/guides/brm-services/:anchor?',
				routes: [
					{
						data: {
							label: 'Introduction of BRM context',
							key: 'intro',
							order: 0,
							description: 'Introduction of BRM context',
							visible: true
						},
						path: '/guides/brm-services/intro',
						url: '/guides/brm-services/intro'
					},
					{
						data: {
							label: 'Real estate',
							key: 'real-state',
							order: 1,
							description: 'Real estate in BRM context',
							visible: true
						},
						path: '/guides/brm-services/real-state',
						url: '/guides/brm-services/real-state'
					},
					{
						data: {
							label: 'Tenant contract',
							key: 'tenant-contract',
							order: 2,
							description: 'Tenant contract in BRM context',
							visible: true
						},
						path: '/guides/brm-services/tenant-contract',
						url: '/guides/brm-services/tenant-contract'
					},
					{
						data: {
							label: 'Insurances attached to a contract',
							key: 'insurances',
							order: 3,
							description: 'Insurances attached to a contract in BRM context',
							visible: true
						},
						path: '/guides/brm-services/insurances',
						url: '/guides/brm-services/insurances'
					},
					{
						data: {
							label: 'How to search a tenant by his phone number',
							key: 'search-tenant-by-phone',
							order: 4,
							description: 'How to search a tenant by his phone number in BRM context',
							visible: true
						},
						path: '/guides/brm-services/search-tenant-by-phone',
						url: '/guides/brm-services/search-tenant-by-phone'
					},
					{
						data: {
							label: 'How to search a tenant by his contract number',
							key: 'search-tenant-by-contract',
							order: 4,
							description: 'How to search a tenant by his contract number in BRM context',
							visible: true
						},
						path: '/guides/brm-services/search-tenant-by-contract',
						url: '/guides/brm-services/search-tenant-by-contract'
					},
					{
						data: {
							label: 'How to search a tenant by his name',
							key: 'search-tenant-by-name',
							order: 5,
							description: 'How to search a tenant by his name in BRM context',
							visible: true
						},
						path: '/guides/brm-services/search-tenant-by-name',
						url: '/guides/brm-services/search-tenant-by-name'
					},
					{
						data: {
							label: 'Claims and follow-ups',
							key: 'claims',
							order: 6,
							description: 'Claims and follow-ups in BRM context',
							visible: true
						},
						path: '/guides/brm-services/claims',
						url: '/guides/brm-services/claims'
					},
					{
						data: {
							label: 'Example of a claim search by date',
							key: 'claims-search-by-date',
							order: 7,
							description: 'Example of a claim search by date in BRM context',
							visible: true
						},
						path: '/guides/brm-services/claims-search-by-date',
						url: '/guides/brm-services/claims-search-by-date'
					},
					{
						data: {
							label: 'Create a new claim',
							key: 'new-claim',
							order: 8,
							description: 'Create a new claim in BRM context',
							visible: true
						},
						path: '/guides/brm-services/new-claim',
						url: '/guides/brm-services/new-claim'
					},
					{
						data: {
							label: 'Add a follow-up to a claim',
							key: 'add-follow-up-claim',
							order: 9,
							description: 'Add a follow-up to a claim in BRM context',
							visible: true
						},
						path: '/guides/brm-services/add-follow-up-claim',
						url: '/guides/brm-services/add-follow-up-claim'
					},
					{
						data: {
							label: 'Change a phone information of a tenant',
							key: 'change-phone-info-tenant',
							order: 10,
							description: 'Change a phone information of a tenant in BRM context',
							visible: true
						},
						path: '/guides/brm-services/change-phone-info-tenant',
						url: '/guides/brm-services/change-phone-info-tenant'
					},
					{
						data: {
							label: 'Change the email of a tenant',
							key: 'change-email-tenant',
							order: 11,
							description: 'Change a phone information of a tenant in BRM context',
							visible: true
						},
						path: '/guides/brm-services/change-email-tenant',
						url: '/guides/brm-services/change-email-tenant'
					}
				],
				url: '/guides/brm-services'
			},
			{
				path: '/guides/*',
				url: '/not-found',
				component: components.NoMatchRoute,
				data: { label: 'Guides', key: 'guides', order: 1000, visible: false, role: 'user' }
			}
		]
	},
	{
		path: '/api-reference',
		url: '/api-reference',
		component: components.LayoutTwoColumn,
		data: { key: 'api-reference', label: 'API Reference', type: 'api', order: 2, visible: true, role: 'user' },
		routes: [
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/tenantcontracts.json',
					key: 'business-tenant-contracts',
					label: 'How to get Tenant contracts',
					opened: false,
					order: 13,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-tenant-contracts',
				routes: [],
				url: '/api-reference/business-tenant-contracts'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/statuses.json',
					key: 'business-statuses',
					label: 'How to get Contract status',
					opened: false,
					order: 12,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-statuses',
				routes: [],
				url: '/api-reference/business-statuses'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/noticeperiodemodels.json',
					key: 'business-notice-period-models',
					label: 'How to get Notice period models',
					opened: false,
					order: 11,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-notice-period-models',
				routes: [],
				url: '/api-reference/business-notice-period-models'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/leavingreasons.json',
					key: 'business-leaving-reasons',
					label: 'How to get Leaving reasons',
					opened: false,
					order: 10,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-leaving-reasons',
				routes: [],
				url: '/api-reference/business-leaving-reasons'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/leases.json',
					key: 'business-leases',
					label: 'How to get Lease',
					opened: false,
					order: 9,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-leases',
				routes: [],
				url: '/api-reference/business-leases'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/hlmcategories.json',
					key: 'business-hlm-categories',
					label: 'How to get HLM category (french market only)',
					opened: false,
					order: 8,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-hlm-categories',
				routes: [],
				url: '/api-reference/business-hlm-categories'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/flattypes.json',
					key: 'business-flat-types',
					label: 'How to get flat type',
					opened: false,
					order: 7,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-flat-types',
				routes: [],
				url: '/api-reference/business-flat-types'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/flatcategories.json',
					key: 'business-flat-category',
					label: 'How to get flat category',
					opened: false,
					order: 6,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-flat-category',
				routes: [],
				url: '/api-reference/business-flat-category'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/estates.json',
					key: 'business-estates',
					label: 'How to get the tree of real estate',
					opened: false,
					order: 5,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-estates',
				routes: [],
				url: '/api-reference/business-estates'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/detailestatetypeflat.json',
					key: 'business-detail-estate-type-flats',
					label: 'How to get flat details',
					opened: false,
					order: 4,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-detail-estate-type-flats',
				routes: [],
				url: '/api-reference/business-detail-estate-type-flats'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/detailestatetypebuilding.json',
					key: 'business-detail-estate-type-buildings',
					label: 'How to get building details',
					opened: false,
					order: 3,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-detail-estate-type-buildings',
				routes: [],
				url: '/api-reference/business-detail-estate-type-buildings'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/contracts.json',
					key: 'business-contract',
					label: 'How to get Contract',
					opened: false,
					order: 2,
					role: 'user',
					visible: true
				},
				path: '/api-reference/business-contract',
				routes: [],
				url: '/api-reference/business-contract'
			},
			{
				component: components.Swagger,
				data: {
					icon: '',
					file: '/src/app/assets/technical-content-data/addresses.json',
					key: 'core-address',
					label: 'How to get the address',
					opened: false,
					order: 1,
					role: 'user',
					visible: true
				},
				path: '/api-reference/core-address',
				routes: [],
				url: '/api-reference/core-address'
			},
			{
				component: components.Markdown,
				exact: true,
				data: {
					icon: '',
					file: 'src/app/assets/static-files/overview-api.md',
					key: 'overview',
					label: 'API Reference',
					opened: false,
					order: 0,
					role: 'user',
					visible: true
				},
				path: '/api-reference',
				routes: [],
				url: '/api-reference'
			},
			{
				path: '/api-reference/*',
				component: components.NoMatchRoute,
				data: { label: '404', visible: false, order: 1000 },
				routes: []
			}
		]
	}
];

export default routesAuto;
