import React, { useEffect } from 'react';
import CookieInfoView from './CookieInfoView';
import MainCookie from './MainCookie';
import Cookies from 'js-cookie';
import { getCookieList, getUserCookies, setUserCookies } from '../../services/cookie.service';
import { Cookie } from 'app/core/interfaces/AdviceCookie';

const AdviceCookie: React.FC = () => {
	const [usersAcceptCookie, setUsersAcceptCookie] = React.useState<boolean>(!Cookies.get('userCookieList'));
	const [cookieMessage, setCookieMessage] = React.useState<boolean>(false);
	const [cookieList, setUserCookieList] = React.useState<Cookie[]>([]);
	// Should be this in a config file?
	const expirationTime = 181;

	const acceptCookiesAndClose = () => {
		setUserCookies(cookieList, expirationTime);
		setUsersAcceptCookie(!usersAcceptCookie);
		// This is used to reload profile page if cookies are not accepted
		// TODO - Change it for a router guard
		window.location.href.includes('profile') && window.location.reload(true);
	};

	const switchCookieAdvice = () => {
		setCookieMessage(!cookieMessage);
	};

	const toggleCookie = (cookieName: string) => {
		let toggledCookie = cookieList.find((cookie) => cookie.cookieName === cookieName);
		if (toggledCookie) {
			toggledCookie.isChecked = !toggledCookie.isChecked;
		}
		setUserCookieList([...cookieList]);
	};

	useEffect(() => {
		const loadCookies = async () => {
			const cookies = await getCookieList();
			const userCookies = await getUserCookies();
			let fullCookies: Cookie[] = [];
			if (cookies && userCookies) {
				fullCookies = userCookies.concat(
					cookies.data.filter((item) => userCookies.map((cookie) => cookie.cookieName).indexOf(item.cookieName) < 0)
				);
			}
			setUserCookieList(fullCookies);
		};
		loadCookies();
	}, []);

	return (
		<React.Fragment>
			{cookieMessage ? (
				<CookieInfoView
					acceptCookiesAndClose={acceptCookiesAndClose}
					switchCookieAdvice={switchCookieAdvice}
					usersAcceptCookie={usersAcceptCookie}
					toggleCookie={toggleCookie}
					cookieList={cookieList}
				/>
			) : (
				<MainCookie
					acceptCookiesAndClose={acceptCookiesAndClose}
					switchCookieAdvice={switchCookieAdvice}
					usersAcceptCookie={usersAcceptCookie}
				/>
			)}
		</React.Fragment>
	);
};

export default AdviceCookie;
