import React from 'react';
import { Route } from 'react-router-dom';
import * as components from './Imports';
import routesAuto from './auto.routes';
import routesAdmin from './admin.routes';
import routesDoc from './doc.routes';
import { AareonRoute } from '../core/interfaces/AareonRoute';
import RequireAuth from '../components/RequireAuth';
import { PROTECTED_ROLES } from '../core/const/roles';
import routesProfile from './profile.routes';

import { forceTheme } from '../components/SwitchMode';

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export const RouteWithSubRoutes: React.FC<AareonRoute> = (route) => {
	forceTheme(route.path);

	return route.path && route.component ? (
		<Route
			path={route.path}
			exact={route.exact}
			render={(props) => {
				if (PROTECTED_ROLES.includes(route.data.role)) {
					return <RequireAuth {...props} route={route} parentRoutes={route.parentRoutes} Component={route.component} />;
				} else {
					return <route.component {...props} route={route} parentRoutes={route.parentRoutes} />;
				}
			}}
		/>
	) : null;
};

const ordered = (a: AareonRoute, b: AareonRoute): number => {
	if (a.data.order !== undefined && b.data.order !== undefined) {
		if (a.data.order > b.data.order) {
			return 1;
		}
		if (a.data.order < b.data.order) {
			return -1;
		}
		// a must be equal to b
		return 0;
	} else {
		return a.data.order !== undefined ? 1 : -1;
	}
};

/**
 * @name deepRoutesOrder
 * @param {AareonRoute} routes
 * @returns {AareonRoute[]} Return an array of AareonRoute items
 * @description Order recursively the object using its data.order attribute
 */
export const deepRoutesOrder = (routes: AareonRoute[]): AareonRoute[] => {
	routes.sort(ordered);
	routes.map((route) => {
		if (route.routes) {
			return deepRoutesOrder(route.routes);
		}
		return route;
	});
	return routes;
};
// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.

export const routes = [
	{
		path: '/login',
		url: '/login',
		component: components.LoginLayout,
		data: { label: 'Login', order: 0 },
		routes: [
			{
				path: '/login',
				url: '/login',
				exact: true,
				component: components.Login,
				data: { label: 'Login', order: 1 },
				routes: []
			},
			{
				path: '/login/forgot',
				url: '/login/forgot',
				component: components.ForgotPassword,
				data: { label: 'Forgot Password', order: 2 },
				routes: []
			},
			{
				path: '/login/reset',
				url: '/login/reset',
				component: components.ResetPassword,
				data: { label: 'Reset Password', order: 2 },
				routes: []
			}
		]
	},
	{
		path: '/admin',
		url: '/admin',
		component: components.AdminLayout,
		data: { label: 'User Management', order: 2 },
		routes: [...routesAdmin]
	},
	{
		path: '/profile',
		url: '/profile',
		component: components.AdminLayout,
		data: { label: 'Profile', order: 3 },
		routes: [...routesProfile]
	},
	{
		path: '/',
		url: '/',
		component: components.MasterLayout,
		data: { label: 'Documentation', order: 4 },
		routes: [...routesDoc, ...routesAuto]
	}
];
export default routes;
