/**
 * SERVICES CONSTS
 */

// Generics
export const HTTPS = 'https://';
export const TOKEN_PREFIX = 'Bearer ';
export const SUB_PREFIX = '/api/v1/';

// Services names
export const COREUSER_SERVICE = 'coreuser-orm';
export const AUTH_SERVICE = 'auth-orm';
export const CORECONFIG_SERVICE = 'coreconfiguration-orm';
export const SMARTUSERS_SERVICE = 'smartusers';
export const SMARTTENANTS_SERVICE = 'smarttenants';

// Endpoints
export const ACTIVATE_USERS_ENDPOINT = 'users/manage/activate';
export const CREATE_USERS_ENDPOINT = 'users';
export const DEACTIVATE_USERS_ENDPOINT = 'users/manage/deactivate';
export const EDIT_USERS_ENDPOINT = 'users/manage/accounts';
export const REFRESH_TOKEN_ENDPOINT = '/api/refresh';
export const MOCKED_USERS_ENDPOINT = '/src/app/assets/mocks/users.json';
export const GET_IDENTITY_ENDPOINT = 'identity/searchby';
export const GET_SEARCHDATA_ENDPOINT = '/src/app/assets/static-files/search.json';
export const USERS_ENDPOINT = 'users';
export const USERS_SEARCHBY_ENDPOINT = 'user/searchby';
export const CONFIGURATION_SEARCHBY_ENDPOINT = 'configuration/searchby';
export const CHANGE_PASSWORD_ENDPOINT = 'users/my_password';
export const GET_PASSWORD_POLICIES_ENDPOINT = 'password/policies';
export const RESET_PASSWORD_ENDPOINT = 'tenants/password/reset';
export const VALIDATE_RESET_PASSWORD_ENDPOINT = 'tenants/password/validate';
export const RESET_PASSWORD_DEEP_LINK = '/login/reset';
export const CAPTCHA_VALIDATE = 'users/captcha_validate';
