import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

const NoMatchRoute = () => {
	let location = useLocation();

	return <Redirect to="/page-not-found" origin={location} />;
};

export default NoMatchRoute;
