import { Box, Button, CircularProgress, Grid, InputBase, InputLabel, Link, Typography } from '@material-ui/core';
import { resetPassword } from '../../services/users.service';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USERNAME } from './../../utils/Validators/regexp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { RESET_PASSWORD_DEEP_LINK } from '../../services/services.consts';

const ForgotPassword: React.FC = () => {
	const { t } = useTranslation(['login']);
	const [loading, setLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [forgotMsgError, setForgotMsgError] = useState<string>('');
	const [submitted, setSubmitted] = useState<boolean>(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const deep_link = `${window.location.origin}${RESET_PASSWORD_DEEP_LINK}`;
		setLoading(true);
		resetPassword(email, deep_link)
			.then((res) => {
				if (res.results.valid) {
					setForgotMsgError('');
					setSubmitted(true);
				} else {
					setForgotMsgError('');
					setSubmitted(false);
				}
			})
			.catch((res) => {
				const errors: any = {
					'Not Found': 'User not found, please check your email'
				};
				setForgotMsgError(errors[res.error]);
			})
			.finally(() => setLoading(false));
	};
	/**
	 * @name handleEmailOnChange
	 * @description Manage input value change in email
	 * @param {React.ChangeEvent<HTMLInputElement>} e the event on the input
	 */
	const handleEmailOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(e?.target.value);
	};
	return (
		<>
			<Typography className={'c-login__forgot-title'} variant={'h4'} gutterBottom>
				{t('form.forgotPassword')}
			</Typography>
			{submitted && (
				<>
					<Box className={'c-login__error'}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={2} className="c-login__errorIconWrapper">
								<EmailOutlinedIcon color="primary" />
							</Grid>
							<Grid item xs={10}>
								<Typography component="span" className={''}>
									{t('form.forgotPasswordSubmitted')}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Link href={'/login'} className={'c-login__textLabelForm c-login__link'}>
						Go back
					</Link>
				</>
			)}
			{forgotMsgError && (
				<Box className={'c-login__error'}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={2} className="c-login__errorIconWrapper">
							<ErrorOutlineIcon className="c-login__errorIcon" />
						</Grid>
						<Grid item xs={10}>
							<Typography component="span" className={''}>
								{forgotMsgError}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			)}
			{!submitted && (
				<>
					<Typography className={'c-login__forgot-description'} variant={'body1'} gutterBottom>
						{t('form.forgotPasswordDescription')}
					</Typography>
					<form noValidate onSubmit={handleSubmit} autoComplete="off">
						<InputLabel htmlFor="email" className={'c-booinput__label c-login__textLabelForm'}>
							{t('form.email')}
						</InputLabel>
						<InputBase
							name="email"
							id="email"
							data-error={t('form.userError')}
							classes={{ root: 'c-booinput', input: 'c-booinput__inner-input' }}
							onChange={handleEmailOnChange}
						/>
						<Box mt={3} mb={3}>
							<Button
								variant="contained"
								type="submit"
								id="sendEmail"
								classes={{
									root: 'c-button c-login__submitBtn',
									label: 'c-login__submitLabel',
									disabled: 'c-login__submitBtn--disabled'
								}}
								disabled={loading || !USERNAME.test(email)}
							>
								{t('form.button.send')}
								{loading && <CircularProgress size={24} className={'c-login__submitProgress'} />}
							</Button>
						</Box>
					</form>
				</>
			)}
		</>
	);
};
export default ForgotPassword;
