import { TotalState } from 'app/store/model/TotalState';
import { UserInfo } from './model/UserInfo';
import { ExpiredSession } from './model/ExpiredSession';

export const loginError = (state: TotalState) => state.loginState.error.message;
export const userIsLogged = (state: TotalState): boolean => state.loginState.login.isLogged;
export const getUser = (state: TotalState): UserInfo => state.loginState.login.userInfo;
export const getUserToken = (state: TotalState): string => state.loginState.login._token;
export const getUserRefreshToken = (state: TotalState): string => state.loginState.login._refreshToken;
export const getExpiredSession = (state: TotalState): ExpiredSession => state.loginState.expiredSession;
export const isRememberMe = (state: TotalState): boolean => state.loginState.rememberMe;
export const isGetUserNameToRemember = (state: TotalState): string => state.loginState.userNameToRemember;
