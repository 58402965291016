import { CUSTOM_MODAL_CLOSE, CUSTOM_MODAL_OPEN } from './customModal.const';
import { CustomModalActionTypes } from './customModal.types';
import { CustomModalContent } from './model/CustomModalContent';

export const closeCustomModal = (): CustomModalActionTypes => {
	return {
		type: CUSTOM_MODAL_CLOSE
	};
};

export const openCustomModal = (customModalContent: CustomModalContent): CustomModalActionTypes => {
	return {
		type: CUSTOM_MODAL_OPEN,
		payload: customModalContent
	};
};
