import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { CircularProgress, Typography } from '@material-ui/core';
import { Element, scroller } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFile } from '../../services/functional-content.service';
import CodeWrapper from '../CodeWrapper';
import TableWrapper from '../TableWrapper';
import VideoPlayer from '../VideoPlayer';
import ScrollToTopButton from '../ScrollToTopButton';
import { MarkDownProps } from './MarkDownProps';
import { setLoading } from './markdown.actions';
import { getLoading } from './markdown.selectors';

const MarkDown: React.FC<MarkDownProps> = (props) => {
	const {
		route: {
			data: { file }
		}
	} = props;

	const dispatch = useDispatch();
	const [source, setSource] = useState('');
	const loading: boolean = useSelector(getLoading);
	const { anchor } = useParams();

	// * Taking the markdown file:
	useEffect(() => {
		setLoading(true);
		dispatch(setLoading(true));
		const getMark = async () => {
			const markdown = await getFile('/' + file);
			setSource(markdown);
		};
		getMark();
	}, []);

	useEffect(() => {
		source.length > 0 && dispatch(setLoading(false));
	}, [source]);
	useEffect(() => {
		loading === false && anchor && scrollTo(`${anchor}_container`);
	}, [loading]);

	const scrollTo = (where: string) => {
		scroller.scrollTo(where, {
			duration: 1100,
			delay: 100,
			smooth: true,
			offset: -160
		});
	};

	return (
		<React.Fragment>
			{loading && (
				<div className="c-markdown__loader-container">
					<CircularProgress disableShrink className="c-markdown__loader" size={100} />
				</div>
			)}
			{!loading && (
				<Markdown
					className={'c-markdown'}
					options={{
						overrides: {
							h1: {
								component: Typography,
								props: {
									variant: 'h1',
									className: ' c-main__header1'
								}
							},
							h2: {
								component: Typography,
								props: {
									variant: 'h2',
									className: 'c-main__header2'
								}
							},
							h3: {
								component: Typography,
								props: {
									variant: 'h3',
									className: 'c-main__header3'
								}
							},
							scroll: {
								component: Element
							},
							code: {
								component: CodeWrapper
							},
							table: {
								props: {
									className: 'c-main__table'
								},
								component: TableWrapper
							},
							tr: {
								props: {
									className: 'c-markdown__row'
								}
							},
							link: {
								component: Link
							},
							video: {
								component: VideoPlayer
							},
							ul: {
								props: {
									className: 'c-markdown__list'
								}
							}
						}
					}}
				>
					{source}
				</Markdown>
			)}
			<ScrollToTopButton />
		</React.Fragment>
	);
};

export default MarkDown;
