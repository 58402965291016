import { SET_OPEN_DIALOG, SET_CLOSE_DIALOG } from './profileFormDialog.consts';
import { ProfileFormDialogActionTypes } from './profileFormDialog.types';

export const setOpenDialog = (): ProfileFormDialogActionTypes => {
	return {
		type: SET_OPEN_DIALOG
	};
};

export const setCloseDialog = (): ProfileFormDialogActionTypes => {
	return {
		type: SET_CLOSE_DIALOG
	};
};
