import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { GET_SEARCHDATA_REQUEST, GET_SEARCHDATA_SUCCESS, GET_SEARCHDATA_ERROR } from './searchbar.consts';
import { SearchBarActionTypes } from './searchbar.types';
import { SearchData, SearchDataState } from './model/SearchDataState';

/**
 * @function
 * @name getSearchData
 * @description Thunk to handle dispatchers
 * @returns {Function} The dipatcher result
 */
export const getSearchData = (): ThunkAction<void, SearchDataState, undefined, SearchBarActionTypes> => {
	return async (
		dispatch: ThunkDispatch<SearchDataState, undefined, SearchBarActionTypes>,
		_getState: any,
		services: any
	) => {
		dispatch(getSearchBarRequest);
		try {
			const data = await services.searchbarService.getSearchData();
			dispatch(getSearchBarSuccess(data));
		} catch (error) {
			dispatch(getSearchBarError(error));
		}
	};
};

/**
 * @function
 * @name getSearchBarRequest
 * @description Start with the request action
 * @returns {object} The type of the action
 */
const getSearchBarRequest = (): SearchBarActionTypes => {
	return {
		type: GET_SEARCHDATA_REQUEST
	};
};

/**
 * @function
 * @name getSearchBarError
 * @param {SearchData[]} searchdata The entire searchdata content
 * @description Manage the success of the action
 * @returns {object} The type of the action and the payload
 */
const getSearchBarSuccess = (searchdata: SearchData[]): SearchBarActionTypes => {
	return {
		type: GET_SEARCHDATA_SUCCESS,
		payload: searchdata
	};
};

/**
 * @function
 * @name getSearchBarError
 * @param {any} error An error
 * @description Manage the errors of the action
 * @returns {object} The type of the action and the payload
 */
const getSearchBarError = (error: any): SearchBarActionTypes => {
	return {
		type: GET_SEARCHDATA_ERROR,
		payload: error
	};
};
