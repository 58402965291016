import React, { useState, useEffect } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { iAvatarContent } from 'app/core/interfaces/AvatarContent';
import { getUser } from '../Login/login.selectors';
import { useSelector } from 'react-redux';

const AvatarContent: React.FC<iAvatarContent> = (props) => {
	const { classes } = props;
	const [userInitials, setUserInitials] = useState('');
	const userInfo = useSelector(getUser);
	const classNames = classes ? classes.join(' ') : '';

	useEffect(() => {
		let avatarContent: any = <PersonIcon id="avatar-content-person-icon"></PersonIcon>;
		if (userInfo && userInfo.identity) {
			const firstname = userInfo.identity.first_name;
			const name = userInfo.identity.last_name;
			if (firstname && name) {
				avatarContent = firstname.charAt(0) + name.charAt(0);
			} else if (firstname || name) {
				avatarContent = firstname ? firstname.charAt(0) : name.charAt(0);
			}
		}
		setUserInitials(avatarContent);
	}, [userInfo]);

	return userInitials ? (
		<span id="avatar-content-initials" className={classNames}>
			{userInitials}
		</span>
	) : (
		<PersonIcon />
	);
};

export default AvatarContent;
