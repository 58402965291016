import { GET_SEARCHDATA_REQUEST, GET_SEARCHDATA_SUCCESS, GET_SEARCHDATA_ERROR } from './searchbar.consts';
import { SearchBarActionTypes } from './searchbar.types';
import { SearchDataState } from './model/SearchDataState';

/**
 * @name initialState
 * @type {SearchDataState}
 * @description The initial state of the reducer
 */
const initialState: SearchDataState = {
	searchData: [],
	error: { status: '', code: '', message: '' },
	loading: false
};

/**
 * @function
 * @name searchBarReducer
 * @param {SearchDataState} state The state of the reducer
 * @param {SearchBarActionTypes} action The action of the reducer
 * @description The Reducer of the searchBar Redux component
 * @returns {SearchDataState} The new state of the reducer
 */
const searchBarReducer = (state = initialState, action: SearchBarActionTypes): SearchDataState => {
	switch (action.type) {
		case GET_SEARCHDATA_REQUEST:
			return {
				...state,
				error: initialState.error,
				loading: true
			};
		case GET_SEARCHDATA_SUCCESS:
			return {
				...state,
				searchData: action.payload,
				error: initialState.error,
				loading: initialState.loading
			};
		case GET_SEARCHDATA_ERROR:
			return {
				...state,
				searchData: initialState.searchData,
				error: action.payload,
				loading: initialState.loading
			};
		default:
			return state;
	}
};

export default searchBarReducer;
