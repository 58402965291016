import { TotalState } from 'app/store/model/TotalState';

/**
 * PROFILEFORMDIALOG: SELECTORS
 *
 * @function
 * @name isProfileFormDialogOpen
 * @param {TotalState} state
 * @returns {boolean} Returns true if profileFormDialogState is open.
 */
export const isProfileFormDialogOpen = (state: TotalState): boolean => state.profileFormDialogState.open;
