import React from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { SnackbarContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AdviceCookieProps } from '../../../core/interfaces/AdviceCookie';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const CookieInfoView: React.FC<AdviceCookieProps> = (props) => {
	const { t } = useTranslation('cookiesInfo');
	const { acceptCookiesAndClose, switchCookieAdvice, usersAcceptCookie, toggleCookie, cookieList } = props;

	return (
		<Snackbar open={usersAcceptCookie} className="c-cookie-infoview__root">
			<SnackbarContent
				classes={{
					root: 'c-cookie-infoview__content',
					action: 'c-cookie-infoview__btn',
					message: 'c-cookie-infoview__tabcontainer'
				}}
				role="alertdialog"
				aria-describedby="cookien-preferences"
				message={
					<React.Fragment>
						<h3 className="c-cookie-infoview__title">{t('cookieInfoView.pagetitle')}</h3>
						<IconButton
							classes={{ root: 'c-cookie-infoview__btn--backbtn' }}
							aria-label="delete"
							onClick={() => {
								switchCookieAdvice();
							}}
						>
							<ArrowBackIosIcon fontSize="large" />
						</IconButton>
						<TableContainer>
							<Table aria-label="simple table">
								<TableBody>
									{cookieList &&
										cookieList.map((cookie, index: number) => {
											return (
												<TableRow key={index} classes={{ root: 'c-cookie-infoview__tablebody' }}>
													<TableCell scope="row">
														<React.Fragment>
															{<h4 className="c-cookie-settings__text">{t(cookie.title)}</h4>}
															{<span className="c-cookie-settings__text">{t(cookie.text)}</span>}
														</React.Fragment>
													</TableCell>
													<TableCell scope="row">
														<Switch
															id={cookie.cookieName}
															disabled={cookie.disabled}
															checked={cookie.isChecked}
															onChange={() => {
																toggleCookie(cookie.cookieName);
															}}
															color="primary"
															name={`${cookie.cookieName}`}
															inputProps={{ 'aria-label': 'primary checkbox' }}
															classes={{
																colorPrimary: 'c-action-table__switch'
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</React.Fragment>
				}
				action={
					<Button
						className={'c-button c-cookie-infoview__btn--confirm'}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => {
							acceptCookiesAndClose();
						}}
					>
						{t('mainCookie.btnAcceptContent')}
					</Button>
				}
			/>
		</Snackbar>
	);
};

export default CookieInfoView;
