import * as components from './Imports';
const routesAdmin = [
	{
		path: '/admin',
		url: '/admin',
		component: components.LayoutTwoColumnAdmin,
		data: {
			label: 'Users',
			key: '',
			order: 0,
			visible: false,
			role: 'admin'
		},
		routes: [
			{
				path: '/admin',
				url: '/admin',
				exact: true,
				component: components.UserList,
				data: {
					label: 'User List',
					key: '',
					visible: true,
					order: 0
				},
				routes: []
			},
			{
				path: '/admin/*',
				component: components.NoMatchRoute,
				data: { label: '404', visible: false, order: 1000 },
				routes: []
			}
		]
	},
	{
		path: '/page-not-found',
		exact: true,
		component: components.LayoutOneColumn,
		data: { label: '404', order: 1000 },
		routes: [
			{
				path: '/page-not-found',
				exact: true,
				component: components.Logo404,
				data: { label: '404', order: 1000, role: 'user' },
				routes: []
			}
		]
	},
	{
		path: '*',
		component: components.NoMatchRoute,
		data: { label: '404', order: 1001 },
		routes: []
	}
];

export default routesAdmin;
