import React from 'react';
import App from './components/App';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { store, persistor } from './store';
import '../scss/main.scss';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react';

// If debugger is disabled, we rewrite logger functions
// If not, our console.xxxx will log with the file and line exactly.
if (!window._env_.LOGGER_DEBUG) {
	console.log = function() {};
	console.info = function() {};
	console.warn = function() {};
	console.error = function() {};
}

const theme = createMuiTheme({
	breakpoints: {
		// Custom breakpoint values.
		values: {
			xs: 0,
			sm: 576,
			md: 768,
			lg: 1024,
			xl: 1440
		}
	}
});
ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<MuiThemeProvider theme={theme}>
				<App />
			</MuiThemeProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
