import {
	GET_LOGIN_SUCCESS,
	GET_LOGIN_REQUEST,
	GET_LOGIN_ERROR,
	LOGOUT,
	SET_REMEMBER_ME_TOGGLE,
	SET_USER_NAME_TO_REMEMBER,
	SET_EXPIRED_SESSION,
	SET_NEW_AUTH,
	CLEAN_ERRORS
} from './login.consts';
import { LoginActionTypes } from './login.types';
import { LoginState } from './model/LoginState';

export const initialState: LoginState = {
	login: {
		isLogged: false,
		_token: '',
		_refreshToken: '',
		userInfo: {
			user: {
				username: '',
				email: '',
				userId: 0,
				roles: {},
				role: '',
				tenant: ''
			},
			identity: {
				id: 0,
				last_name: '',
				first_name: ''
			},
			options: []
		}
	},
	error: { status: '', code: '', message: '' },
	rememberMe: false,
	userNameToRemember: '',
	expiredSession: { expired: false, retry: 2, initialRetry: 2, type: '' }
};

const userReducer = (state = initialState, action: LoginActionTypes): LoginState => {
	switch (action.type) {
		case GET_LOGIN_REQUEST:
			return {
				...state,
				error: initialState.error
			};
		case GET_LOGIN_SUCCESS:
			return {
				...state,
				login: { ...action.payload },
				error: initialState.error,
				expiredSession: initialState.expiredSession
			};
		case GET_LOGIN_ERROR:
			return {
				...state,
				login: initialState.login,
				error: action.payload
			};
		case LOGOUT:
			return {
				...state,
				login: initialState.login
			};
		case SET_REMEMBER_ME_TOGGLE:
			return {
				...state,
				rememberMe: action.payload
			};

		case SET_USER_NAME_TO_REMEMBER:
			return {
				...state,
				userNameToRemember: state.rememberMe ? state.login.userInfo.user.username : initialState.userNameToRemember
			};
		case SET_EXPIRED_SESSION:
			return {
				...state,
				expiredSession: { ...action.payload, initialRetry: initialState.expiredSession.initialRetry }
			};
		case SET_NEW_AUTH:
			return {
				...state,
				login: {
					...state.login,
					_refreshToken: action.payload.refreshToken,
					_token: action.payload.accessToken
				}
			};
		case CLEAN_ERRORS:
			return {
				...state,
				error: initialState.error
			};
		default:
			return state;
	}
};

export default userReducer;
