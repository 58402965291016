import axios from 'axios';
import React from 'react';
import { Grid } from '@material-ui/core';
import Console from './Console';
import Doc from './Doc';
import { SwaggerProps } from './model/SwaggerProps';
import { JsonData } from './model/JsonData';

const Swagger: React.FC<SwaggerProps> = (props) => {
	const { route } = props;
	const [displayConsole, setDisplayConsole] = React.useState(false);
	const [path, setPath] = React.useState<string>('');
	const [selectedMethod, setSelectedMethod] = React.useState({});
	const [jsonData, setJsonData] = React.useState<JsonData>({
		info: {},
		paths: {},
		servers: [
			{
				url: '',
				description: ''
			}
		]
	});
	const apiFile: string = route.data?.file || '';

	React.useEffect(() => {
		axios
			.get(apiFile)
			.then((response) => {
				console.log('Success:', response);
				setJsonData(response.data);
			})
			.catch((error) => console.error('Error:', error));
	}, []);

	const toggleConsole = (path: string) => {
		if (!displayConsole) {
			setDisplayConsole(true);
			setPath(path);
			setSelectedMethod(jsonData.paths[path]);
		} else if (displayConsole && path !== '') {
			setPath(path);
			setSelectedMethod(jsonData.paths[path]);
		} else if (path === '') {
			setDisplayConsole(false);
			setSelectedMethod({});
		}
	};

	return (
		<Grid container spacing={1} className="c-swagger">
			<Grid item xs={12} sm={displayConsole ? 6 : 12} className="c-swagger__left">
				<Doc data={jsonData} toggleConsole={toggleConsole} selectedMethod={selectedMethod} />
			</Grid>
			<Grid
				item
				style={{ display: displayConsole ? 'block' : 'none' }}
				xs={12}
				sm={6}
				className="c-swagger__right"
				id="consoleContainer"
			>
				<Console
					data={jsonData}
					servers={jsonData.servers}
					selectedMethod={selectedMethod}
					path={path}
					toggleConsole={toggleConsole}
				/>
			</Grid>
		</Grid>
	);
};
export default Swagger;
