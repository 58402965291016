import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ConfirmDialogProps } from './model/ConfirmDialog';
import { useTranslation } from 'react-i18next';

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
	const { callback, description, onClose, isOpen, title } = props;
	const { t } = useTranslation(['generics']);
	return (
		<Dialog
			open={isOpen}
			onClose={() => onClose()}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<div className="c-confirmdialog__dialog">
				<DialogTitle classes={{ root: 'c-confirmdialog__title' }} disableTypography id="alert-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" className={'c-confirmdialog__label'}>
						{description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => onClose()}
						color="primary"
						variant="outlined"
						className={'c-confirmdialog__btn c-confirmdialog__btn--cancel'}
					>
						{t('cancel')}
					</Button>
					<Button
						onClick={() => {
							callback();
							onClose();
						}}
						variant="contained"
						className={'c-button c-confirmdialog__btn'}
						autoFocus
					>
						{t('confirm')}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};
export default ConfirmDialog;
